<button class="btn bg-secondary text-primary w-100 px-3 px-sm-4 h-100 rounded-lg " (click)="showE911Modal();">
  <svg style="height: 30px" id="ambulance" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.291 32.966">
    <path id="Path_1374" data-name="Path 1374" d="M276.75,41.208h2.3v3.653h-2.3Z" transform="translate(-255.512 -41.208)" fill="#fff"/>
    <path id="Path_1375" data-name="Path 1375" d="M0,0H3.653V2.3H0Z" transform="matrix(0.736, -0.677, 0.677, 0.736, 25.675, 4.343)" fill="#fff"/>
    <path id="Path_1376" data-name="Path 1376" d="M0,0H2.3V3.653H0Z" transform="matrix(0.677, -0.736, 0.736, 0.677, 14.853, 3.589)" fill="#fff"/>
    <circle id="Ellipse_118" data-name="Ellipse 118" cx="2.072" cy="2.072" r="2.072" transform="translate(7.367 28.822)" fill="#fff"/>
    <path id="Path_1377" data-name="Path 1377" d="M246.5,119.819h6.945v-1.032a3.472,3.472,0,0,0-6.945,0Z" transform="translate(-227.584 -109.628)" fill="#fff"/>
    <circle id="Ellipse_119" data-name="Ellipse 119" cx="2.072" cy="2.072" r="2.072" transform="translate(27.962 28.822)" fill="#fff"/>
    <path id="Path_1379" data-name="Path 1379" d="M39.028,211.725H21.238v-7.717H0v18.633H5.071q-.006-.115-.006-.232a4.375,4.375,0,0,1,8.749,0q0,.117-.006.232H25.666q-.006-.115-.006-.232a4.375,4.375,0,1,1,8.749,0q0,.117-.006.232h4.888v-9.556A3.642,3.642,0,0,0,39.028,211.725Zm-24.746.608H12.041v2.241h-2.3v-2.241H7.5v-2.3H9.738V207.79h2.3v2.241h2.241v2.3Zm12.7,3.518H23.813v-2.3h3.172Z" transform="translate(0 -191.515)" fill="#fff"/>
    <path id="Path_1380" data-name="Path 1380" d="M306.75,209.423h10.278l-3.613-5.415H306.75Z" transform="translate(-283.21 -191.515)" fill="#fff"/>
  </svg>

  <h5 class="m-0 font-weight-bold pt-2">E911</h5>
</button>


<!-- Modal E911 -->
<div class="modal fade bsModal" bsModal #e911Modal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog" *ngIf="allowE911Modal">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Edit Registered E911 Location</h4>
            </div>
            <div class="modal-body"> {{f.errors | json}}
                <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Name is required.</div>
                        </div>
                    </div>



                    <div class="form-group">
                        <label>Address Line 1</label>
                        <input type="text" formControlName="addressLine1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.addressLine1.errors }" />
                        <div *ngIf="submitted && f.addressLine1.errors" class="invalid-feedback">
                            <div *ngIf="f.addressLine1.errors.required">Address is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Address Line 2</label>
                        <input type="text" formControlName="addressLine2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.addressLine2.errors }" />

                    </div>

                    <div class="form-group">
                        <label>City</label>
                        <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                        <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                            <div *ngIf="f.city.errors.required">City is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>State</label>
                        <div class="select-input">
                            <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
                            <select class="select-custom form-control" formControlName="state" [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                <option value="" selected>Select State</option>
                <option *ngFor="let state of filteredStates" [value]="state.shortCode">{{state.name}}</option>
              </select>
                        </div>
                        <div *ngIf="submitted && f.state.errors" class="dropdown-error">
                            <div *ngIf="f.state.errors.required">State is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Country</label>
                        <div class="select-input">
                            <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
                            <select class="select-custom form-control" formControlName="country" [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                <option selected value="">Select Country</option>
                <option *ngFor="let country of countries" [value]="country.countryShortCode">{{country.countryName}}

                  <!-- <option value="US">U.S.A.</option>
                <option value="CA">Canada</option> -->
              </select>
                        </div>
                        <div *ngIf="submitted && f.country.errors" class="dropdown-error">
                            <div *ngIf="f.country.errors.required">Country is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Zip</label>
                        <input type="text" formControlName="zip" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.zip.errors }" />
                        <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                            <div *ngIf="f.zip.errors.required">Zip is required.</div>
                        </div>
                    </div>

                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="hideE911Modal()">Cancel</button>
                <button type="button" class="btn bg-primary text-primary" (click)="onSubmit()">Verify Changes</button>
            </div>
        </div>
    </div>
</div>
