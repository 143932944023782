<div class="row">
  <div class="col-md-4 col-6" *ngFor="let provider of providers">
    <div class="card w-100">
      <div class="d-flex justify-content-end">
        <img src="/assets/images/star.png" alt="" class="star-img">
        <div class="star-img-text text-primary">5</div>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-center" *ngIf="provider">
          <img class="card-title provider-img" src={{provider.image}} alt="">
        </div>
        <div class="text-center">
          <span class="text-color">{{provider.monthlyPrice}}/month</span>
        </div>
      </div>
      <a class="btn bg-gray text-primary w-100" [href]="provider.reviewLink"> Read Reviews</a>
      <!-- <button class="btn bg-primary text-primary w-100">Get Quote</button> -->
      <button class="btn text-primary w-100" style="background-color: rgb(254, 51, 10); color: rgb(255, 255, 255);"
        (click)="selectInternetProvider(provider.name)">
        <span *ngIf="(selectedInternetProvider.name || '').indexOf(provider.name) >= 0">
          <i class="fas fa-check"></i> SELECTED</span>
        <span *ngIf="(selectedInternetProvider.name || '').indexOf(provider.name) < 0">GET
          QUOTE</span>
      </button>
    </div>
  </div>

</div>