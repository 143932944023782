import { Component, OnInit } from '@angular/core';
import { CheckoutAfterCheckoutBase } from '../checkout.after-checkout.base';
import { SystemType } from '@voiply/shared/model';
import { CrispService } from '@voiply/shared/common-ui';

@Component({
  selector: 'voiply-checkout-home-after-checkout',
  templateUrl: './checkout-home-after-checkout.component.html',
  styleUrls: ['./../checkout.base.css']
})
export class CheckoutHomeAfterCheckoutComponent extends CheckoutAfterCheckoutBase {

  constructor(crispService: CrispService) {
    super(crispService, SystemType.Home);
  }
}
