import { Component } from '@angular/core';

@Component({
  selector: 'voiply-footer-home',
  templateUrl: './footer-home.component.html',
  styleUrls: ['./footer-home.component.scss']
})
export class FooterHomeComponent {
  content = `If you're a small business owner, check out <span class="font-weight-bold">Voiply Business</span> plans! With unlimited calling* and 50+ included business features, our customized plans are perfect to grow with you business!`;

  features = [
    "Unlimited Calling to 50+ Countries",
    "No Setup Fees",
    "24/7 Tech Support",
    "HD Voice",
    "99.9999% Uptime",
    "Voicemail to Email"
  ];
  imglogo="/assets/images/voiplyBusinessFooterIcon.png";
  constructor() { }

  navigatePage() {
    window.location.href = "https://build.voiply.com";
  }
}
