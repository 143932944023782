import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CONTAINERS } from './containers';
import { COMPONENTS } from './components'
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CommonUiModule } from '@voiply/shared/common-ui';

@NgModule({
  imports: [
    CommonModule,
    CollapseModule,
    CommonUiModule
  ],
  declarations: [
    ...CONTAINERS,
    ...COMPONENTS
  ],
  exports: [
    ...CONTAINERS,
    ...COMPONENTS
  ]
})
export class FaqModule {}
