import { Component, Inject, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ProductBase } from '../product.base';
import { ENVIRONMENT, IEnvironment, CrispService, AudioService } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';
import { Phone } from '@voiply/shared/model';
import * as _ from 'lodash'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddToCartConfirmModalComponent } from '../../components/add-to-cart-confirm-modal/add-to-cart-confirm-modal.component';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'voiply-apps-home',
  templateUrl: './apps-home.component.html',
  styleUrls: ['./apps-home.component.scss']
})
export class AppsHomeComponent extends ProductBase {
  isCollapsed = true;
  modalRef: BsModalRef;
  hasVoiplyAdapter = false;
  @ViewChild('template') templateRef: TemplateRef<any>;
  constructor(@Inject(ENVIRONMENT) environment: IEnvironment, toastr: ToastrService, crispService: CrispService, audioService: AudioService, private modalService: BsModalService) {
    super(environment, toastr, crispService, audioService);
  }

}


