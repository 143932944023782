import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderBase } from './../header.base';
import { HeadingService } from '@voiply/shared/common-ui';
import * as _ from 'lodash';
@Component({
  selector: 'voiply-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./../header.base.css']
})
export class HeaderHomeComponent extends HeaderBase implements OnInit {

  chips = {
    payAnnually: false,
    promoCode: '',
    freeShipping: false,
  };

  title = '#1 Phone System for Residential';

  constructor(private headingService: HeadingService) {
    super();
    if (this.headingService.reviews.length)
      this.title = _.filter(
        this.headingService.reviews,
        review => review.type === 'residential'
      )[0].title;
    this.subscriptions$.add(this.billingAddress$.subscribe((billingData) => this.chips.freeShipping = billingData.country == 'US'));

    this.subscriptions$.add(this.checkOutDetails$.subscribe((details) => {
      this.chips.payAnnually = details.payAnnually;
      this.chips.promoCode = details.promoCode;
    }));

  }

  ngOnInit() {

  }


}
