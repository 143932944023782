<!-- <div class=" my-3 card py-5 px-4" style="border-radius: 0% !important;" id="phones">
    <div class="card-header bg-white border-0">
        <div class="h2 font-weight-bolder text-capitalize">
            Choose Your Bundle <span class="text-muted" style="font-size: 18px;">(Choose Only One)</span>
        </div>
    </div>
    <voiply-phone-new [phones$]="phones$" [selectedCountry]="selectedCountry" (addToCart)="onAddPhonetoCartHome($event)"></voiply-phone-new>
</div> -->

<div class=" my-3 card py-5 px-lg-4 addOnScroll" style="border-radius: 0% !important; border: 1px solid #ededed" id="apps">
    <div class="card-header bg-white border-0">
        <div style="position: relative;">
            <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block" style="position: absolute; left: -60px; top: -10px; font-size: 60px; color:#D3D4D5"></i>
        </div>
        <div class="h2 font-weight-bolder text-capitalize heading-font">
            Activate Premium Features
        </div>
    </div>
    <div class="card-body">
        <voiply-app-new [apps$]="apps$" [selectedCountry]="selectedCountry" [cartItems]="cartItems" (addToCart)="onAddAppToCart($event)" (removeCartItem)="onRemoveCartItem($event)" (showWarning)="showWarning()"></voiply-app-new>
    </div>
</div>


<!-- <div class="">
    <div class="collapse multi-collapse bg-white" [collapse]="!isCollapsed" id="multiCollapseExample1">
    </div>
    <voiply-product-configuration-home [productConfigurations$]="cartItems$" [phones]="(phones$ | async)" [apps]="(apps$ | async)" (removeCartItem)="onRemoveCartItem($event)" (updateCartItemConfiguration)="onUpdateCartItemConfiguration($event)">
    </voiply-product-configuration-home>
</div>

<div class="modal fade bsModal" bsModal #appModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="bsModal-header">
                <div class="d-flex flex-row">
                    <h4 class="flex-grow-1" class="modal-title">Voiply Adapter</h4>
                </div>
            </div>
            <div class="modal-body">
                <div> Voiply Adapter is already added in the cart. Do you want to add Another Voiply Adapter?</div>
            </div>
            <div class="numb-footer">
                <button type="button" class="btn-close" (click)="this.appModal.hide();">Cancel</button>
                <button type="button" class="btn-update" (click)="this.appModal.hide();">Add
          to Cart</button>
            </div>
        </div>
    </div>
</div> -->
