<div class="keepNumberScroll d-flex my-3 card py-5 px-4" style="border-radius: 0% !important; border: 1px solid #ececec;" id="primary-number">
    <div class="card-header bg-white border-0">
        <div style="position: relative;">
            <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block" style="position: absolute; left: -60px; top: -10px; font-size: 60px; color:#D3D4D5"></i>
        </div>
        <div class="h2 font-weight-bolder text-capitalize heading-font">
            Keep your phone number
        </div>
    </div>
    <div class="card-body bg-light-grey rounded-lg">
        <div class="row">
            <div class="col-lg-7 d-flex align-items-center">
                <div class="w-100 rounded-lg" style="overflow: hidden;">
                    <!-- <voiply-wistia-player videoId="g6u98c4qew"></voiply-wistia-player> -->
                    <div style="padding:56.25% 0 0 0;position:relative;">
                        <iframe src="https://player.vimeo.com/video/571623693?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;portrait=0&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" ></iframe>
                    </div>
                </div>
                
            </div>
            <div class="col-lg-5 mt-3 mt-lg-0">
                <div class="d-flex flex-column rounded-lg" style="background-color: #f1f1f1;">
                    <div class="d-flex flex-row align-items-center justify-content-between p-3 rounded-lg cursor-pointer flex-fill border bg-white" (click)="toggleAccordianShow(1)" [ngClass]="{'text-black': accordianShowExistingNumber == 'in', 'text-secondary': accordianShowExistingNumber == 'out'}">
                        <div class="d-flex align-items-center">
                            <!-- <div class="pr-2" *ngIf="accordianShowExistingNumber == 'out'">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
                                  <defs>
                                    <linearGradient id="linear-gradient" x2="1" y2="0.929" gradientUnits="objectBoundingBox">
                                      <stop offset="0" stop-color="#fb522c" />
                                      <stop offset="1" stop-color="#d22700" />
                                    </linearGradient>
                                  </defs>
                                  <g id="Group_766" data-name="Group 766" transform="translate(-1004 -2405)">
                                    <g id="Ellipse_57" data-name="Ellipse 57" transform="translate(1004 2405)" fill="#fff"
                                      stroke="#f63b0b" stroke-width="0.5">
                                      <circle cx="11" cy="11" r="11" stroke="none" />
                                      <circle cx="11" cy="11" r="10.75" fill="none" />
                                    </g>
                                    <path id="Path_1671" data-name="Path 1671" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z"
                                      transform="translate(1008 2409)" fill="url(#linear-gradient)" />
                                  </g>
                                </svg>
                            </div>
                            <div class="pr-2" *ngIf="accordianShowExistingNumber == 'in'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                  <g id="Ellipse_58" data-name="Ellipse 58" fill="#fff" stroke="#b8b8b8" stroke-width="0.5">
                    <circle cx="11" cy="11" r="11" stroke="none" />
                    <circle cx="11" cy="11" r="10.75" fill="none" />
                  </g>
                                </svg>
                            </div> -->
                            <span class="text-capitalize font-weight-bold h6 mb-0">I want to transfer my number</span>
                        </div>
                        <span><span class="text-uppercase mr-1">free</span>
                        <span style="opacity: 0.7;"><del>{{39.99 | currency}}</del></span>
                        </span>
                    </div>
                    <div [@slideInOut]="accordianShowExistingNumber">
                        <div class="border rounded" style="border-top: 0 !important;">
                            <div class="m-2 rounded">
                                <!-- <input class="bg-white rounded-top" type="text" [ngClass]="{ 'is-invalid': ((!isValid && submitted) || (primaryNumber.touched && !isValid)) , 'form-control h-100 primary-number-input':true}" mask="(000) 000-0000" name="primaryNumber" #primaryNumber="ngModel"
                                placeHolder="(XXX) XXX-XXXX" [(ngModel)]="primaryPhone.number" (keyup)="setIsNewStatus($event); savePrimaryNumber()">
                            <div *ngIf="primaryNumber.pristine" class="text-muted font-weight-bolder text-center rounded-bottom text-uppercase" style="background-color: #D4D4D4; font-size: small;">Enter your residential phone number to continue</div>
                            <div *ngIf="!primaryNumber.pristine && primaryNumber.valid" class="text-success font-weight-bold text-center rounded-bottom text-uppercase" style="background-color: #C0F3BC; font-size: small;">Success! We Can Transfer Your Number to Voiply.</div>
                            <div *ngIf="!primaryNumber.pristine && primaryNumber.invalid" class="text-danger font-weight-bolder text-center rounded-bottom text-uppercase" style="background-color: #FFBEC0; font-size: small;">Failed! Infortunately we cannot transfer your number</div> -->
                                <voiply-existing-number-home (update)="onUpdate($event)" (updateNumber)="this.primaryPhone.number = $event; this.setIsNewStatus(); this.savePrimaryNumber();" [selectedNumber]="primaryPhone.number" [tollFreeNumberNotAllowed]="tollFreeNumberNotAllowed"
                                    [isNew]="primaryPhone.isNew" [isValid]="primaryPhone.isValid">
                                </voiply-existing-number-home>
                            </div>
                        </div>
                        <div class="text-muted d-flex flex-column py-3 bg-light-grey">
                            <div class="d-flex flex-row">
                                <i class="fas fa-circle px-2 my-1" style="color: #D8D8D8;"></i>
                                <div>The transfer process of your number may take up to 10 Business Days to complete.</div>
                            </div>
                            <div class="d-flex flex-row">
                                <i class="fas fa-circle px-2 my-1" style="color: #D8D8D8;"></i>
                                <div>Do not contact your phone company/carrier to disconnect your local telephone service.</div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row align-items-center justify-content-between p-3 rounded-lg cursor-pointer flex-fill border bg-white" (click)="toggleAccordianShow(2)" [ngClass]="{'text-black': accordianShowNewNumber == 'in', 'text-secondary': accordianShowNewNumber == 'out'}">
                        <div class="d-flex align-items-center">
                            <!-- <div class="pr-2" *ngIf="accordianShowNewNumber == 'out'">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
                                  <defs>
                                    <linearGradient id="linear-gradient" x2="1" y2="0.929" gradientUnits="objectBoundingBox">
                                      <stop offset="0" stop-color="#fb522c" />
                                      <stop offset="1" stop-color="#d22700" />
                                    </linearGradient>
                                  </defs>
                                  <g id="Group_766" data-name="Group 766" transform="translate(-1004 -2405)">
                                    <g id="Ellipse_57" data-name="Ellipse 57" transform="translate(1004 2405)" fill="#fff"
                                      stroke="#f63b0b" stroke-width="0.5">
                                      <circle cx="11" cy="11" r="11" stroke="none" />
                                      <circle cx="11" cy="11" r="10.75" fill="none" />
                                    </g>
                                    <path id="Path_1671" data-name="Path 1671" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z"
                                      transform="translate(1008 2409)" fill="url(#linear-gradient)" />
                                  </g>
                                </svg>
                            </div>
                            <div class="pr-2" *ngIf="accordianShowNewNumber == 'in'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                  <g id="Ellipse_58" data-name="Ellipse 58" fill="#fff" stroke="#b8b8b8" stroke-width="0.5">
                                    <circle cx="11" cy="11" r="11" stroke="none" />
                                    <circle cx="11" cy="11" r="10.75" fill="none" />
                                  </g>
                                </svg>
                            </div> -->
                            <span class="text-capitalize font-weight-bold h6 mb-0">I want a new number</span>
                        </div>
                        <span><span class="text-uppercase mr-1">free</span>
                        <span style="opacity: 0.7;"><del>{{19.99 | currency}}</del></span>
                        </span>
                    </div>
                    <div [@slideInOut]="accordianShowNewNumber">
                        <div class="border rounded" style="border-top: 0 !important;">
                            <div class="m-2 rounded">
                                <input readonly class="bg-white rounded-top border-0" type="text" [ngClass]="{ 'is-invalid': ((!isValid && submitted) || (primaryNumber.touched && !isValid)) &&  primaryPhone.isNew, 'form-control h-100 primary-number-input':true}" mask="(000) 000-0000"
                                    name="primaryNumber" #primaryNumber="ngModel" placeHolder="(XXX) XXX-XXXX" [(ngModel)]="primaryPhone.number" (keyup)="setIsNewStatus(); savePrimaryNumber()">
                                <div class="text-muted font-weight-bolder text-center rounded-bottom text-uppercase" style="background-color: #D4D4D4; font-size: 12px;">YOUR NEW NUMBER WILL BE ACTIVATED AFTER PLACING ORDER
                                </div>
                                <voiply-new-number-home (numberSelected)="onNumberSelected($event)" [tollFreeNumberNotAllowed]="tollFreeNumberNotAllowed" [isNew]="primaryPhone.isNew">
                                </voiply-new-number-home>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
