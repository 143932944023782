import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'voiply-footer-home-after-checkout',
  templateUrl: './footer-home-after-checkout.component.html',
  styleUrls: ['./footer-home-after-checkout.component.scss']
})
export class FooterHomeAfterCheckoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
