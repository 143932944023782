<div class="internationalScroll d-flex mt-3 card px-lg-4 py-5" style="border-radius: 0% !important; border: 0px solid #ececec;">
    <div class="card-header bg-white border-0">
        <div style="position: absolute;">
            <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block" style="position: absolute; left: -58px; top: -10px; font-size: 60px; color:#D3D4D5"></i>
        </div>
        <div class="d-flex flex-column heading-font">
            <div class="h2 font-weight-bolder">
                International calls
            </div>
            <div>
                Free Calling to <span class="text-secondary font-weight-bolder">50+ Countries Landline </span>
            </div>
        </div>
        <div class="text-center" style="position: relative;"><img class="img-fluid" style="object-fit: contain;" src="assets/images/worldMap.png">
            <div class="call-rate d-flex flex-column justify-content-center">
                <span class="font-weight-bold mb-2">Where do you call the most?</span>
                <voiply-international-callrates-new (getInternationalCallRates)="getInternationalCallRates($event)" [currentRate]="currentRate" [loading]="loading" [country]="country"></voiply-international-callrates-new>
            </div>
        </div>
    </div>
    <div>
    </div>
</div>
