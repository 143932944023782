<!-- <div style="background-color: #fff">
    <div class="container">
        <div class="d-flex flex-row align-items-center justify-content-start">
            <div class="border-right">
                <img class="mr-3 my-3" src="assets/images/{{logo}}" style="height: 30px;">
            </div>
            <div class="flex-fill">
                <div class="d-none d-lg-block">
                    <nav class="nav  sub-header-menu ml-3">
                        <a class="cursor-pointer nav-link" (click)="onShowAllFeatures()">Features</a>
                        <a class="cursor-pointer nav-link" (click)="scrollTo('phonesAndApps')">Phones & Apps</a>
                        <a class="cursor-pointer nav-link" (click)="scrollTo('checkout')">Express Checkout</a>
                        <a class="cursor-pointer nav-link" (click)="scrollTo('faq'); clickTo('faq-expand-button')">Frequently Asked Questions</a>
                    </nav>
                </div>
            </div>
            <div>
                <h6 class="py-3 m-0 font-weight-bold d-flex flex-column flex-sm-row">
                    NEED HELP?<span class="text-secondary d-none d-md-block pl-2"> CALL
            {{helpNumber | mask:'(000) 000-0000'}}</span>
                    <span class="text-secondary d-md-none"><a href="tel:{{helpNumber}}"> CALL
              {{helpNumber | mask:'(000) 000-0000'}}</a></span>
                </h6>
            </div>
        </div>
    </div>
</div> -->

<div class="bg-white d-none d-lg-block">
    <div class="container-fluid">
        <div class="d-flex flex-row align-items-center justify-content-start">
            <div class="d-flex flex-row">
                <img class="my-2" src="assets/images/{{logo}}" style="height: 45px;">
            </div>
            <div class="flex-fill">
                <div class="d-none d-lg-block">
                    <nav class="nav sub-header-menu">
                        <a class="cursor-pointer nav-link text-uppercase font-weight-bolder mr-xl-3 my-auto text-secondary" (click)="scrollTo('phones')">choose bundle</a>
                        <a class="cursor-pointer nav-link text-uppercase font-weight-bolder mx-xl-3" (click)="scrollTo('primary-number')">Keep number</a>
                        <a class="cursor-pointer nav-link text-uppercase font-weight-bolder mx-xl-3" (click)="scrollTo('comapany-features')">Features</a>
                        <a class="cursor-pointer nav-link text-uppercase font-weight-bolder mx-xl-3" (click)="scrollTo('faq');">faq</a>
                    </nav>
                </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-end font-weight-bold">
                <div class="text-capitalize">Get help</div>
                <div class="text-secondary ml-1">{{helpNumber | mask:'(000) 000-0000'}}</div>
                <div class="btn btn-dark rounded ml-3 font-weight-bold" (click)="goToBusiness()"> Need Business Service? </div>
            </div>
        </div>
    </div>
</div>

<div class="shadow bg-white d-block d-lg-none">
    <div class="container-fluid">
        <div class="d-flex flex-row align-items-center justify-content-between">
            <div class="d-flex flex-row align-items-center justify-content-between font-weight-bold w-100 py-2">
                <div class="d-flex font-weight-bolder">
                    <div class="text-capitalize">Get help</div>
                    <div class="text-danger ml-1">{{helpNumber | mask:'(000) 000-0000'}}</div>
                </div>
                <div class="d-flex">
                    <button class="btn btn-light rounded mx-3 px-4 py-2 font-weight-bold" (click)="goToBusiness()">
                        Business</button>
                    <button class="btn text-secondary d-flex align-items-center" (click)="toggleAccordianShow()"><i
                            class="fas fa-bars" style="font-size: 24px;"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div [@slideInOut]="toggleAccordian">
            <div class="d-flex flex-column align-items-stretch text-center">
                <a class="cursor-pointer nav-link text-uppercase font-weight-bolder mr-xl-3 my-auto text-danger" (click)="scrollTo('phones')">choose bundle</a>
                <a class="cursor-pointer nav-link text-uppercase font-weight-bolder mx-xl-3" (click)="scrollTo('primary-number')">Keep number</a>
                <a class="cursor-pointer nav-link text-uppercase font-weight-bolder mx-xl-3" (click)="scrollTo('comapany-features')">Features</a>
                <a class="cursor-pointer nav-link text-uppercase font-weight-bolder mx-xl-3" (click)="scrollTo('faq');">faq</a>
            </div>
        </div>

    </div>
</div>

<ng-container *ngIf="systemFeatures">
    <common-ui-features-model [features]="systemFeatures.features"></common-ui-features-model>
</ng-container>