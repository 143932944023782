import { Component, NgZone, OnInit } from '@angular/core';
import { IntroBase } from '../intro.base';

@Component({
  selector: 'voiply-intro-home',
  templateUrl: './intro-home.component.html',
  styleUrls: ['./../intro.base.scss']
})
export class IntroHomeComponent extends IntroBase implements OnInit {
  headerHeight = 0;
  observer;
  constructor(private zone: NgZone) {
    super();
  }

  ngOnInit() {
    this.observer = new (window as any).ResizeObserver(entries => {
      this.zone.run(() => {
        this.headerHeight = entries[0].contentRect.height;
      });
    });
    this.observer.observe(document.getElementById('head-bar'));
  }

  ngOnDestroy() {
    this.observer.unobserve(document.getElementById('head-bar'));
  }
}
