<!-- TOLL FREE NUMBERS -->
<div class="row">
  <div class="col-12">
    <h6 class="font-weight-bold mb-1">1- Toll-Free Prefixes</h6>
    <!-- <div class="local-number row m-1"> -->
      <div class="select-input">
        <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
        <select class="select-custom"   (change)="getTollFreeNumbers($event.target.value)" style="background-color: #EEEEEE !important;">
          <option value="" class="placeholder" selected="selected">Select Prefixes</option>
          <option *ngFor="let num of tollFreePrefixes" [value]="num">{{num}}</option>
        </select>
      </div>

      <!-- <div class="col-6 col-sm-4 mb-1" style="padding-top: 0!important; font-size: 12px;"
        *ngFor="let num of tollFreePrefixes">
        <label class="radio-container">
          <input type="radio" name="tollFreePrefixRadio" [value]="num" [ngModel]="855"
            (click)="getTollFreeNumbers(num)">
          <span class="checkmark"></span>
          <span>({{num}}) Number</span>
        </label>
      </div> -->
    <!-- </div> -->
  </div>
  <div class="col-12 mt-2">
    <h6 class="font-weight-bold mb-1">2- Number</h6>

    <!-- <div class="numb-input2">
      <input class="form-control" placeholder="Filter Digits or Terms" name="" type="text"
        (keyup)="onTollFreeSearch($event.target.value)" [(ngModel)]="tollfreeSearchValue">
    </div> -->
    <!-- <div class="row mt-3">
      <div class="col-12" *ngIf="matchingTollFreeNumbers.length == 0 && tollfreeSearchValue.length > 0">
        <span class="text-muted">No matching number found.</span>
      </div>
      <div class="d-flex justify-content-center col-12" *ngIf="searching">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
      <div class="col-6 col-sm-4 mb-1" *ngFor="let tollFreeNumber of matchingTollFreeNumbers" style="font-size: 12px">
        <label class="radio-container">
          <input type="radio" name="tollfreenumber" [value]="tollFreeNumber"
            (click)="selectedNumberChanged(tollFreeNumber)">
          <span class="checkmark"></span>
          <span>{{tollFreeNumber | formatPhoneNumber}}</span>
        </label>
      </div>
    </div> -->
    <div class="select-input">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom"   style="background-color: #EEEEEE !important;" (change)="selectedNumberChanged($event.target.value)">
        <option value="" class="placeholder" selected="selected">Select Number</option>
        <option *ngFor="let tollFreeNumber of matchingTollFreeNumbers" [value]="tollFreeNumber">{{tollFreeNumber}}</option>
      </select>
    </div>

  </div>
</div>