import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'voiply-product-configuration-phone-status-new',
  templateUrl: './product-configuration-phone-status-new.component.html',
  styleUrls: ['./product-configuration-phone-status-new.component.scss']
})
export class ProductConfigurationPhoneStatusNewComponent implements OnInit {
  @Input() phoneStatus: any;
  constructor() { }

  ngOnInit() {
  }

}
