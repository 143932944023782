<div class="h-100" [style.opacity]="updatingOrder || coolDownPeriodOn ? 0.4 : 1">
    <button class="btn bg-grey rounded-lg h-100" (click)="portingNumberModal.show()">
		<span>
			<i class="fas fa-list fa-2x d-none d-md-block"></i>
		</span>
		<h5 class="m-0 font-weight-bold pt-2">More Numbers</h5>
	</button>
</div>

<div class="modal fade" tabindex="-1" #portingNumberModal="bs-modal" bsModal role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Select a new number</h5>
            </div>
            <div class="modal-body">
                <div>Enter numbers per row unformatted.</div>
                <textarea rows="4" cols="50" #portingNumbers class="form-control" [ngModel]="additionalNumberArray"></textarea>
            </div>
            <div class="modal-footer d-flex justify-content-between">

                <button type="button" class="btn btn-primary" *ngIf="showAddtoCompanyNumber" (click)="updatePortingNumber(portingNumbers.value,true);portingNumberModal.hide()">Add to Company
					Numbers</button>
                <div class="text-right flex-grow-1">
                    <button type="button" class="btn btn-outline-dark mr-2" data-dismiss="modal" (click)="portingNumberModal.hide()">Cancel</button>
                    <button type="button" class="btn btn-primary h-100" (click)="updatePortingNumber(portingNumbers.value,false);portingNumberModal.hide()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
