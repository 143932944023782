<div class="pt-3" *ngIf="speedTest">
  <common-ui-section-header imagePath="/assets/images/speedometer.png" title="VoIP Speed Test">
    <ng-template>
      <ng-container>
        <div
          class="flex-grow-1 d-flex align-items-center justify-content-end bg-secondary pr-3 sectionheadertext-color h-100 text-secondary"
          *ngIf="!speedTest.testInProgress">

          <i class="fas fa-exclamation  pr-3"></i>
          <span class="text-success d-none d-md-block"
            *ngIf="speedTest.downloadSpeed > 2 && speedTest.uploadSpeed > 1 && speedTest.ping <= 50 && speedTest.jitter <= 25">
            Your internet is ready for Voip. Save with our preferred providers.
          </span>
          <span class="d-none d-md-block" style="color: #FF7373;"
            *ngIf="speedTest.downloadSpeed <= 2 || speedTest.uploadSpeed <= 1 || speedTest.ping > 50 || speedTest.jitter > 35">
            <strong>Voip Problem detected.</strong> Try our preffered providers
          </span>
        </div>
      </ng-container>
    </ng-template>
  </common-ui-section-header>
  <div class="row py-3  justify-content-center">
    <div class="col-md-12 col-lg-4">
      <!--speed test-->
      <!-- <voiply-speed-test-result [speedtest]="speedTest"></voiply-speed-test-result> -->
    </div>
    <div class="col-md-12 col-lg-8">
      <!--internet providers-->
      <voiply-internet-providers [providers]="providers" [selectedInternetProvider]="internetProvider$ | async">
      </voiply-internet-providers>
    </div>
  </div>
</div>