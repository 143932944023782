import { Component, OnInit } from '@angular/core';
import { CompanyFeatures } from '../company-features.base';
import { CompanyFeaturesService } from '../../services/company-features.service';

@Component({
  selector: 'voiply-company-features-home',
  templateUrl: './company-features-home.component.html',
  styleUrls: ['../company-features.scss', './company-features-home.component.scss']
})
export class CompanyFeaturesHomeComponent extends CompanyFeatures {

  constructor(service: CompanyFeaturesService) {
    super(service);
  }

  onShowAllFeatures() {
    this.features.features = this.features.features.filter(f => f.title != 'Fax to Email');
    this.featuremodel.openFeatures();
  }
  //* text to display in extra line card
  extralineText = [
    {
      id: 1,
      text: `<b>You can answer an incoming call to your Voiply number as if you were at home.</b> All calls to your Voiply number, will ring your home phone
      and the app.`
    },
    {
      id: 2,
      text: `<b>Make calls on 3G/4G or use Wi-Fi</b> when you are in poor cell coverage areas (standard data rates apply).`
    },
    {
      id: 3,
      text: `<b>You will know an incoming call</b> is to your Voiply number or to your
      mobile number.`
    },
    {
      id: 4,
      text: `Calls you make from the app will <b>use your home Voiply number as
      your caller ID.</b>`
    }
  ]
}
