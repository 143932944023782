import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'voiply-apply-changes-new',
  templateUrl: './apply-changes-new.component.html',
  styleUrls: ['./apply-changes-new.component.scss']
})
export class ApplyChangesNewComponent implements OnInit {

  @Input() updatingOrder: boolean;
  @Input() coolDownPeriodOn: boolean;
  @Output() updateOrder = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  updateOrderDetails() {
    this.updateOrder.emit();
  }
}
