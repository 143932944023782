import { Component, OnInit, Inject } from '@angular/core';
import { Providers, SpeedTest } from '../../model/speed-test.model';
import { SpeedTestService } from '../../services/speed-test.service';
import { Select } from '@ngxs/store';
import { AppStateSelectors, OnDestroyCleanup, IEnvironment, ENVIRONMENT } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';

@Component({
  selector: 'voiply-speed-test',
  templateUrl: './speed-test.component.html',
  styleUrls: ['./speed-test.component.css']
})
export class SpeedTestComponent extends OnDestroyCleanup implements OnInit {

  @Select(AppStateSelectors.internetProviders) internetProvider$: Observable<any>;

  constructor(private speedTestService: SpeedTestService,@Inject(ENVIRONMENT) private environment: IEnvironment) {
    super();
  }

  speedTest: SpeedTest;
  providers: Providers[] = [
    {
      heading: 'BEST FIBER',
      image: 'assets/images/fios.png',
      monthlyPrice: '$99.95',
      speed: '1000 Mbps',
      reviewLink: 'https://broadbandnow.com/Verizon-Fios-reviews',
      name: 'verizon'
    },
    {
      heading: 'BEST CABLE',
      image: 'assets/images/comcast.png',
      monthlyPrice: '$69.95',
      speed: '800 Mbps',
      reviewLink: 'https://broadbandnow.com/XFINITY-reviews',
      name: 'xfinity'
    },
    {
      heading: 'BEST VALUE',
      image: 'assets/images/att.png',
      monthlyPrice: '$49.95',
      speed: '2 Mbps',
      reviewLink: 'https://broadbandnow.com/ATT-Wireless',
      name: 'att'
    }
  ];


  ngOnInit() {
    // Start speed test on live environment only.
    if (this.environment.production) {
      setTimeout(() => {
        this.subscriptions$.add(this.speedTestService.valueChanged.subscribe(value => this.speedTest = value));
        console.log('Starting speed test');
        this.speedTestService.InitializeTest();
      }, 60 * 1000);
    }
  }
}
