<div [ngClass]="{'d-flex flex-row mx-4': true, 'mt-3': !cartHasHardware}">
    <div class="flex-grow-1 mb-2">
        <a (click)="openModal(template)" style="cursor: pointer">
            <h5 *ngIf="!checkoutDetail.promoCode" class="font-weight-bold  text-center pt-4" style="opacity: 0.4;">Have a promo code?
            </h5>
            <!-- <span *ngIf="!checkoutDetail.promoCode">Have a promo code?</span> -->
            <span *ngIf="checkoutDetail.promoCode" class="font-weight-bold text-secondary pb-3">
        <span>Promo Code: </span>
            <span style="text-decoration: underline" [popover]="promoDescTemplate" placement="bottom" triggers="mouseenter:mouseleave">
          {{checkoutDetail.promoCode}}
        </span>
            <ng-template #promoDescTemplate>
                <span class="text-center">Promotion Details<br>{{checkoutDetail.description}} </span>
            </ng-template>
            </span>
        </a>
    </div>
    <a *ngIf="checkoutDetail.promoCode" style="cursor: pointer; font-size: 120%;" (click)="removePromoCode.emit()"><i
      class="far fa-times text-hover-secondary"></i></a>
</div>

<!-- Promo Code Modal -->
<ng-template #template>
    <div class="modal-content">
        <div class="modal-header">
            <h4 id="dialog-static-name" class="modal-title pull-left font-weight-bolder" style="opacity: 0.4;">Have a promo code?</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Promo Code</label>
                <input type="text" class="form-control" aria-describedby="promoHelp" [(ngModel)]="promoCode">
                <small *ngIf="promoCodeApplied.message === ''" id="promoHelp" class="form-text text-muted">Enter promo code
          in the box
          above and click apply.</small>

                <small *ngIf="promoCodeApplied.message !== ''" id="promoHelp" style="color:#dc3545!important" class="form-text text-muted">{{promoCodeApplied.message}}</small>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="applyPromoCode.emit(promoCode)">Apply</button>
        </div>
    </div>
</ng-template>
