import { Select } from "@ngxs/store";
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppStateSelectors, OnDestroyCleanup, CrispService, QrCodeService } from '@voiply/shared/common-ui';
import { Users, Shippment, NumberPorting, FirstPromoter, CheckoutDetail } from '@voiply/shared/model';
import { ToastrService } from 'ngx-toastr';
import { switchMap, take } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { HeaderOrderTrackingBase } from "../header-order.tracking.base";

@Component({
  selector: 'voiply-header-order-tracking-home',
  templateUrl: './header-order-tracking-home.component.html',
  styleUrls: ['./header-order-tracking-home.component.css']
})
export class HeaderOrderTrackingHomeComponent extends HeaderOrderTrackingBase {

  constructor(crispService: CrispService, toastr: ToastrService, qrCodeService: QrCodeService, domSanitizer: DomSanitizer) {
    super(crispService,toastr,qrCodeService,domSanitizer);
  }

}
