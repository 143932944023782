<ul class="list-group list-group-flush bg-light-grey">
    <li class="list-group-item bg-light-grey" *ngIf="groupedCartItems.length == 0">
        <div class="totalrat rat-detail">
            <div class="total-left">
                <h4 class="m-0">YOUR CART ITEM IS EMPTY</h4>
            </div>
        </div>
    </li>

    <li class="list-group-item bg-light-grey" *ngFor="let cartItem of groupedCartItems">
        <div class="d-flex flex-row align-items-center">
            <div class="phone-image d-flex align-items-center justify-content-center">
                <img [src]="getItemImage(cartItem.itemId, cartItem.type)" alt=""
                    style="height: 80px; width: 80px; object-fit: contain;" *ngIf="cartItem.type!=='CompanyNumber'"
                    [ngClass]="{'rounded-lg':true}">
                <img src="assets/images/company-numbers.png" *ngIf="cartItem.type==='CompanyNumber'" alt=""
                    style="width: 55px;">
            </div>
            <div class="flex-fill pl-3">
                <span class="font-weight-bold text-secondary" style="font-size: large;">{{cartItem.qty}}x  </span> <span class="ml-1"style="font-weight: bolder;font-size: larger;">{{cartItem.heading}} </span><br>
                <!-- <span class="font-weight-bolder"> {{cartItem.heading}}</span><br> -->
                <span *ngIf="cartItem.monthlyCharge > 0" class="text-muted m-0" style="font-size: 75% !important">
                
                    {{(payAnnually ? cartItem.annualTotalCostText    : cartItem.monthlyTotalCostText) |
                    formatMoneyValue}} 

                    <br>
                    <!-- <span> {{cartItem.qty}} {{cartItem.heading}}</span> -->
                </span>
            </div>
            <div class="">
                <h3 class="font-weight-bold m-0 ">
                    <!-- <span *ngIf="cartItem.totalDiscountedCost !== cartItem.totalCost && cartItem.totalCost != 'Free'" style="font-size: 50%; text-decoration: line-through; float: right; margin-top: -15px">
                      {{cartItem.totalCost | formatMoneyValue}}
                    </span> -->
                    <ng-container *ngIf='cartItem.itemId==4'>{{''}} </ng-container>
                    <ng-container *ngIf='cartItem.itemId!==4'>{{cartItem.totalDiscountedCost | formatMoneyValue}}
                    </ng-container>
                    <!-- {{cartItem.totalDiscountedCost | formatMoneyValue}} -->
                </h3>
            </div>
        </div>
    </li>
</ul>