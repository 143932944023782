<div class="modal fade" bsModal #voiplyFeaturesModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">

  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #fe330a; color: #ffffff;">
        <h3 class="text-center" style="width: 100%">
          Voiply Features
        </h3>
        <button type="button" class="close pull-right" aria-label="Close" (click)="voiplyFeaturesModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="row">
          <li class="col-sm-6 col-md-4" *ngFor="let feature of features">{{feature.title}}</li>
        </ul>
      </div>
    </div>
  </div>

</div>