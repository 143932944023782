import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'voiply-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private auth: AuthService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    if (this.route.snapshot.queryParams.changeSignUp) {
      this.auth.signUpWithPassword() //This method is called to open lock with create account popup
    } else {
      window.location.href = 'https://portal.voiply.online';
    }
    return false;
  }
}
