import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppStateSelectors, SetPrimaryPhoneFormStatusAction, SetPrimaryPhoneNumber, OnDestroyCleanup, sanitizePhoneNumber, AddCompanyNumberAction, RemoveCompanyNumberAction, UpdateCompanyNumberAction, CompanyNumberNameChangeModalComponent, CalculatingTotalAction, UpdatePrimaryNumberInCompanyNumberAction } from '@voiply/shared/common-ui';
import * as Model from '@voiply/shared/model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { NumberSearchService } from '@voiply/shared/number-search';
import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal/';
import * as _ from 'lodash';
import { CartItemType, PrimaryPhone } from '@voiply/shared/model';

@Component({
  selector: 'voiply-primary-number-business',
  templateUrl: './primary-number-business-new.component.html',
  styleUrls: ['./primary-number-business.component.scss']
})
export class PrimaryNumberBusinessComponent extends OnDestroyCleanup implements OnInit {

  toggle:boolean = true;
  flag:boolean=false;

  onClick(btnId){
    if(btnId=='1' && this.toggle==false){
      this.toggle=!this.toggle;
      return;
    }
    if(btnId=='2' && this.toggle==true){
      this.toggle=!this.toggle;
      return;
    }
  }
  primaryPhone: Model.PrimaryPhone;
  isValid = false;
  submitted = false;
  companyNumbers: Model.CompanyNumber[] = [];
  canAddCompanyNumbers = true;
  modalRef: BsModalRef;
  canshowCompanyNumber = false;
  selectedNewNumberInfo:Model.PrimaryPhone;

  @Select(AppStateSelectors.primaryPhone) primaryPhone$: Observable<Model.PrimaryPhone>;
  @Select(AppStateSelectors.currentLocation) currentLocation$: Observable<any>;
  @Select(AppStateSelectors.doValidate) performValidation$: Observable<object>;
  @Select(AppStateSelectors.companyNumbers) companyNumbers$: Observable<any>;
  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;

  @Dispatch() setPrimaryPhoneNumber = (phoneNumber, updateInServer = true) => new SetPrimaryPhoneNumber(phoneNumber, updateInServer);
  @Dispatch() primaryNumberFormStatusChanged = (isValid) => new SetPrimaryPhoneFormStatusAction(isValid);
  @Dispatch() addCompanyNumber = (phoneNumber: Model.CompanyNumber) => new AddCompanyNumberAction(phoneNumber);
  @Dispatch() removeCompanyNumber = (phoneNumber: Model.CompanyNumber) => new RemoveCompanyNumberAction(phoneNumber);
  @Dispatch() updateCompanyNumber = (phoneNumber: Model.CompanyNumber) => new UpdateCompanyNumberAction(phoneNumber);
  @Dispatch() calculatingTotal = (isCalculating: boolean) => new CalculatingTotalAction(isCalculating);
  @Dispatch() updatePrimaryNumberInCompanyNumber = (phoneNumber,isNew) => new UpdatePrimaryNumberInCompanyNumberAction(phoneNumber,isNew);
  constructor(private numberSearchService: NumberSearchService, private toastr: ToastrService, private modalService: BsModalService) {
    super();
    this.subscriptions$.add(this.companyNumbers$.subscribe((companyNumbers) => {
      this.companyNumbers = companyNumbers
    }))
    this.subscriptions$.add(this.primaryPhone$.subscribe(phoneNumber => {
      this.primaryPhone = phoneNumber;
      this.isValid = phoneNumber.isValid;
    }));
    this.performValidation$.subscribe(newvalue => {
      if (newvalue)
        this.submitted = true;
    });

    this.subscriptions$.add(this.cartItems$.subscribe(cartItems => {
      if (!(_.size(cartItems) > 0)) {
        this.canshowCompanyNumber = true;
        return;
      }
      _.each(cartItems, (cartItem) => {
        if (cartItem.type === CartItemType.Phone) {
          this.canshowCompanyNumber = true;
          //This return is to exit loop
          return false;
        } else {
          this.canshowCompanyNumber = false;
        }
      });
    }));
  }


  ngOnInit(): void {
    //#region Commented below section, since we dont want to load system generated primary number by default on business
    // this.subscriptions$.add(this.currentLocation$.subscribe(currentLocation => {
    //   // Get temp number to be shown in number textbox
    //   if (location.origin.indexOf("localhost") > -1) {
    //     if (this.primaryPhone.number === '' || this.primaryPhone.number === null && currentLocation.stateCode != null) {
    //        this.setPrimaryPhoneNumber({ number: '1111111111', isNew: false, systemSelected: false });
    //        this.addCompanyNumber({ number: '1111111111', isNew: false, systemSelected: false, isPrimaryNumber: true, name: 'Phone Number', callFlow: new Model.CallFlow, isSelectedCallFlow: true })
    //     }
    //   }
    //   else {
    //     if (this.primaryPhone.number === '' || this.primaryPhone.number === null && currentLocation.stateCode != null) {
    //       this.numberSearchService.getNumbersByState(currentLocation.stateCode, '', currentLocation.stateCode === "NY" ? '929' : '')
    //         .subscribe(o => {
    //           if (o && o.numbers && o.numbers.length > 0) {
    //              this.setPrimaryPhoneNumber({ number: o.numbers[0].toString(), isNew: true, systemSelected: true });
    //            this.addCompanyNumber({ number: o.numbers[0].toString(), isNew: true, systemSelected: true, isPrimaryNumber: true, name: 'Phone Number', callFlow: new Model.CallFlow, isSelectedCallFlow: true })
    //           }
    //         })
    //     }
    //   }
    // }));
    //#endregion
    
    this.toggle=!this.primaryPhone.isNew;
    
  }
  onNumberSelected(phoneNumber: any) {
    //New Number Event
    /*const sanitizedPhoneNumber = sanitizePhoneNumber(phoneNumber.number.toString());
    if (this.primaryPhone) {
      this.calculatingTotal(true);
      this.addCompanyNumber({ number: sanitizedPhoneNumber, isNew: phoneNumber.isNew, systemSelected: false, isPrimaryNumber: false, name: 'Phone Number', callFlow: new Model.CallFlow, isSelectedCallFlow: false })
    }
    else
      this.setPrimaryPhoneNumber({ number: sanitizedPhoneNumber, isNew: phoneNumber.isNew, systemSelected: false });
    */
  }
  onInternationalNumberSelected(internationalNumber) {
    if (!this.primaryPhone) {
      this.toastr.error('Please Add a Primary Number before adding a International Number');
    } else {
      this.calculatingTotal(true);
      if (this.primaryPhone.number === '' || this.primaryPhone.number === null) {
        this.addCompanyNumber({ number: internationalNumber.number, isNew: internationalNumber.isNew, systemSelected: false, isInternational: true, countryCode: internationalNumber.countryCode, areaCode: internationalNumber.areaCode, dialCode: internationalNumber.dialCode, package: internationalNumber.package, country: internationalNumber.country, isPrimaryNumber: false, name: 'Phone Number', /*callFlow: new Model.CallFlow,*/ callScenario: new Model.CallScenarioModel,countryShortCode: internationalNumber.countryShortCode,isSelectedCallScenario:true })
      } else {
        this.addCompanyNumber({ number: internationalNumber.number, isNew: internationalNumber.isNew, systemSelected: false, isInternational: true, countryCode: internationalNumber.countryCode, areaCode: internationalNumber.areaCode, dialCode: internationalNumber.dialCode, package: internationalNumber.package, country: internationalNumber.country, isPrimaryNumber: false, name: 'Phone Number', /*callFlow: new Model.CallFlow,*/ callScenario: new Model.CallScenarioModel,countryShortCode: internationalNumber.countryShortCode, isSelectedCallScenario:false })
      }
    }
  }
  onUpdate(existingNumber: any) {
    //Bring Your Number Event
    const sanitizedPhoneNumber = sanitizePhoneNumber(existingNumber.number.toString());
    if (this.companyNumbers.length > 0) {
      this.calculatingTotal(true);
      this.addCompanyNumber({ number: sanitizedPhoneNumber, isNew: existingNumber.isNew, systemSelected: false, isPrimaryNumber: false, name: 'Phone Number', /*callFlow: new Model.CallFlow,*/ callScenario: new Model.CallScenarioModel, isSelectedCallScenario:false })
    }
    else {
      this.setPrimaryPhoneNumber({ number: sanitizedPhoneNumber, isNew: existingNumber.isNew, systemSelected: existingNumber.systemSelected });
      this.addCompanyNumber({ number: sanitizedPhoneNumber, isNew: existingNumber.isNew, systemSelected: false, isPrimaryNumber: true, name: 'Phone Number', /*callFlow: new Model.CallFlow,*/ callScenario: new Model.CallScenarioModel, isSelectedCallScenario:true })

    }

  }

  setIsNewStatus(event) {
    //need to change color of "Existing Number" and "New Number" buttons when user starts typing primary number
    //set isNew status on every key press, only in state and not in cosmos db
    if (this.primaryPhone.number !== null) {
      const sanitizedPhoneNumber = sanitizePhoneNumber(this.primaryPhone.number.toString());
      this.setPrimaryPhoneNumber({ number: sanitizedPhoneNumber, isNew: false, systemSelected: this.primaryPhone.systemSelected }, false);
    }
    if (this.isValid) {
      if (this.primaryPhone.number !== null) {
        const sanitizedPhoneNumber = sanitizePhoneNumber(this.primaryPhone.number.toString());
        this.setPrimaryPhoneNumber({ number: sanitizedPhoneNumber, isNew: this.primaryPhone.isNew, systemSelected: this.primaryPhone.systemSelected });
        if(this.companyNumbers.length<1){ 
          this.addCompanyNumber({ number: sanitizedPhoneNumber, isNew: this.primaryPhone.isNew, systemSelected: this.primaryPhone.systemSelected, isPrimaryNumber: true, name: 'Phone Number', /*callFlow: new Model.CallFlow,*/ callScenario: new Model.CallScenarioModel, isSelectedCallScenario: true })}
        else
          this.updatePrimaryNumberInCompanyNumber(sanitizedPhoneNumber,false)
      }
    }
  }
  onRemoveCompanyNumber(phoneNumber: Model.CompanyNumber) {
    if (!phoneNumber.isInternational) {
      const filteredNonInternationalCompanyNumbers = _.filter(this.companyNumbers, (companyNumber) => !companyNumber.isInternational);
      if (filteredNonInternationalCompanyNumbers.length <= 1) {
        this.toastr.error(`Atleast one Company Number is required. If you want to remove ${phoneNumber.number}, Please select any Other Number First`)
        return;
      }
    }

    this.calculatingTotal(true);
    this.removeCompanyNumber(phoneNumber);

  }

  // onUpdatePrimaryNumberStatus(phoneNumber: Model.CompanyNumber) {
    onUpdatePrimaryNumberStatus(phoneNumber : any,isNew : boolean) {
    //debugger
    
    //phoneNumber.isPrimaryNumber = true;
  
    this.setPrimaryPhoneNumber({ number: phoneNumber.number, isNew: isNew, systemSelected: false ,selectedNewNumberInfo:phoneNumber.selectedNewNumberInfo});
    this.updatePrimaryNumberInCompanyNumber(phoneNumber.number,phoneNumber.isNew)
    if(this.companyNumbers.length==0){
      this.addCompanyNumber({ number: phoneNumber.number, isNew: this.primaryPhone.isNew, systemSelected: this.primaryPhone.systemSelected, isPrimaryNumber: true, name: 'Phone Number', /*callFlow: new Model.CallFlow,*/ callScenario: new Model.CallScenarioModel, isSelectedCallScenario: true });
    }
    //this.updateCompanyNumber(phoneNumber);
    /* para phone number -> Company number each -> read Obj -> Replace if true -> patch state 
    Update primary number in company number 
    */
  }

  onUpdateSelectedCallFlowStatus(phoneNumber: Model.CompanyNumber) {
    
    //phoneNumber.isSelectedCallFlow = true;
    phoneNumber.isSelectedCallScenario = true;
    this.updateCompanyNumber(phoneNumber);
  }
  onUpdatePrimaryNumberName(phoneNumber: Model.CompanyNumber) {
    const initialState = {
      companyNumber: phoneNumber
    };
    this.modalRef = this.modalService.show(CompanyNumberNameChangeModalComponent, { initialState, backdrop: 'static' });
    this.modalRef.content.nameChange.subscribe(
      result => {
        phoneNumber.name = result
        
        this.updateCompanyNumber(phoneNumber)
      }
    )
  }

  sanitizeInternationalNumber(companyNumber) {
    return companyNumber.countryCode + ' ' + companyNumber.areaCode + ' ' + companyNumber.dialCode.substring(0, 3) + '-' + companyNumber.dialCode.substring(3, 7);
  }

  savePrimaryNumber() {
    if (this.primaryPhone.number !== null) {
      const sanitizedPhoneNumber = sanitizePhoneNumber(this.primaryPhone.number.toString());
      this.setPrimaryPhoneNumber({ number: sanitizedPhoneNumber, isNew: this.primaryPhone.isNew, systemSelected: this.primaryPhone.systemSelected });
    }
  }
  
}
