import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { AppStateSelectors, OnDestroyCleanup, AnnualBillingModalComponent, AddMorePhonesAction, CalculatingTotalAction, TroubleshootingComponent, APPSCONST } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';
import { Shippment, CheckoutDetail, Customer } from '@voiply/shared/model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal/';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from 'libs/shared/common-ui/src/lib/services';
import * as _ from 'lodash';
import { HomeConfigurationModalComponent } from 'libs/product-configuration/src/lib/components';
import { AuthService } from '@voiply/auth0';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { AddMorePhonesHomeComponent } from 'libs/checkout/src/lib/containers';

@Component({
  selector: 'voiply-after-checkout-page',
  templateUrl: './after-checkout-page.component.html',
  styleUrls: ['./after-checkout-page.component.scss']
})
export class AfterCheckoutPageComponent extends OnDestroyCleanup implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  mobileQuery: MediaQueryList;
  bsModalRef: BsModalRef;
  shipment: Shippment = new Shippment();
  orderId = '';
  email = '';
  companyName = '';
  titleHeight = 0;
  productConfiguration = [];
  scrollToTopbottomHeight = 0;
  showScrollToTop: boolean;
  isPayAnually = false;
  showEmergency999 = false;
  customer: Customer;
  userIsVoiplyMember = false;
  checkoutDetail: CheckoutDetail;
  isFaxOnly: boolean = false;
  get nativeWindow(): any {
    return window;
  }
  private _mobileQueryListener: () => void;
  @ViewChild('snav') snav: MatSidenav;
  @Select(AppStateSelectors.shipment) shipment$: Observable<Shippment>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;
  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;
  @Select(AppStateSelectors.isPayAnually) isPayAnually$: Observable<boolean>;
  @Select(AppStateSelectors.customer) customer$: Observable<any>;
  @Select(AppStateSelectors.userIsVoiplyMember) userIsVoiplyMember$: Observable<boolean>;
  @Dispatch() addMorePhones = () => new AddMorePhonesAction();
  @Dispatch() recalculatingTotal = (isCalculating: boolean) => new CalculatingTotalAction(isCalculating);
  constructor(private titleService: Title, private modalService: BsModalService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private sidenavService: SidenavService, private authService: AuthService) {
    super();
    this.titleService.setTitle('Voiply Online Customer Portal');
    this.subscriptions$.add(this.shipment$.subscribe((shipment) => this.shipment = shipment));
    this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      this.email = checkoutDetail.billingAddress.email;
      this.companyName = checkoutDetail.companyName;
      this.checkoutDetail = checkoutDetail
    }));
    this.subscriptions$.add(this.isPayAnually$.subscribe((isPayAnually) => {
      this.isPayAnually = isPayAnually;
    }));
    this.subscriptions$.add(this.customer$.subscribe((customer) => {
      if (customer)
        this.customer = customer;
    }));
    this.subscriptions$.add(this.userIsVoiplyMember$.subscribe((userIsVoiplyMember) => { this.userIsVoiplyMember = userIsVoiplyMember }))
    this.cartItems$.subscribe((data) => {
      this.productConfiguration = data;
      if (_.filter(this.productConfiguration, value => value.heading === APPSCONST.EMERGENCY).length) {
        this.showEmergency999 = true;
      }
      if (
          _.filter(this.productConfiguration, value => value.heading === APPSCONST.ONLINEFAX).length == Object.keys(this.productConfiguration).length
        ) this.isFaxOnly = true;
    });

    this.mobileQuery = media.matchMedia('(max-width: 1441px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    const changeSignUp = localStorage.getItem('changeSignUp');
    if (changeSignUp && !this.userIsVoiplyMember && !this.customer.updatedSignUp) {
      this.authService.logout({ changeSignUp: true });
      localStorage.removeItem('changeSignUp');
    }
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.snav);
  }

  ngAfterViewChecked() {
    this.titleHeight = document.getElementById('top-header').clientHeight;
    if ((document.documentElement.scrollHeight - document.documentElement.scrollTop) <=
      (document.documentElement.offsetHeight + 100)) {
      this.scrollToTopbottomHeight = document.getElementById('footer').offsetHeight + 16;
    } else {
      this.scrollToTopbottomHeight = 16;
    }
    if (document.documentElement.scrollTop <= 1200) {
      this.showScrollToTop = false;
    } else {
      this.showScrollToTop = true;
    }
    this.changeDetectorRef.detectChanges()
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  scrollTo(id) {
    const el = document.getElementById(id);
    if (el)
      el.scrollIntoView({ behavior: "smooth" });
  }

  annualBillingComponent() {
    const initialState = {
      orderId: this.orderId,
      checkoutDetail: this.checkoutDetail
    };
    this.bsModalRef = this.modalService.show(AnnualBillingModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  closeNav() {
    if (this.mobileQuery.matches) {
      this.snav.close()
    }
  }

  openEmergencyApp() {
    _.each(this.productConfiguration, (values, keys) => {
      if (values.heading === APPSCONST.EMERGENCY) {
        const initialState = {
          key: keys,
          value: values,
        };
        this.bsModalRef = this.modalService.show(HomeConfigurationModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
        status = this.bsModalRef.content.form.status;
      }
    });
  }
  openTroubleShooting() {
    const initialState = {
      orderId: this.orderId
    };
    this.bsModalRef = this.modalService.show(TroubleshootingComponent, { initialState, class: 'modal-lg', backdrop: 'static' });

  }

  openCrispChat() {
    this.nativeWindow.$crisp.push(["do", "chat:open"]);
  }
  authPopup() {
    this.authService.logout({ changeSignUp: true });
  }

  addMorePhonesComponent() {
    const initialState = {
      orderId: this.orderId
    };
    this.recalculatingTotal(true);
    this.addMorePhones();
    this.bsModalRef = this.modalService.show(AddMorePhonesHomeComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
