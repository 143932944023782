import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'voiply-cookie-concent',
  templateUrl: './cookie-concent.component.html',
  styleUrls: ['./cookie-concent.component.scss']
})
export class CookieConcentComponent {

  showCookieConsent: boolean = localStorage.getItem("cookieConsentAccepted") ? false : true;

  acceptCookieConsent() {
    localStorage.setItem("cookieConsentAccepted", "User accepted cookie consent");
    this.showCookieConsent = false;
  }

}
