import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'voiply-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() buttonText: string;
  @Input() logo: string;
  currentYear = new Date().getUTCFullYear();
  shareUrl: string;
  @Output()
  navigatePage = new EventEmitter();

  ngOnInit() {
    this.shareUrl = location.origin;
  }
}
