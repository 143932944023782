<!-- <div class="d-flex align-items-center pb-3">
    <div class="flex-fill">
        <span class="font-weight-bold m-0 ">Choose your delivery option</span>
    </div>
    <div>
        <ng-container *ngIf="calculatingTotal">
            <mat-spinner class="float-right" diameter="26" value="accent"></mat-spinner>
        </ng-container>
        <ng-container *ngIf="!calculatingTotal">
            <span class="text-secondary">
        <h4 class="font-weight-bold m-0">{{checkoutDetail.discountedShippingCharges | formatMoneyValue}}
        </h4>
      </span>
        </ng-container>
    </div>
</div> -->

<!-- <div class="d-flex">
  <div class="select-input" id="b-state">
    <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
    <select class="select-custom form-control" [ngModel]="checkoutDetail.shippingCharges" name="shippingOptions"
      (change)="shippingChange.emit(shippingOption)">
      <option *ngFor="let shippingOption of shippingOptions" [value]="shippingOption.charges" style="color: #212529">
        <div style="color: #fe330a">{{shippingOption.displayDate}}</div>
        {{shippingOption.charges | formatMoneyValue}} - {{shippingOption.displayTitle}}
      </option>
    </select>
  </div>
</div> -->

<!-- <label *ngFor="let shippingOption of shippingOptions" class="radio-container d-flex flex-row">
  <div class="flex-column">
    <input type="radio" [ngModel]="checkoutDetail.shippingCharges" name="shippingOptions"
      (change)="shippingChange.emit(shippingOption)" style="margin-right: 10px; margin-top: -2px"
      [value]="shippingOption.charges">
    <div style="color: #fe330a">{{shippingOption.displayDate}}</div>
    <div> {{shippingOption.charges | formatMoneyValue}} - {{shippingOption.displayTitle}}</div>
    <span class="checkmark"></span>
  </div>
</label> -->

<div class="btn-group w-100" dropdown>
    <div class="bg-white border-0 w-100 py-2 px-3 dropdown-toggle d-flex justify-content-between align-items-center cursor-pointer rounded" dropdownToggle aria-controls="dropdown-basic" id="dropdown-context-lable">
        <div class="d-flex align-items-center text-wrap font-weight-bold">
            {{selectedShippingOption.displayDate}} - {{selectedShippingOption.charges | formatMoneyValue}} ({{selectedShippingOption.displayTitle}})
        </div>
    </div>

    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="button-basic">
        <ng-container *ngFor="let shippingOption of shippingOptions; let i of index">
            <li role="menuitem"><button class="dropdown-item w-100" style="outline: 0;" (click)="onShippingOptionChange(shippingOption)">
          <div class="d-flex align-items-center flex-wrap">
            <span>{{shippingOption.displayDate}} - {{shippingOption.charges | formatMoneyValue}}
              ({{shippingOption.displayTitle}})</span>
          </div>
        </button>
            </li>
        </ng-container>
    </ul>
</div>