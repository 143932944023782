import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonUiModule } from '@voiply/shared/common-ui';

import { CONTAINERS } from './container';
import { COMPONENTS } from './components';

@NgModule({
  imports: [
    CommonModule,
    CommonUiModule
  ],
  declarations: [
    ...CONTAINERS,
    ...COMPONENTS
  ],
  exports:[
    ...CONTAINERS,
    ...COMPONENTS
  ]
})
export class SpeedTestModule {}
