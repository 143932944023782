<pagination-template #p="paginationApi" (pageChange)="pageChange.emit($event)" [id]="id" [maxSize]="200">
  <div class=" d-flex flex-row justify-content-center align-items-center mt-3 border-x-2"
    style="background-color: #dadadb;">
    <button [disabled]="p.isFirstPage()" (click)="p.previous()" class="btn align-self-center cursor-pointer"><i
        class="fas fa-caret-left text-secondary d-flex align-items-center" style="font-size: 24px;"></i></button>
    <h6 class="text-secondary m-0 py-2 d-flex flex-row align-items-center ">
      PAGE {{p.getCurrent()}}
    </h6>
    <button [disabled]="p.isLastPage()" (click)="p.next()" class="btn align-self-center cursor-pointer"> <i
        class="fas fa-caret-right  text-secondary d-flex align-items-center" style="font-size: 24px;"></i></button>
  </div>

</pagination-template>