import { Input, EventEmitter, Output, Directive } from '@angular/core';
import { ApplicationFeature, Survey, SystemType } from '@voiply/shared/model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from "@ngxs/store";
import { AppStateSelectors, BANNERS, OnDestroyCleanup } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Directive()
export class IntroBase extends OnDestroyCleanup {
    systemFeatures;
    banners = BANNERS;
    selectedBanner = { name: '', bannerLink: '' };
    @Select(AppStateSelectors.companyFeatures) systemFeatures$: Observable<ApplicationFeature[]>;
    @Select(AppStateSelectors.surveyBanner) surveyBanner$: Observable<string>;
    constructor() {
        super();
        this.subscriptions$.add(this.systemFeatures$.subscribe((systemFeatures) => this.systemFeatures = systemFeatures));
        this.subscriptions$.add(this.surveyBanner$.subscribe((surveyBanner) => {
            _.each(this.banners, (value) => {
                if (value.name === surveyBanner) {
                    this.selectedBanner = value
                }
            })
        }));
    }


}
