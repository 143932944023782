import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PrimaryNumberService } from '../../../services';
import { ExistingNumberBase } from '../existing-number.base';

@Component({
  selector: 'voiply-existing-number-home',
  templateUrl: './existing-number-home.component.html',
  styleUrls: ['./existing-number-home.component.css']
})
export class ExistingNumberHomeComponent extends ExistingNumberBase implements OnInit {

  constructor(public primaryNumberService: PrimaryNumberService) {
    super(primaryNumberService);
  }

  ngOnInit() {

  }

  checkNumber() {
    this.updateNumber.emit(this._selectedNumber);
    if(this._selectedNumber.length >= 10) {
      this.checkNumberPortability();
      if(this.currentNumberIsPortable === true && this.currentNumberToPort) {
        this.onClick();
      }
    }
  }
}
