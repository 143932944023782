<div class="card border-0" style="border-radius: 0;">
    <div class="p-3">
        <div *ngIf="(userIsVoiplyMember$ |async)">
            <voiply-app-new-after-checkout [apps$]="apps$" [cartItems]="cartItems$ | async" [selectedCountry]="selectedCountry" (addToCart)="onAddAppToCart($event)"></voiply-app-new-after-checkout>
        </div>
        <voiply-product-configuration-home-after-checkout [productConfigurations$]="cartItems$" [extDisabled]=true [userIsVoiplyMember]="userIsVoiplyMember$ | async" (removeCartItem)="onRemoveCartItem($event)" (updateCartItemConfiguration)="onUpdateCartItemConfiguration($event)"
            [phones]="(phones$ | async)" [apps]="(apps$ | async)">
        </voiply-product-configuration-home-after-checkout>
    </div>
</div>
