// ANGULAR and Other Node Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Voiply Modules
import { CommonUiModule } from '@voiply/shared/common-ui';
import { SharedNumberSearchModule } from '@voiply/shared/number-search';
// Current Module elements
import { COMPONENTS } from './components';
import { CONTAINERS } from './container';
// import { SERVICES } from './services';

// Bootstrap/Material
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    CommonUiModule,
    SharedNumberSearchModule,

    ModalModule.forRoot(),
    TabsModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  exports: [
    ...CONTAINERS
  ],
  declarations: [
    ...COMPONENTS,
    ...CONTAINERS,
  ]
  // providers: [
  //   ...SERVICES
  // ]
})
export class PrimaryNumberModule { }
