import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
// import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal/public_api';

@Component({
  selector: 'common-ui-features-model',
  templateUrl: './features-model.component.html',
  styleUrls: ['./features-model.component.scss']
})
export class FeaturesModelComponent {
  @ViewChild('voiplyFeaturesModal') voiplyFeaturesModal;

  @Input() features: { title: string, showAlways: boolean, icon: string }[];

  openFeatures() {
    this.voiplyFeaturesModal.show();
  }
}