import { Component, OnInit } from '@angular/core';
import * as LogRocket from 'logrocket';
import  packageInfo  from '../../../../package.json';
@Component({
  selector: 'voiply-home-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  childComponentLoaded = false;
  public version: string = packageInfo.version;

  constructor() {
    LogRocket.init('uyjcld/online-checkout', ({
      console: {
        shouldAggregateConsoleErrors: true
      }
    }) as any);
    console.log('VERSION:', packageInfo.version);
  }
}
