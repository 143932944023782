<div class="card border-0">
    <div class="card-header bg-white text-black border-0 pr-4 pl-0">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
            <div class="flex-fill">
                <span>
                  <span class="font-weight-bold h2">Manage Your Phones </span>
                <span class="font-italic">({{_productConfigurationLength}} Total Products)</span>
                </span>
            </div>
            <div class="d-flex align-items-center bg-light-grey input-group rounded px-2" style="max-width: 237px;">
                <input type="search" placeholder="Search" class="bg-light-grey border-0 form-control" autocomplete="off" [(ngModel)]="searchTerm" style="box-shadow: none !important;">
                <div class="px-1"><i class="far fa-search"></i></div>
            </div>
        </div>
    </div>
    <div class="card-body pt-0 pb-3">
        <!-- Phones Configuration -->
        <ng-container *ngIf="(phonesconfiguration | keyvalue | SearchPipe:searchTerm:searchableList) as results">
            <ng-container *ngIf="_phonesconfigurationLength>0 && (searchTerm.length>0 && results.length==0)">
                <p class="mt-3 mb-0"> No Phones Found with Term {{searchTerm}}</p>
            </ng-container>
            <ng-container *ngIf="results.length>0">
                <div class="d-flex flex-row pt-3 align-items-center" id="managePhones">
                    <span class="text-secondary font-weight-bold">Manage Phones</span>
                    <div class="flex-fill pl-3">
                        <hr style="border: 1px dashed rgba(0, 0, 0, 0.125)" class="m-0">
                    </div>
                    <div>
                        <a class=" d-flex pl-3  " (click)="isCollapsedPhones = !isCollapsedPhones" [attr.aria-expanded]="!isCollapsedPhones" aria-controls="collapsePhone">
                            <div class="" [collapse]="isCollapsedPhones">
                                <i class="fas fa-plus "></i>
                            </div>
                            <div class="" [collapse]="!isCollapsedPhones">
                                <i class="fas fa-minus "></i>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="collapse multi-collapse background-color" [collapse]="!isCollapsedPhones" id="collapsePhone">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-xl-3 pt-3" *ngFor="let cartItem of (results | paginate: { itemsPerPage: 8, currentPage: phonePageNumber, id:'phonesPagination' }) ; let i = index;">
                            <div class="card border-0">
                                <div style=" position: relative;">
                                    <!-- cartItem Image -->
                                    <div class="d-flex align-items-center justify-content-center bg-light-grey pt-3" style="height:180px;">
                                        <img [src]="getItemImage(cartItem.value.itemId, cartItem.value.type)" alt="..." height="130px" class="px-2">
                                    </div>
                                    <!-- online-offline status aftercheckout -->
                                    <voiply-product-configuration-phone-status-new [phoneStatus]="cartItem.value.phoneStatusDetail" style="position:absolute; top:8px; left:12px;" *ngIf="cartItem.value.phoneStatusDetail">
                                    </voiply-product-configuration-phone-status-new>

                                    <!-- Delete Cart Item -->
                                    <div style="position: absolute; right: 12px; top: 8px;">
                                        <i class=" fal fa-times text-muted cursor-pointer" (click)="onRemoveCartItem(cartItem.key, cartItem.value)"></i>
                                    </div>
                                </div>
                                <!-- Heading and Edit button -->
                                <div class="d-flex flex-row bg-dark">
                                    <p class="text-primary bg-dark text-center py-2 mb-0 font-weight-bold flex-fill">
                                        {{cartItem.value.heading}}
                                    </p>
                                    <button class="btn rounded-0 text-primary px-2" (click)="openHomeModal(cartItem.key)">
                                      <div class="rounded-circle d-flex align-items-center">
                                        <i class="fas fa-pencil text-primary"></i>
                                      </div>
                                    </button>
                                </div>
                            </div>

                            <div class="d-flex flex-column">
                                <div class="pt-2">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text border-0 bg-light-grey d-flex justify-content-center" style="width: 45px;">
                                                <svg style="height: 1rem;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.416 17.222">
                                                <g id="user_4_" data-name="user (4)" transform="translate(0)">
                                                  <g id="Group_407" data-name="Group 407" transform="translate(3.238)">
                                                    <g id="Group_406" data-name="Group 406" transform="translate(0)">
                                                      <path id="Path_1454" data-name="Path 1454" d="M115.263,0a4.472,4.472,0,1,0,4.472,4.472A4.483,4.483,0,0,0,115.263,0Z" transform="translate(-110.791)" fill="#f63b0b"/>
                                                    </g>
                                                  </g>
                                                  <g id="Group_409" data-name="Group 409" transform="translate(0 9.263)">
                                                    <g id="Group_408" data-name="Group 408" transform="translate(0)">
                                                      <path id="Path_1455" data-name="Path 1455" d="M39.548,251.094a4.169,4.169,0,0,0-.449-.82,5.558,5.558,0,0,0-3.847-2.421.823.823,0,0,0-.566.137,4.766,4.766,0,0,1-5.624,0,.733.733,0,0,0-.566-.137,5.518,5.518,0,0,0-3.847,2.421,4.807,4.807,0,0,0-.449.82.415.415,0,0,0,.02.371,7.782,7.782,0,0,0,.527.781,7.421,7.421,0,0,0,.9,1.015,11.707,11.707,0,0,0,.9.781,8.9,8.9,0,0,0,10.622,0,8.583,8.583,0,0,0,.9-.781,9.016,9.016,0,0,0,.9-1.015,6.842,6.842,0,0,0,.527-.781A.333.333,0,0,0,39.548,251.094Z" transform="translate(-24.165 -247.841)" fill="#f63b0b"/>
                                                    </g>
                                                  </g>
                                                </g>
                                              </svg>
                                            </div>
                                        </div>
                                        <input type="text" class=" form-control border-0 bg-light-grey" [ngModel]="cartItem.value.configuration.firstName + ' ' + cartItem.value.configuration.lastName" placeholder="User Name" readonly style="box-shadow: none !important;">
                                    </div>
                                </div>

                                <div class="pt-2">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text border-0 bg-light-grey d-flex justify-content-center" style="width: 45px;">
                                                <svg style="height: 1rem;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.558 12.419">
                                                <path id="envelope" d="M15.006,59.882H1.552A1.554,1.554,0,0,0,0,61.434v9.314A1.554,1.554,0,0,0,1.552,72.3H15.006a1.554,1.554,0,0,0,1.552-1.552V61.434A1.554,1.554,0,0,0,15.006,59.882ZM1.354,60.957C1.415,60.932,15.143,60.931,1.354,60.957ZM15.006,71.266H1.552a.518.518,0,0,1-.517-.517v-8.7L7.94,68.035a.518.518,0,0,0,.678,0l6.905-5.985v8.7A.518.518,0,0,1,15.006,71.266Z" transform="translate(0 -59.882)" fill="#f63b0b"/>
                                              </svg>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control border-0 bg-light-grey" [ngModel]="cartItem.value.configuration.email" placeholder="E-mail" readonly style="box-shadow: none !important;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="_phonesconfigurationLength<9" class="pt-3">
                        <voiply-pagination (pageChange)="phonePageChange($event);scrollTo('managePhones');" id="phonesPagination">
                        </voiply-pagination>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <!-- Apps Configuration -->
        <ng-container *ngIf="(appsconfiguration | keyvalue | SearchPipe:searchTerm:searchableList) as results">
            <ng-container *ngIf="_appsconfigurationLength>0 && (searchTerm.length>0 && results.length==0)">
                <p class="mt-3 mb-0"> No Apps Found with Term {{searchTerm}}</p>
            </ng-container>
            <ng-container *ngIf="results.length>0">
                <div class="d-flex flex-row align-items-center pt-3">
                    <span class="text-secondary font-weight-bold">Manage Apps</span>
                    <div class="flex-fill pl-3">
                        <hr style="border: 1px dashed rgba(0, 0, 0, 0.125)" class="m-0">
                    </div>
                    <div>
                        <a class=" d-flex pl-3" (click)="isCollapsedApps = !isCollapsedApps" [attr.aria-expanded]="!isCollapsedApps" aria-controls="collapseApps">
                            <div class="" [collapse]="isCollapsedApps">
                                <i class="fas fa-plus "></i>
                            </div>
                            <div class="" [collapse]="!isCollapsedApps">
                                <i class="fas fa-minus "></i>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="collapse multi-collapse background-color" [collapse]="!isCollapsedApps" id="collapseApps">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-xl-3 pt-3" *ngFor="let cartItem of (results | paginate: { itemsPerPage: 4, currentPage: appPageNumber,id:'appsPagination' }) ; let i = index; ">
                            <div class="card border-0">
                                <div style="position: relative;">
                                    <!-- cartItem Image -->
                                    <div class="d-flex align-items-center justify-content-center bg-light-grey" style="height: 180px;">
                                        <img [src]="getItemImage(cartItem.value.itemId, cartItem.value.type)" alt="..." style="height:130px;width: 130px;" class="">
                                    </div>
                                    <!-- Validation Exclamation Mark -->
                                    <div style="position: absolute; left: 12px; top: 8px;">
                                        <i *ngIf="submitted && validateCartConfiguration(cartItem.value)" class="fas fa-exclamation-circle" tooltip="Please enter details" style="color:#e2574c" [ngClass]="{ 'is-invalid':true}"></i>
                                    </div>

                                    <!-- Delete Cart Item -->
                                    <div style="position: absolute; right: 12px; top: 8px;">
                                        <i class=" fal fa-times text-muted cursor-pointer" (click)="onRemoveCartItem(cartItem.key, cartItem.value)"></i>
                                    </div>
                                    <!-- Arrow for edit help if cart not valid -->
                                    <div *ngIf="submitted && validateCartConfiguration(cartItem.value)">
                                        <i class="fal fa-long-arrow-down" style="position: absolute; right: 21px; bottom: 10px; font-size: 26px; color:#e2574c"></i>
                                    </div>
                                </div>
                                <!-- Heading and Edit button -->
                                <div class="d-flex flex-row bg-dark">
                                    <p class="text-primary bg-dark text-center py-2 mb-0 font-weight-bold flex-fill" [ngClass]="{ 'error':(submitted && validateCartConfiguration(cartItem.value))}">
                                        {{cartItem.value.heading}}
                                    </p>
                                    <button *ngIf="cartItem.value.itemId!==8 && cartItem.value.itemId!==6 && cartItem.value.itemId!==7" class="btn rounded-0 text-primary px-2" (click)="openHomeModal(cartItem.key)" [ngClass]="{ 'error':(submitted && validateCartConfiguration(cartItem.value))}">
                                    <div class="rounded-circle d-flex align-items-center">
                                      <i class="fas fa-pencil text-primary"></i>
                                    </div>
                                  </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="_appsconfigurationLength<5 " class="pt-3">
                        <voiply-pagination (pageChange)="appPageChange($event)" id="appsPagination">
                        </voiply-pagination>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>