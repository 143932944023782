import { Component, Input, ChangeDetectorRef, OnInit, AfterViewInit, HostListener, AfterViewChecked } from '@angular/core';
import { NavigationBarBase } from '../navigation-bar.base';
import { AnnualBillingModalComponent, AppStateSelectors } from '@voiply/shared/common-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal/';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';
import { CheckoutDetail } from '@voiply/shared/model';

@Component({
  selector: 'voiply-navigation-bar-after-checkout',
  templateUrl: './navigation-bar-after-checkout.component.html',
  styleUrls: ['./../navigation-bar.base.css']

})

export class NavigationBarAfterCheckoutComponent extends NavigationBarBase implements AfterViewChecked {


  @Input() logo: string;
  @Input() supportNumber: string;
  bsModalRef: BsModalRef;
  checkoutDetail: CheckoutDetail

  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;

  orderId: string;
  titleHeight: number;
  mobileQuery: MediaQueryList;

  constructor(private modalService: BsModalService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    super();
    this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      this.checkoutDetail = checkoutDetail;
    }));
    this.mobileQuery = this.media.matchMedia('(max-width: 1441px)');
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.titleHeight = document.getElementById('top-header').clientHeight;
    this.mobileQuery = this.media.matchMedia('(max-width: 1441px)');
  }

  ngAfterViewChecked() {
    this.titleHeight = document.getElementById('top-header').clientHeight;
    this.changeDetectorRef.detectChanges()
  }
  annualBillingComponent() {
    const initialState = {
      orderId: this.orderId,
      checkoutDetail: this.checkoutDetail
    };
    this.bsModalRef = this.modalService.show(AnnualBillingModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }
}
