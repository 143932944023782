import { Input, EventEmitter, Output, Directive } from '@angular/core';
import { ApplicationFeature, Survey, SystemType } from '@voiply/shared/model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from "@ngxs/store";
import { AppStateSelectors, SubmitSurveyAction, SkipSurveyAction, CrispService, OnDestroyCleanup, BANNERS } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';
import * as LogRocket from 'logrocket';
import * as _ from 'lodash';

@Directive()
export class HeaderSurveyBase extends OnDestroyCleanup {
    private orderId = '';
    protected phoneId;
    banners = BANNERS;
    selectedBanner = { name: '', bannerLink: '' };
    @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
    @Select(AppStateSelectors.survey) survey$: Observable<Survey>;
    @Select(AppStateSelectors.companyFeatures) systemFeatures$: Observable<ApplicationFeature[]>;
    @Select(AppStateSelectors.surveyBanner) surveyBanner$: Observable<string>;
    @Dispatch() submitSurveyDispatcher = (survey, phoneId) => new SubmitSurveyAction(survey, phoneId);
    @Dispatch() skipSurveyDispatcher = () => new SkipSurveyAction();

    constructor(private crispService: CrispService, private systemType: SystemType) {
        super();
        this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
        this.subscriptions$.add(this.surveyBanner$.subscribe((surveyBanner) => {
            _.each(this.banners, (value) => {
                if (value.name === surveyBanner) {
                    this.selectedBanner = value
                }
            })
        }));
    }

    skipSurvey() {
        this.skipSurveyDispatcher();
    }

    submitSurvey(survey: Survey) {
        this.crispService.CrispUserEmail = survey.email;
        this.crispService.setSessionEvent(this.systemType === SystemType.Business ? "checkout:survey" : "checkout:survey:residential", survey);
        LogRocket.track(this.systemType === SystemType.Business ? "checkout:survey" : "checkout:survey:residential");
        LogRocket.identify(this.orderId, { email: survey.email });

        this.submitSurveyDispatcher(survey, this.phoneId);
    }
}
