import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'checkout-protection-plan-new',
  templateUrl: './protection-plan-new.component.html',
  styleUrls: ['./protection-plan-new.component.scss']
})
export class ProtectionPlanNewComponent implements OnInit {

  constructor() { }
  @Input() hasProtectionPlan = false;
  @Input() protectionPlanMonthlyCharge = 0.00
  @Output() protectionPlanChanged: EventEmitter<boolean> = new EventEmitter();

  ngOnInit() {
  }

  onProtectionPlanAdded() {
    this.protectionPlanChanged.emit(true);
  }
}
