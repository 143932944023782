import { Pipe, PipeTransform } from '@angular/core';
import { PHONES } from '../constants';
import * as _ from 'lodash';
@Pipe({ name: 'keyValueSorted', pure: false })
export class KeyValuePipe implements PipeTransform {
    transform(keyvalue: any, input: any): any {
        let newKeyValue: any;
        switch (input) {
            case 'extension':
                newKeyValue = _.sortBy(keyvalue, [function (o) { return o.value.configuration.extension; }]);
                return newKeyValue;
            case 'firstName':
                newKeyValue = _.sortBy(keyvalue, [function (o) { return o.value.configuration.firstName; }]);
                return newKeyValue;
            case PHONES.VOIPLYADAPTER:
                newKeyValue = _.sortBy(keyvalue, [function (o) { return o.value.heading !== PHONES.VOIPLYADAPTER; }]);
                return newKeyValue;
            case PHONES.WIFIDESKPHONE:
                newKeyValue = _.sortBy(keyvalue, [function (o) { return o.value.heading !== PHONES.WIFIDESKPHONE; }]);
                return newKeyValue;
            case PHONES.WIFICORDLESSPHONE:
                newKeyValue = _.sortBy(keyvalue, [function (o) { return o.value.heading !== PHONES.WIFICORDLESSPHONE; }]);
                return newKeyValue;
            case PHONES.APPONLY:
                newKeyValue = _.sortBy(keyvalue, [function (o) { return o.value.heading !== PHONES.APPONLY; }]);
                return newKeyValue;
            default:
                return keyvalue;
        }
    }

}
