import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CartItemType, Phone } from '@voiply/shared/model';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { OnDestroyCleanup } from '@voiply/shared/common-ui';
@Component({
  selector: 'voiply-phone-new',
  templateUrl: './phone-new.component.html',
  styleUrls: ['./phone-new.component.scss']
})
export class PhoneNewComponent extends OnDestroyCleanup implements OnInit {
  @Input()
  phones$: Observable<any>;
  @Input()
  selectedCountry = 'US';
  @Input()
  cartItems;
  @Output()
  addToCart = new EventEmitter<any>();
  @Output()
  removeCartItem = new EventEmitter<string>();
  @Output()
  showWarning = new EventEmitter();
  phoneQuantity = [];
  phonePageNumber = 1;
  activePhones: Phone[];

  constructor() {
    super()
  }

  ngOnInit() {
    this.subscriptions$.add(this.phones$.subscribe((phone) => this.activePhones = _.filter(phone, (phone1) => (phone1.isActive && (phone1.featureId !== 11)))));
  }

  addPhoneToCart(phone: Phone, quantity) {
    if(this.selectedCountry!=='US' && this.selectedCountry!=='CA'){
      this.showWarning.emit();
      return;
    }
    if (quantity === undefined) quantity = 1
    this.addToCart.emit({ phone, quantity });
  }
  onChange(number, index) {
    this.phoneQuantity[index] = number
  }

  phonePageChange(number) {
    this.phonePageNumber = number;
  }

  scrollTo(controlId) {
    const el = document.getElementById(controlId);
    el.scrollIntoView({ behavior: "smooth" });
  }

  phoneAvailableInCountry(phone) {
    if (phone.onlyAvailableInCountry.length) {
      if (_.includes(phone.onlyAvailableInCountry, this.selectedCountry)) {
        return true
      } else return false
    } else return true
  }

  inCart(phone) {
    let returnVar: boolean = false;
    _.each(this.cartItems, (value, keys) => {
      if(value.type === CartItemType.Phone) {
        if(value.heading == phone.heading) {
          returnVar = true;
        }
        return false;
      }
    });
    return returnVar;
  }

  onRemoveCartItem(phone) {
    _.each(this.cartItems, (value, keys) => {
      if(value.type === CartItemType.Phone) {
        if(value.heading == phone.heading) {
          this.removeCartItem.emit(keys);
        }
      }
    })
  }
}
