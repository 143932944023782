import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'voiply-faq-new-item',
  templateUrl: './faq-new.component.html',
  styleUrls: ['./faq-new.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('out', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      transition('in => out', animate('400ms')),
      transition('out => in', animate('400ms'))
    ]),
    trigger('childAnimation1', [
      state('in', style({
        transform: 'rotate(0deg)'
      })),
      state('out', style({
        transform: 'rotate(180deg)'
      })),
      transition('in => out', animate('200ms', style({ transform: 'rotate(180deg)' }))),
      transition('out => in', animate('200ms', style({ transform: 'rotate(0deg)' })))
    ])
  ]
})
export class FaqNewComponent implements OnInit {
  @Input() faq: { title: string, content: string } = null;

  faqShow = "in";
  constructor() { }

  ngOnInit() {
  }
  toggleFaqShow() {
    if (this.faqShow === 'in') {
      this.faqShow = "out"
    }
    else {
      this.faqShow = "in"
    }
  }

}
