import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'voiply-header-bar-checkout',
  templateUrl: './header-bar-checkout.component.html',
  styleUrls: ['./header-bar-checkout.component.scss']
})
export class HeaderBarCheckoutComponent implements OnInit {
  @Input()
  phone?;
  @Input()
  chips?;
  @Input()
  price?;
  @Input()
  country?;
  @Output()
  payNow = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}
