<!-- OLD  -->
<!-- <voiply-local-number-search (numberSelectedInfo)="checkData($event)" (numberSelected)="selectedNumber = $event;onNumberSelected();setPrimaryNumber()" [primaryPhoneInfo]="primaryPhoneInfo"></voiply-local-number-search> -->
<!-- End -->


<div class="d-flex flex-column flex-wrap">
    <div class="d-flex flex-row">
        <div class="d-flex flex-row p-2 justify-content-between align-items-center flex-wrap flex-sm-nowrap w-100" style="background-color: #F3F3F3; border-radius:5px !important;">
            <div class="flex-fill d-flex flex-row h3 mb-0 ">
                <div class="h6 h3-md">
                <input class="bg-transperant changeTextSize rounded-top form-control h-100 primary-number-input border-0" type="text" mask="(000) 000-0000" 
                placeHolder="(XXX) XXX-XXXX" #primaryNumber="ngModel" [(ngModel)]="_selectedPrimaryNumber"
                style="background-color:transparent;outline:none;border:none;box-shadow:none !important;width:97%;">
                
                
                </div>
                
                <div class="d-block d-lg-none align-self-center">
                <div class="ml-2"style="font-size:small;color:#BBBBBB;font-weight:bold;">FREE<sup><del>$39.99</del></sup></div>
            </div>
            </div>
            <div class="flex-fill d-flex justify-content-around flex-row align-self-center">
                <div class="d-none d-lg-block align-self-center">
                    <div class="p-sm-1"style="font-size:small;color:#BBBBBB;font-weight:bold;">FREE<sup><del>$39.99</del></sup></div>
                </div>
            </div>  
        </div>
        <!-- <div class=" d-flex align-items-center align-self-center" style="background-color: #C0F3BC;border-radius:5px;">
            <div class="text-center py-3 px-5 "style="font-size:medium;font-weight:normal; color:#068E0A;letter-spacing:1px;" >EDIT</div>
        </div> -->
        <button [ngClass]="{'btn btn-styles  text-primary py-2':true}"
  (click)="staticModal.show();" class="position-relative" style="background-color: #414141;">
  
  <p class="m-0">EDIT</p>
  
</button>
    </div>
    <div class="d-flex flex-column ro2">
      <div class="d-flex flex-row  ">
        <div class="d-flex flex-row p-2 col-6 ">
          <div class="" style="background-color: grey;border-radius:7px;min-width:5px;">

          </div>
          <div class="ml-2">
                If you want a different number, click on Edit Number and select one.
          </div>
        </div>
        
        <div class="pt-1 m-2" style="background-image: linear-gradient(to right, #616161, #f9f9f9);"></div>
        
        <div class="d-flex flex-row p-2 col-6">
          <div class="" style="background-color: grey;border-radius:7px;min-width:5px;">

          </div>
          <div class="ml-2">
                Your new number will be activated after placing the order.
          </div>
        </div>
      </div>


    </div>
</div>

<!-- New Modal -->
<div class="modal fade" bsModal #staticModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog  modal-lg p-0" role="document">
    <div class="modal-content p-2" style="background-color: #EEEEEE;">
      <div class="modal-body p-0" >
        <div class="card">
          <div class="card-body" style="background-color: white;">
            <div>
              <i class="d-flex flex-row-reverse btn fa fa-times p-0" aria-hidden="true" (click)="staticModal.hide()" ></i>
              <h3 class="mb-0">Pick a New {{toggle ? "Local": "Toll Free"}} Number</h3>
              <div class="flex-fill w-100">
                <div class="btn btn-group btn-sm d-flex flex-column flex-md-row m-0 p-1" role="group"
                    aria-label="Basic example">
                    <button id="1" type="button"
                        class="btn border d-flex flex-row justify-content-between align-items-center p-3"
                        [ngClass]="toggle? 'open_color' :'nopen_color'" (click)="onClick('1')">
                        
                        <div class="flex-grow-1">LOCAL</div>
                    </button>
                    <button id="2" type="button"
                        class="btn border d-flex flex-row justify-content-between align-items-center p-3"
                        [ngClass]="toggle? 'nopen_color' :'open_color'" (click)="onClick('2')">
                        <div class="flex-grow-1">TOLL-FREE</div>
                    </button>
                </div>
              </div>

              <div class="mt-4" *ngIf="toggle">
                <voiply-local-number-search-alt (numberSelectedInfo)="checkData($event)" (numberSelected)="selectedNumber = $event;onNumberSelected();setPrimaryNumber()" [primaryPhoneInfo]="primaryPhoneInfo"></voiply-local-number-search-alt>
              </div>

              <div class="mt-4" *ngIf="!toggle">
                <voiply-tollfree-number-search-alt  (numberSelectedInfo)="checkData($event)" (numberSelected)="selectedNumber = $event;onNumberSelected();setPrimaryNumber()"></voiply-tollfree-number-search-alt>
              </div>

            </div>

            <div class="d-flex flex-row-reverse">
              <button type="button" class="btn bg-primary text-primary mt-3"
                  (click)="onNumberSelected();staticModal.hide()">
                  <h5 class="mb-0 p-1" style="font-weight:normal; letter-spacing: 1px;"> CONFIRM </h5>
            </button>
            </div>
            
            <!-- <div class="modal-footer d-flex  align-items-center flex-wrap justify-content-end"
              *ngIf="!internationalTabSelected">
              <div class="d-flex justify-content-end flex-grow-1">
                <button type="button" class="btn bg-primary text-primary"
                  (click)="onNumberSelected();staticModal.hide()">
                  Confirm
                </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- OLD MODAL -->
<!-- <div class="modal fade" bsModal #staticModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title font-weight-bold m-0" id="exampleModalLongTitle">Select a New Number</h3>
      </div>
      <div class="modal-body">
        <div *ngIf="tollFreeNumberNotAllowed">
          <voiply-local-number-search (numberSelected)="selectedNumber = $event"></voiply-local-number-search>
        </div>
        <tabset [justified]="true" *ngIf="!tollFreeNumberNotAllowed">
          <tab heading="LOCAL NUMBERS" (selectTab)="internationalTabSelected=false">
            <div class="mt-4">
              <voiply-local-number-search (numberSelected)="selectedNumber = $event"></voiply-local-number-search>
            </div>
          </tab>
          <tab heading="TOLL FREE NUMBERS" (selectTab)="internationalTabSelected=false">
            <div class="mt-4">
              <voiply-tollfree-number-search (numberSelected)="selectedNumber = $event"></voiply-tollfree-number-search>
            </div>
          </tab>
          <tab heading="INTERNATIONAL NUMBERS" (selectTab)="internationalTabSelected=true" *ngIf="canAddCompanyNumbers">
            <div class="mt-4">
              <voiply-international-number-search (internationalNumberSelected)="setInternationalNumber($event)">
              </voiply-international-number-search>
            </div>
          </tab>
        </tabset>

      </div>
      <div class="modal-footer d-flex  align-items-center flex-wrap justify-content-end"
        *ngIf="!internationalTabSelected">
        <div class="d-flex justify-content-end flex-grow-1">
          <button type="button" class="btn btn-outline-dark px-4 py-2 mr-3" data-dismiss="modal"
            (click)="staticModal.hide()">Cancel</button>
          <button type="button" class="btn bg-primary text-primary px-4 py-2"
            (click)="onNumberSelected();staticModal.hide()">
            Add Number
          </button>
        </div>
      </div>
      <div class="modal-footer d-flex  align-items-center flex-wrap justify-content-between"
        *ngIf="internationalTabSelected">
        <div class="mb-3 mb-lg-0 d-flex flex-row align-items-center">
          <i class="fas fa-exclamation-square fa-lg mr-3"></i>
          <div class="text-color text-wrap" style="font-size: 14px;max-width: 400px;">Activation Fee and Minimum Monthly
            Requirement will be
            assessed and billed after purchase</div>
        </div>
        <div class="d-flex justify-content-end flex-grow-1">
          <button type="button" class="btn btn-outline-dark px-4 py-2 mr-3" data-dismiss="modal"
            (click)="staticModal.hide()">Cancel</button>
          <button type="button" class="btn bg-primary text-primary px-4 py-2"
            (click)="onInternationalNumberSelected();staticModal.hide()">
            Add Number
          </button>
        </div>
      </div>
    </div>
  </div>
</div> -->