import { Directive, OnInit, Input, Output, EventEmitter, Renderer2, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { FirstPromoter, NumberPorting, Users, Shippment, CartItem, CartItemType } from '@voiply/shared/model';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TroubleshootingComponent } from '@voiply/shared/common-ui';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Directive()
export class OrderTrackingBase implements OnInit, OnChanges {

  @Input() shipment: Shippment;
  @Input() numberPorting: NumberPorting = null;
  @Input() firstPromoter: FirstPromoter = null;
  @Input() companyName: string;
  @Input() email: string;
  @Input() orderId: string;
  @Input() hasHardware: boolean;
  @Input() showVoiplyOnlineApp: boolean;
  @Input() users: Users[];
  @Input() cartItems;
  @Input() qrCodeData;
  @Input() hasOnlyFax;
  showRefer = false;
  @Output() sendInvite = new EventEmitter<any>();
  @Output() sendRefer = new EventEmitter<any>();
  shareUrl: string;
  showme: boolean = false;
  user = new FormControl();
  showReferEmail = false;
  referEmail = new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.email()] });
  refersubmitted: boolean;
  bsModalRef: BsModalRef;
  isQrCodeLoaded: boolean = false;
  isExtralineMobileSelected: boolean = true;
  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document, private modalService: BsModalService, private domSanitize: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['qrCodeData']?.currentValue) {
      setTimeout(() => {
        this.changeQrCode();
        this.isQrCodeLoaded = true;
      }, 1);
    }
  }

  ngOnInit() {
    this.shareUrl = location.origin + "/?fp_ref=" + (this.firstPromoter || { my_ref: '' }).my_ref;
    this.shipment = this.shipment || {
      delivered: false,
      deliveyDate: null,
      estimatedDeliveryDate: null,
      shipperTrackingLink: '',
      status: 'In Processing',
      status_detail: '',
      trackingRef: ''
    };

    let s = this._renderer2.createElement('script');
    s.type = `text/javascript`;
    s.text = `(function () { var qs, js, q, s, d = document, gi = d.getElementById, ce = d.createElement, gt = d.getElementsByTagName, id = "typef_orm_share", b = "https://embed.typeform.com/"; if (!gi.call(d, id)) { js = ce.call(d, "script"); js.id = id; js.src = b + "embed.js"; q = gt.call(d, "script")[0]; q.parentNode.insertBefore(js, q) } })()`;

    this._renderer2.appendChild(this._document.body, s);

    this.selectedPhone = this.cartItems[_.findKey(this.cartItems, function (i) { return i.type == CartItemType.Phone; })];
  }
  sendEmailInvite() {
    this.sendInvite.emit(this.user.value);
  }

  sendReferEmail() {
    this.refersubmitted = true;
    if (!this.referEmail.errors)
      this.sendRefer.emit(this.referEmail.value);
  }

  openTroubleShooting() {
    const initialState = {
      orderId: this.orderId
    };
    this.bsModalRef = this.modalService.show(TroubleshootingComponent, { initialState, class: 'modal-lg', backdrop: 'static' });

  }

  selectedPhone: any = {};

  generateUrl() {
    return `csc:${this.orderId}:${this.selectedPhone?.configuration.userId}@VOIPLYONLINE`;
  }

  generateUrlForExtralineApp() {
    return `csc:${this.orderId}:${this.selectedPhone?.configuration.userId}@VOIPLY`;
  }

  sanitizeUrl(url: string) {
    return this.domSanitize.bypassSecurityTrustUrl(url);
  }

  dropDownItems() {
    let cartItems: CartItem[] = [];
    for (let item of Object.keys(this.cartItems))
      if (this.cartItems[item].type == CartItemType.Phone)
        cartItems.push(this.cartItems[item]);
    return cartItems;
  }
  changeQrCode() {
    if (this.qrCodeData) {
      const items = this.dropDownItems()
      items.forEach(item => {
        let el = document.getElementById(`ext_${item?.configuration.extension}`)
        if (el) el.style.display = 'none';
      });
      let el = document.getElementById(`ext_${this.selectedPhone?.configuration.extension}`)
      if (el) {
        el.style.display = '';
      }
    }
  }
  onPhoneSelect(cartItem: CartItem) {
    this.selectedPhone = cartItem;
    this.changeQrCode()
  }

  referButtonClick() {
    if (this.showReferEmail) {
      this.sendReferEmail()
      this.showReferEmail = false
      return;
    }

    this.showRefer = true;
    return;
  }
}
