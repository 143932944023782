// ANGULAR and Other Node modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CompanyFeaturesModule } from '@voiply/company-features';
import { FaqModule } from '@voiply/faq';
import { FooterModule } from '@voiply/footer';

// Voiply Modules
import { HeaderModule } from '@voiply/header';
import { CommonUiModule, ENVIRONMENT } from '@voiply/shared/common-ui';
import { ProductModule } from '@voiply/product';
import { PrimaryNumberModule } from '@voiply/primary-number';
import { TestimonialsModule } from '@voiply/testimonials';
import { SpeedTestModule } from '@voiply/speed-test';
import { CheckoutModule } from '@voiply/checkout';
import { Auth0Module } from '@voiply/auth0';
import { HeaderSurveyModule } from '@voiply/header-survey';
import { HeaderOrderTrackingModule } from '@voiply/header-order-tracking';
import { CookieConcentModule } from '@voiply/cookie-concent';
import { PrimaryNumberAfterCheckoutModule } from '@voiply/primary-number-after-checkout';
import { OrderSummaryModule } from '@voiply/order-summary';
import { HeaderIntroDisplayModule } from '@voiply/header-intro-display';


// Current Module elements
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { RootPageComponent } from './root-page/root-page.component';
import { AppRoutes } from './app.routing';
import { AfterCheckoutPageComponent } from './after-checkout-page/after-checkout-page.component';

@NgModule({
  declarations: [
    AppComponent,
    RootPageComponent,
    AfterCheckoutPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, { initialNavigation: 'enabled' }),

    // State
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),

    // Voiply Modules
    CommonUiModule,
    HeaderModule,
    PrimaryNumberModule,
    ProductModule,
    CheckoutModule,
    SpeedTestModule,
    TestimonialsModule,
    Auth0Module,
    CompanyFeaturesModule,
    FaqModule,
    FooterModule,
    HeaderSurveyModule,
    HeaderOrderTrackingModule,
    PrimaryNumberAfterCheckoutModule,
    CookieConcentModule,
    OrderSummaryModule,
    HeaderIntroDisplayModule
  ],
  providers: [
    { provide: ENVIRONMENT, useValue: environment }
  ],
  bootstrap: [AppComponent],
  exports: [
    BrowserModule
  ]
})
export class AppModule { }