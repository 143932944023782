<!-- <div class="row" *ngIf="apps">
    <div class="col-lg-4 col-sm-6 col-12 pb-4" *ngFor="let app of apps; let i = index;">
        <div class="card bg-light-grey rounded-lg p-3 h-100" [ngStyle]="{'border': inCart(app) ? '2px solid #F63B0B': '0'}">
            <div>
                <img *ngIf="!app.videoId" class="rounded-lg align-self-center app-image" [src]="app.headerImage" [ngStyle]="{'height': findHeight()+'px'}">
                <div class="rounded-lg" style="overflow: hidden;">
                    <div *ngIf="app.videoId && app.appId==1"  style="padding:56.25% 0 0 0;position:relative;">
                        <iframe src="https://player.vimeo.com/video/571642121?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;portrait=0&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Online Business Fax Solution.mp4"></iframe>
                    </div>

                    <div *ngIf="app.videoId && app.appId==5" style="padding:56.25% 0 0 0;position:relative;">
                        <iframe src="https://player.vimeo.com/video/571633196?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;portrait=0&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Emergency999_AllVideos.mp4"></iframe>
                    </div>
                    

                </div>
                <div></div>
            </div>
            <div class="card-body p-0 d-flex flex-column justify-content-end">
                <div class="d-flex flex-column justify-content-center text-center align-items-center mt-3">
                    <img class="img-fluid" style="height: 50px !important;" [src]="app.avatar">
                    <div class="font-weight-bolder my-3 cursor-pointer h5" (click)="onOpenModalDialog(i)">{{app.title.toUpperCase()}}</div>
                    <div style="min-height: 70px;">{{app.description}}</div>
                </div>
                <div class="d-flex flex-column align-items-center mt-3">
                    <div class="text-center">
                        <h3 [ngClass]="{'text-secondary': app.price == 0.00, 'text-center font-weight-bold m-0': true}">
                            {{app.discountedPrice}}</h3>
                    </div>
                    <div class="text-center text-muted h5" style="text-decoration: line-through; opacity: 0.9;">
                        ${{app.actualPrice}}/mo
                    </div>
                    <div class="align-self-stretch d-flex mx-3 mt-3">
                        <button *ngIf="!inCart(app)" class="text-black text-center py-2 mb-0 flex-fill bg-grey btn rounded" (click)="onAddToCart(app)">
                      <span class="font-weight-bolder text-uppercase">Add to Cart</span>
                    </button>
                        <button *ngIf="inCart(app)" style="position: relative;" class="text-primary text-center py-2 mb-0 flex-fill bg-primary btn rounded">
                        <i class="fas fa-times" style="position: absolute; top: 3px; right: 4px;" (click)="onRemoveCartItem(app)"></i>
                      <span class="font-weight-bolder text-uppercase">In cart</span>
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class=" d-flex flex-row justify-content-center align-items-center mt-3 border-x-2 cursor-pointer" *ngIf="apps?.length>4 &&showapps<5" style="background-color: #dadadb;" (click)="showMoreApps()">
    <h6 class="text-secondary m-0 py-2 d-flex flex-row align-items-center font-weight-bold">
        SHOW MORE APPS
    </h6>
</div>
<div class=" d-flex flex-row justify-content-center align-items-center mt-3 border-x-2 cursor-pointer" *ngIf="apps?.length>4&&apps?.length<showapps+1" style="background-color: #dadadb;" (click)="this.showapps=4">
    <h6 class="text-secondary m-0 py-2 d-flex flex-row align-items-center font-weight-bold">
        HIDE MORE APPS
    </h6>
</div> -->

<!-- <div class="modal fade" bsModal #moreAppsModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex flex-row align-items-center">
                    <h4 class="flex-grow-1" class="modal-title text-primary pl-3">Apps</h4>
                </div>
                <button type="button" class="close pull-right" aria-label="Close" (click)="moreAppsModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12" *ngFor="let app of apps; let i = index;">
                        <div class="card ">
                            <div style="position: absolute; right:8px; top:8px;">
                                <button type="button" class="btn rounded-0 shadow-1 bg-white text-secondary" (click)="onAddToCart(app)"><i class="fas fa-plus"></i></button>
                            </div>
                            <div class="d-flex flex-column justify-content-between align-items-center px-4 pt-2 pb-4 bg-primary-gradient">
                                <img [src]="app.image" style="height: 70px;" class="mb-2" class="app-image-color">
                                <h6 class="text-primary m-0 font-weight-bold">{{app.title.toUpperCase()}}</h6>
                            </div>
                        </div>

                        <div class="bg-black py-1 border"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="row" *ngIf="apps">
    <div class="col-lg-4 col-sm-6 col-12 pb-4" *ngFor="let app of apps; let i = index;">
        <div class="card bg-light-grey rounded-lg p-3 h-100">
            <div class="bannerClass text-center">
                <h4 class="mt-2 mb-0 ml-4" style="color: white;transform: skew(0deg, 34deg) rotate(-34deg);"> {{selectedAppsInCart[app.appId].off}} </h4>
                <div class="triangle-down"></div>
                <div class="triangle-up"></div>
            </div>
            <div class="triangle-2-up">
                <div class="inside-triangle"></div>
            </div>
            <div class="card-body p-0 d-flex flex-column justify-content-between">
                <div class="d-flex flex-row-reverse">
                    <label class="switch">
                      <input type="checkbox" [checked]="selectedAppsInCart[app.appId].isSelected"
                        (click)="clickedToggleAppBtn(app)">
                      <span class="slider round"> </span>
                    </label>
                </div>
                <div class="d-flex flex-column justify-content-center text-center align-items-center mt-0">
                    <img class="img-fluid" style="height: 100px !important;" [src]="app.avatar">
                    <div class="font-weight-bolder my-1 h6" >{{app.title.toUpperCase()}}</div>
                    <div style="min-height: 70px;">{{app.description}}</div>
                </div>
                <div class="d-flex flex-column align-items-center mt-0">
                    <div class="text-center">
                        <h4 [ngClass]="{'text-black': app.price == 0.00, 'text-center font-weight-bold m-0': true}" class="mb-0">
                            {{app.discountedPrice}}</h4>
                    </div>
                    <div class="text-center text-muted h5" style="text-decoration: line-through; opacity: 0.9;">
                        ${{app.actualPrice}}/mo
                    </div>
                    <div class="d-flex flex-row-reverse align-self-end">
                        <span class="float-end cursor-pointer font-weight-bold" (click)="onOpenModalDialog(i)"> <u>LEARN MORE</u><span>>></span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>