import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'voiply-pagination-phone',
  templateUrl: './pagination-phone.component.html',
  styleUrls: ['./pagination-phone.component.scss']
})
export class PaginationPhoneComponent implements OnInit {

  @Input() id: string;
  @Input() maxSize: number;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() page: number;
  constructor() { }

  ngOnInit() {
  }

}
