<div style="background-color: #fff" [style.margin-top.px]="mobileQuery.matches?titleHeight:0">
    <div class="container">
        <div class="d-flex flex-row align-items-center justify-content-between">
            <div class="border-right">
                <img class="my-3 mr-3" src="/assets/images/{{logo}}" style="height:30px;">
            </div>
            <div class="flex-fill" *ngIf="!isPayAnually">
                <div class="d-none d-lg-block">
                    <div class="d-flex align-items-center ml-3">
                        <span class="text-success"><span class="font-weight-bold"> Save 20%</span> a Year by Switching to <span class="font-weight-bold"> ANNUAL BILLING </span></span>
                        <button class="btn btn-success text-success border-0 rounded text-uppercase font-weight-bold mx-3 px-4 " type="button" (click)="annualBillingComponent()">
                          Switch Now
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <h6 class="py-3 m-0 font-weight-bold">
                    NEED HELP? <span class="text-secondary"> CALL {{supportNumber}}</span>
                </h6>

            </div>
        </div>
    </div>
</div>
