import { ViewChild, Input, Output, EventEmitter, Directive } from '@angular/core';
import { FeaturesModelComponent, AppStateSelectors, OnDestroyCleanup } from '@voiply/shared/common-ui';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApplicationFeature } from '@voiply/shared/model';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

/// Common class for both business and home Navigation bar.
@Directive()
export class NavigationBarBase extends OnDestroyCleanup {

  @Select(AppStateSelectors.isPayAnually) isPayAnually$: Observable<boolean>;
  @ViewChild(FeaturesModelComponent) featuremodel;
  isPayAnually: boolean;


  constructor() {
    super()
    this.subscriptions$.add(this.isPayAnually$.subscribe((isPayAnually) => {
      this.isPayAnually = isPayAnually;
    }));
  }

  @Input()
  systemFeatures;

  scrollTo(controlId) {
    const el = document.getElementById(controlId);
    el.scrollIntoView({ behavior: "smooth" });
  }

  clickTo(controlId) {
    const el = document.getElementById(controlId);
    if(el) el.click();
  }

  onShowAllFeatures() {
    this.featuremodel.openFeatures();
  }

}
