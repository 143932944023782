import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'voiply-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.scss']
})
export class FooterNewComponent implements OnInit {
  @Input() buttonText: string;
  @Input() logo: string;
  currentYear = new Date().getUTCFullYear();
  shareUrl: string;
  @Output()
  navigatePage = new EventEmitter();

  ngOnInit() {
    this.shareUrl = location.origin;
  }
}
