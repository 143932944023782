<div class="row" id="phones">
    <ng-container *ngIf="activePhones">
        <div *ngFor="let phone of activePhones | paginate: { itemsPerPage: 8, currentPage: phonePageNumber, id:'phonesPagination1'}; let i=index" class="col-lg-3 col-sm-6 pb-4 pb-lg-0">
            <!-- [ngClass]="{'col-md-6 col-sm-6 col-xs-6 col-lg-3': activePhones?.length >= 4, 'col-md-4 col-sm-6 col-xs-6': activePhones?.length == 3, 'col-md-2 col-sm-6 col-xs-6': activePhones?.length <= 2}" -->
            <div class="h-100 d-flex flex-column bg-light-grey rounded-lg py-3 px-2" [ngStyle]="{'border': inCart(phone) ? '2px solid #F63B0B': '0'}">
                <div class="d-flex flex-column p-2 flex-grow-1">
                    <div class="d-flex flex-column flex-grow-1">
                        <div class="mb-1" [ngStyle]="{'visibility': phone.featureText ? '' : 'hidden'}">
                            <div class="d-flex justify-content-between">
                                <div style="background-color: #F9DED7; font-size: 10px;" class="px-3 py-2 my-auto text-secondary font-weight-bolder rounded">
                                    {{phone.featureText.toUpperCase()}}
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="fas fa-plus text-secondary mr-1"></i>
                                    <img class="img-fluid" width="25px" src="assets/images/voiplyadapter-new.png">
                                </div>
                            </div>
                        </div>
                        <div class="flex-column d-flex align-items-center justify-content-center border-0 phone-image">
                            <!-- <voiply-wistia-player *ngIf="phone.featureVideo" [videoId]=phone.featureVideo style="left:0;top:0;width:100%;">
                            </voiply-wistia-player> -->
                            <img class="img-fluid" [src]="phone.image" style="height: 125px; object-fit: contain;">
                        </div>
                        <div class="d-flex">
                            <p class="h5 text-center py-2 mb-0 flex-fill text-truncate font-weight-bolder text-capitalize">
                                {{phone.heading}}
                            </p>
                            <a *ngIf="phone.pdfURL" class="p-2 d-flex" [href]="phone.pdfURL" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27.366" height="24.475" viewBox="0 0 27.366 24.475">
                            <g id="files" transform="translate(0 0)">
                              <g id="Group_359" data-name="Group 359" transform="translate(0 0)">
                                <g id="Group_358" data-name="Group 358" transform="translate(0 0)">
                                  <path id="Path_874" data-name="Path 874" d="M292.481,256H288.9a.9.9,0,0,0-.9.9v7.17a.9.9,0,0,0,.9.9h3.585a1.793,1.793,0,0,0,1.793-1.792v-5.377A1.793,1.793,0,0,0,292.481,256Zm0,7.17h-2.689v-5.377h2.689Z" transform="translate(-273.182 -244.209)" fill="#403e3e"/>
                                  <path id="Path_875" data-name="Path 875" d="M421.378,257.793V256H416.9a.9.9,0,0,0-.9.9v8.066h1.793v-3.585h3.585v-1.793h-3.585v-1.792Z" transform="translate(-394.012 -244.209)" fill="#403e3e"/>
                                  <path id="Path_876" data-name="Path 876" d="M1.53,22.18V2.294a.765.765,0,0,1,.765-.765H13.767V4.589a1.53,1.53,0,0,0,1.53,1.53h3.059V8.413h1.53V5.354a.765.765,0,0,0-.222-.543L15.075.222A.765.765,0,0,0,14.532,0H2.294A2.3,2.3,0,0,0,0,2.294V22.18a2.294,2.294,0,0,0,2.294,2.294H7.648v-1.53H2.294A.765.765,0,0,1,1.53,22.18Z" transform="translate(0 0)" fill="#403e3e"/>
                                  <path id="Path_877" data-name="Path 877" d="M164.481,256H160.9a.9.9,0,0,0-.9.9v8.066h1.792v-2.689h2.689a1.793,1.793,0,0,0,1.792-1.793v-2.689A1.793,1.793,0,0,0,164.481,256Zm0,4.481h-2.689v-2.689h2.689Z" transform="translate(-152.352 -244.209)" fill="#403e3e"/>
                                </g>
                              </g>
                            </g>
                              </svg>
                            </a>
                        </div>
                        <div>
                            <ul class="list-group list-group-flush">
                                <li *ngFor="let feature of phone.features" class="border-0 d-flex px-1 mt-1 justify-content-center text-capitalize" style="font-size: 14px;">
                                    <p class="m-0 text-center">
                                        {{feature.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center py-2">
                        <h3 [ngClass]="{'text-secondary': phone.priceValue == 0.00, 'text-center font-weight-bold m-0': true}">
                            {{phone.price}}
                        </h3>
                        <span style="opacity: 0.4;"> <del>{{phone.withoutDiscountPriceValue | currency}}</del> </span>
                    </div>
                </div>
                <div class="d-flex flex-column mx-3">
                    <div class="d-flex flex-column align-items-stretch">
                        <div class="align-self-stretch d-flex" *ngIf="phoneAvailableInCountry(phone)&&!phone.outOfStock">
                            <button id="{{phone.heading}}" *ngIf="!inCart(phone)" class="text-black text-center py-2 mb-0 flex-fill bg-grey btn rounded" (click)="addPhoneToCart(phone, phoneQuantity[i])">
                              <span class="font-weight-bolder text-uppercase">Add to Cart</span>
                            </button>
                            <button *ngIf="inCart(phone)" style="position: relative;" class="text-primary text-center py-2 mb-0 flex-fill bg-primary btn rounded">
                                <i class="fas fa-times" style="position: absolute; top: 3px; right: 4px;" (click)="onRemoveCartItem(phone)"></i>
                              <span class="font-weight-bolder text-uppercase">In cart</span>
                            </button>
                        </div>
                        <div class="text-center border rounded p-2 text-truncate text-muted text-uppercase font-weight-bolder bg-white" *ngIf="!phoneAvailableInCountry(phone)" style="opacity: 0.5;">
                            Unavailable
                        </div>
                        <div class="text-center border rounded p-2 text-truncate text-muted text-uppercase font-weight-bolder bg-white" *ngIf="phoneAvailableInCountry(phone)&&phone.outOfStock" style="opacity: 0.9; border-width: 2px !important;"> Out of Stock </div>
                        <!-- <div class="btn-group bg-grey rounded-lg mt-2" dropdown>
                            <button class="btn text-center px-2 my-1 font-weight-bold flex-fill dropdown-toggle d-flex justify-content-between align-items-center text-uppercase" style="font-size: 12px !important;" type="button" dropdownToggle type="button" aria-controls="dropdown-basic"
                                id="dropdown-context-lable">
                              {{phoneQuantity[i] || 1 }} Handset Included
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu w-100 text-center" role="menu" aria-labelledby="button-basic">
                                <ng-container *ngFor="let number of [1,2,3,4,5,6,7,8,9,10]">
                                    <li role="menuitem" *ngIf="phone.isActive">
                                        <button class="dropdown-item px-3" style="outline: none;" (click)="onChange(number,i)">
                                        {{number}}
                                      </button>
                                    </li>
                                </ng-container>
                            </ul>
                        </div> -->
                        <!-- </button> -->
                    </div>
                </div>
            </div>

        </div>
    </ng-container>
</div>
<div *ngIf="activePhones?.length>8">
    <voiply-pagination-phone (pageChange)="phonePageChange($event);scrollTo('Phones');" id="phonesPagination1">
    </voiply-pagination-phone>
</div>
