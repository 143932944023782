<mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
        [style.marginTop.px]="mobileQuery.matches ? titleHeight : 0" [fixedInViewport]="mobileQuery.matches"
        position="end">
        <mat-nav-list>
            <ng-container *ngIf="!mobileQuery.matches else navBar"></ng-container>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="container-xl bg-white shadow">
            <voiply-header-home-after-checkout></voiply-header-home-after-checkout>
            <div class="container bg-light-grey pt-3">
                <div class="d-flex flex-row">
                    <div class="mr-3 d-none show-navigation" style="min-width:25%" *ngIf="!mobileQuery.matches">
                        <ng-container *ngTemplateOutlet="navBar">
                        </ng-container>
                        <div class="mt-3">
                            <img class="img-fluid px-2" src="assets/images/aftercheckout-dashboard/ad-2.png" />
                        </div>
                        <div class="position-fixed" [style.bottom.px]="scrollToTopbottomHeight"
                            [ngClass]="showScrollToTop ? 'd-block' : 'd-none' "> <i
                                class="fas fa-arrow-circle-up fa-3x text-secondary cursor-pointer"
                                (click)="scrollTo('top-header')"></i>
                        </div>
                    </div>
                    <div>
                        <voiply-header-order-tracking-home></voiply-header-order-tracking-home>
                        <voiply-primary-number-home-after-checkout></voiply-primary-number-home-after-checkout>
                        <voiply-product-home-after-checkout></voiply-product-home-after-checkout>
                        <voiply-checkout-home-after-checkout></voiply-checkout-home-after-checkout>
                        <voiply-order-summary></voiply-order-summary>
                        <voiply-all-faqs-new></voiply-all-faqs-new>
                        <voiply-footer-top-home-after-checkout></voiply-footer-top-home-after-checkout>
                    </div>
                </div>
            </div>
            <voiply-footer-home-after-checkout></voiply-footer-home-after-checkout>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #navBar>

    <div class="d-flex flex-column w-100">
        <div class="d-none show-navigation">
            <div
                class="d-flex flex-row bg-primary-gradient text-primary p-3 justify-content-between align-items-center">
                <div class="font-weight-bold"><i class="fas fa-align-justify pr-3 cursor-pointer"
                        (click)="closeNav()"></i>Voiply Navigation
                </div>
                <div><i class="fas fa-question-circle"></i></div>
            </div>
        </div>
        <div>
            <div class=" d-flex flex-row bg-nav-header px-3 py-2 justify-content-between align-items-center">
                <div class="font-weight-bold d-flex align-items-center"><img
                        src="assets/images/aftercheckout-dashboard/dashboard.png" style="width:16px; "
                        class="mr-3" />DASHBOARD
                </div>
                <!-- <img src="assets/images/triangle.png" style="transform:rotate(180deg)" width="11px" /> -->
            </div>
            <div class="bg-white">
                <div class="ml-5" style="border-left:1px dashed #dee2e6">
                    <a *ngIf="!isFaxOnly"
                        [attr.href]="this.shipment.shipperTrackingLink != '' ? this.shipment.shipperTrackingLink : null"
                        target="_blank">
                        <button class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                            <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                                <img src="assets/images/aftercheckout-dashboard/order-status.png" style="width:16px; "
                                    class="mr-2" />
                            </div>
                            Order Status
                        </button>
                    </a>
                    <a href="https://voiply.typeform.com/to/R6MTB6?email={{email}}&company={{companyName}}&order_id={{orderId}}"
                        target="_blank">
                        <button class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                            <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                                <img src="assets/images/aftercheckout-dashboard/porting-status.png" style="width:22px; "
                                    class="mr-2" />
                            </div>
                            Porting Status
                        </button>
                    </a>
                    <button class="d-flex flex-row align-items-center text-muted w-100 btn p-3">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <img src="assets/images/aftercheckout-dashboard/money.png" style="width:16px; "
                                class="mr-2" />
                        </div>
                        Get a Free Month
                    </button>
                </div>
            </div>
        </div>

        <div>
            <div class="d-flex flex-row bg-nav-header px-3 py-2 justify-content-between align-items-center">
                <div class="font-weight-bold d-flex align-items-center"><img
                        src="assets/images/aftercheckout-dashboard/devices-apps.png" style="width:14px; "
                        class="mr-3" />PHONES & APPS
                </div>
                <!-- <img src="assets/images/triangle.png" style="transform:rotate(180deg)" width="11px" /> -->
            </div>
            <div class="bg-white">
                <div class="ml-5" style="border-left:1px dashed #dee2e6">
                    <a *ngIf="!isFaxOnly" href="https://build.voiply.house" target="_blank"
                        class="d-flex flex-row border-bottom  align-items-center text-muted w-100 btn p-3"
                        style="display: none !important;">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <i class="fas fa-user-plus mr-2" style="font-size: 12px;"></i>
                        </div>
                        Order More Phones
                    </a>
                    <button *ngIf="!isFaxOnly" (click)="scrollTo('managePhones')"
                        class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <img src="assets/images/aftercheckout-dashboard/manage-devices.png" style="width:16px; "
                                class="mr-2" />
                        </div>
                        Manage Phones
                    </button>
                    <button (click)="scrollTo('manageApps')"
                        class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <img src="assets/images/aftercheckout-dashboard/manage-apps.png" style="width:16px; "
                                class="mr-2" />
                        </div>
                        Manage Apps
                    </button>
                    <button (click)="openEmergencyApp()" *ngIf="showEmergency999"
                        class="d-flex flex-row align-items-center text-muted w-100 btn p-3">
                        <div class="d-flex align-items-center justify-content-center"
                            style="width:25px;filter: invert(60%) sepia(0%) saturate(1%) hue-rotate(202deg) brightness(88%) contrast(85%);">
                            <img src="assets/images/alert911.svg" style="width:18px; " class="mr-2" />
                        </div>
                        Emergency999
                    </button>
                </div>
            </div>
        </div>
        <div>
            <div class="d-flex flex-row bg-nav-header px-3 py-2 justify-content-between align-items-center">
                <div class="font-weight-bold d-flex align-items-center"><img
                        src="assets/images/aftercheckout-dashboard/bill.png" style="width:14px; " class="mr-3" />BILLING
                    OPTIONS
                </div>
                <!-- <img src="assets/images/triangle.png" style="transform:rotate(180deg)" width="11px" /> -->
            </div>
            <div class="bg-white">
                <div class="ml-5" style="border-left:1px dashed #dee2e6">
                    <button (click)="scrollTo('orderSummary')"
                        class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <img src="assets/images/aftercheckout-dashboard/sheet.png" style="width:12px; "
                                class="mr-2" />
                        </div>
                        Summary
                    </button>
                    <button (click)="scrollTo('orderSummary')"
                        class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <img src="assets/images/aftercheckout-dashboard/dollar.png" style="width:16px; "
                                class="mr-2" />
                        </div>
                        View Invoices
                    </button>
                    <button (click)="scrollTo('updateCard')"
                        class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <img src="assets/images/aftercheckout-dashboard/card.png" style="width:16px; "
                                class="mr-2" />
                        </div>
                        Update Credit Card
                    </button>
                    <button (click)="annualBillingComponent()" *ngIf="!isPayAnually"
                        class="d-flex flex-row text-secondary p-3 font-weight-bold btn w-100">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <img src="assets/images/aftercheckout-dashboard/up-chevron.png" style="width:16px; "
                                class="mr-2" />
                        </div>
                        UPGRADE TO ANNUAL
                    </button>
                </div>
            </div>
        </div>
        <div class=" mt-2">
            <div
                class="d-flex flex-row bg-primary-gradient text-primary px-3 py-2 justify-content-between align-items-center">
                <div class="font-weight-bold d-flex align-items-center"><img
                        src="assets/images/aftercheckout-dashboard/help-center.png" style="width:20px; "
                        class="mr-3" />HELP CENTER
                </div>
                <!-- <img src="assets/images/triangle.png" style="transform:rotate(180deg)" width="11px" /> -->
            </div>
            <div class="bg-white">
                <div class="ml-5" style="border-left:1px dashed #dee2e6">
                    <a href="https://support.voiply.com" target="_blank">

                        <button class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                            <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                                <img src="assets/images/aftercheckout-dashboard/home.png" style="width:16px; "
                                    class="mr-2" />
                            </div>
                            Home Page
                        </button>
                    </a>
                    <button *ngIf="!isFaxOnly" (click)="openTroubleShooting()"
                        class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <i class="fas fa-cogs mr-2 text-muted"></i>

                        </div>
                        Troubleshoot
                    </button>
                    <button (click)="scrollTo('faq')"
                        class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <img src="assets/images/aftercheckout-dashboard/question-mark.png" style="width:10px; "
                                class="mr-2" />
                        </div>
                        FAQ
                    </button>
                    <button (click)="openCrispChat()"
                        class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                        <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                            <img src="assets/images/aftercheckout-dashboard/chat.png" style="width:16px; "
                                class="mr-2" />
                        </div>
                        Chat Now
                    </button>
                    <a class="typeform-share button" href="https://voiply.typeform.com/to/MRALAC" data-mode="popup"
                        target="_blank">
                        <button class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                            <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                                <img src="assets/images/aftercheckout-dashboard/call-me.png" style="width:13px; "
                                    class="mr-2" />
                            </div>
                            Call Me
                        </button>
                    </a>
                    <a class="typeform-share button" href="https://voiply.typeform.com/to/M9130p" data-mode="popup"
                        target="_blank">
                        <button class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                            <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                                <img src="assets/images/aftercheckout-dashboard/up-arrow.png" style="width:16px; "
                                    class="mr-2" />
                            </div>
                            Get Support
                        </button>
                    </a>
                    <a  href="https://rates.voiply.com" target="_blank">
                        <button class="d-flex flex-row align-items-center text-color w-100 btn p-3">
                            <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                                <img src="assets/images/aftercheckout-dashboard/International_Rates.svg"
                                    style="width:20px; " class="mr-2" />
                            </div>
                            Intl Rates
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <div class=" mt-2">
            <div
                class="d-flex flex-row bg-primary-gradient text-primary px-3 py-2 justify-content-between align-items-center">
                <div class="font-weight-bold d-flex align-items-center"><img
                        src="assets/images/aftercheckout-dashboard/smile.png" style="width:20px; " class="mr-3" />LEAVE
                    YOUR FEEDBACK
                </div>
                <!-- <img src="assets/images/triangle.png" style="transform:rotate(180deg)" width="11px" /> -->
            </div>
            <div class="bg-white">
                <div class="ml-5" style="border-left:1px dashed #dee2e6">
                    <a class="typeform-share button" href="https://voiply.typeform.com/to/wleiAV" target="_blank"
                        data-mode="drawer_right">
                        <button class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                            <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                                <img src="assets/images/aftercheckout-dashboard/happy.png" style="width:16px; "
                                    class="mr-2" />
                            </div>
                            Send a Smile
                        </button>
                    </a>
                    <a class="typeform-share button" href="https://voiply.typeform.com/to/wleiAV" target="_blank"
                        data-mode="drawer_right">
                        <button class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                            <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                                <img src="assets/images/aftercheckout-dashboard/sad.png" style="width:16px; "
                                    class="mr-2" />
                            </div>
                            Send a Frown
                        </button>
                    </a>
                    <a class="typeform-share button" href="https://voiply.typeform.com/to/wleiAV" target="_blank"
                        data-mode="drawer_right">
                        <button class="d-flex flex-row border-bottom align-items-center text-muted w-100 btn p-3">
                            <div class="d-flex align-items-center justify-content-center" style="width:25px;">
                                <img src="assets/images/aftercheckout-dashboard/mail.png" style="width:16px; "
                                    class="mr-2" />
                            </div>
                            Suggest an Idea
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <div *ngIf="!this.customer.updatedSignUp&&!userIsVoiplyMember&&this.customer.method!=='password'">
            <div
                class="d-flex flex-row bg-primary-gradient text-primary px-3 py-3 justify-content-between align-items-center">
                <button class="font-weight-bold d-flex align-items-center btn text-primary p-0" (click)="authPopup()">
                    <i class="fas fa-sign-in-alt text-primary" style="font-size: 18px;"></i><span class="ml-3">Change
                        Sign In Method
                    </span>
                </button>
                <i class="fas fa-question-circle text-primary cursor-pointer" popover="change SignIn Method"
                    placement="auto" containerClass="popover-css" triggers="mouseenter:mouseleave"></i>
            </div>
        </div>
    </div>

</ng-template>