<div class="mx-4 mt-2 d-flex flex-column">
  <div [hidden]="payViaBrowser&&showPaymentButton" id="example5-card" #cardInfo class="input bg-grey rounded flex-fill"
    style="height: 54px; padding:16px 0 0 14px;">
  </div>
  <div *ngIf="showPaymentButton">
    <div class="d-flex justify-content-between px-3 py-2">
      <div>
        <span class="radio-container" (click)="changePayViaBrowser(true)">
          <input style="margin-right: 5px" type="radio" name="payButtons" [(ngModel)]="payViaBrowser"
            (click)="changePayViaBrowser(true)" [value]="true" [ngModelOptions]="{standalone: true}">
          Google/Apple Pay
          <span (click)="changePayViaBrowser(true)" class="checkmark"></span>
        </span>
      </div>
      <div>
        <span class="radio-container" (click)="changePayViaBrowser(false)">
          <input style="margin-right: 5px" type="radio" name="payButtons" [(ngModel)]="payViaBrowser"
            (click)="changePayViaBrowser(false)" [value]="false" [ngModelOptions]="{standalone: true}">
          Pay via Credit Card
          <span (click)="changePayViaBrowser(false)" class="checkmark"></span>
        </span>
      </div>
    </div>
  </div>
</div>