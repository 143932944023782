import { Component, OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';
import { PrimaryNumberService } from '../../services';

@Directive()
export class ExistingNumberBase implements OnInit {


  checkingPortability: boolean = false;
  currentNumberIsPortable: boolean = false;
  currentNumberToPort: string = null;
  videoId = "g6u98c4qew";
  isTollFreeNumber = false;
  _selectedNumber: string;
  @Input()
  set selectedNumber(value: string) {
    this._selectedNumber = value;
  };

  @Input()
  isNew: boolean;
  @Input()
  isValid: boolean;
  @Input()
  canAddCompanyNumbers = false;
  @Input()
  tollFreeNumberNotAllowed = false;
  @Output()
  updateNumber: EventEmitter<any> = new EventEmitter<any>();
  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  constructor(public primaryNumberService: PrimaryNumberService) { }

  ngOnInit() {

  }

  checkNumberPortability() {
    this.checkingPortability = true;
    const phoneNum = this._selectedNumber.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    if (phoneNum.toString().length >= 3) {
      if (['800', '833', '844', '855', '866', '877', '888'].indexOf(phoneNum.substring(0, 3)) >= 0) {
        this.checkingPortability = false;
        this.currentNumberToPort = this._selectedNumber;
        this.isTollFreeNumber = true;
        console.log('Current number is portable', 'toll free number');
        if (!this.tollFreeNumberNotAllowed) {
          this.currentNumberIsPortable = true;
        }
        return;
      }
    }

    this.primaryNumberService.getNumberPortabilityStatus(phoneNum).subscribe(o => {
      this.isTollFreeNumber = false;
      this.checkingPortability = false;
      this.currentNumberToPort = this._selectedNumber;
      console.log('Current number is portable', o.result, o);
      this.currentNumberIsPortable = o.result == "true";
    });
  }
  formatNumber(number): string {
    if (number.length === 10) {
      return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
  }
  onClick() {
    this.currentNumberIsPortable = null;
    this.update.emit({ number: this._selectedNumber, isNew: false, systemSelected: false });
  }

}
