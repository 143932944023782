import { Component } from '@angular/core';
import { ShippingOptionsBase } from '../shipping-options-base';
@Component({
  selector: 'checkout-shipping-options-new',
  templateUrl: './shipping-options-new.component.html',
  styleUrls: ['./shipping-options-new.component.scss']
})
export class ShippingOptionsNewComponent extends ShippingOptionsBase {

}
