<pagination-template #p="paginationApi" (pageChange)="pageChange.emit($event)" [id]="id" [maxSize]="200">
  <div class=" d-flex flex-row justify-content-between align-items-center">
    <div class="pagination-previous ">
      <button type="button" class="btn btn-primary d-none d-sm-block" [disabled]="p.isFirstPage()"
        (click)="p.previous()"> Previous Page
      </button>

      <button [disabled]="p.isFirstPage()" (click)="p.previous()" class=" d-block d-sm-none btn"> <i
          class="fas fa-chevron-left fa-2x text-secondary"></i></button>
    </div>
    <div class="pagination-previous display-text">
      Displaying <span class="page">{{p.getCurrent()}}</span> of <span class="page">{{p.pages.length}}</span>
    </div>
    <div class="pagination-previous">
      <button type="button" class="btn btn-primary d-none d-sm-block" [disabled]="p.isLastPage()" (click)="p.next()">
        Next Page</button>
      <button [disabled]="p.isLastPage()" (click)="p.next()" class=" d-block d-sm-none btn"> <i
          class="fas fa-chevron-right fa-2x text-secondary"></i></button>

    </div>
  </div>

</pagination-template>