
<div class="d-flex flex-column flex-wrap">
    <div class="d-flex flex-row p-2 justify-content-between align-items-center flex-wrap flex-sm-nowrap w-100" style="background-color: #F3F3F3; border-radius:5px !important;">
        <div class="flex-fill d-flex flex-row h3 mb-0 ">
            <div class="h6 h3-md">
              <!-- <input class="bg-transperant py-3 changeTextSize" #existingPhoneNumber #numberInput mask="(000) 000-0000"
            [(ngModel)]="_selectedNumber" placeholder="Enter a Phone Number" (keyup)="checkNumberPortability()"
            style="background-color:transparent;outline:none;border:none;box-shadow:none !important;width:97%;"> -->
              <input class="bg-transperant changeTextSize rounded-top form-control h-100 primary-number-input border-0" type="text" mask="(000) 000-0000" 
              name="primaryNumber" #primaryNumber="ngModel" placeHolder="(XXX) XXX-XXXX" 
              [(ngModel)]="_selectedNumber" (keyup)="checkNumber();" [ngClass]="{'is-invalid': !isValid && !isNew}"
              style="background-color:transparent;outline:none;border:none;box-shadow:none !important;width:97%;">
            </div>
            
            <div class="d-block d-lg-none align-self-center">
              <div class="ml-2"style="font-size:small;color:#BBBBBB;font-weight:bold;">FREE<sup><del>$39.99</del></sup></div>
          </div>
        </div>
        <div class="flex-fill d-flex justify-content-around flex-row align-self-center">
            
          <div class="d-none d-lg-block align-self-center">
            <div class="p-sm-1"style="font-size:small;color:#BBBBBB;font-weight:bold;">FREE<sup><del>$39.99</del></sup></div>
        </div>

            <div class=" f-flex align-items-center align-self-center" *ngIf="currentNumberIsPortable === true && currentNumberToPort" style="background-color: #C0F3BC;border-radius:5px;">
                <div class="text-center py-3 px-4 "style="font-size:medium;font-weight:normal; color:#068E0A;letter-spacing:1px;" >TRANSFERABLE NUMBER</div>
            </div>

            <div class=" f-flex align-items-center align-self-center" *ngIf="currentNumberIsPortable === false && currentNumberToPort  &&!isTollFreeNumber" style="background-color: red;border-radius:5px;">
              <div class="text-center py-3 px-4 "style="font-size:medium;font-weight:normal; color:white;letter-spacing:1px;" >CAN NOT TRANSFER</div>
          </div>

        </div>
    </div>

    <div class="d-flex flex-row flex-fill mt-5 flex-wrap flex-sm-nowrap justify-content-between">
      <div class="d-flex flex-column">
        <div class="d-flex flex-row p-2">
          <div class="" style="background-color: grey;border-radius:7px;min-width:5px;">

          </div>
          <div class="ml-2">
            The transfer process of your number  may take up to 10 Business Days to complete.
          </div>
        </div>
        
        <div class="pt-1 m-2" style="background-image: linear-gradient(to right, #616161, #f9f9f9);"></div>
        
        <div class="d-flex flex-row p-2">
          <div class="" style="background-color: grey;border-radius:7px;min-width:5px;">

          </div>
          <div class="ml-2">
            Do not contact your phone company/carrier to disconnect your local telephone service.
          </div>
        </div>

        <div class="pt-1 m-2" style="background-image: linear-gradient(to right, #616161, #f9f9f9);"></div>
        
        <div class="d-flex flex-row p-2">
          <div class="" style="background-color: grey;border-radius:7px;min-width:5px;">

          </div>
          <div class="ml-2">
            Extra Business Numbers are $1.25/month
          </div>
        </div>
      </div>

      <div class=" rounded-lg p-2 dynamic-width">
        <!-- <voiply-wistia-player videoId="g6u98c4qew"></voiply-wistia-player> -->
        <div style="padding:56.25% 0 0 0;position:relative;">
          <iframe src="https://player.vimeo.com/video/571623693?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;portrait=0&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" ></iframe>
      </div>
    </div>
    </div>
</div>