import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { COMPONENTS } from './component';
import { CONTAINERS } from './container';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ShareModule } from '@ngx-share/core';

@NgModule({
  imports: [
    CommonModule,
    CollapseModule,
    ShareModule
  ],
  declarations: [
    ...CONTAINERS,
    ...COMPONENTS
  ],
  exports: [
    ...CONTAINERS,
    ...COMPONENTS
  ]
})
export class FooterModule { }
