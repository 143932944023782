<button [ngClass]="{'btn btn-styles bg-orange text-primary h-100 w-100 py-2':true,  'bg-grey':isNew}" (click)="numberPortabilityModal.show();" class="position-relative d-none">
  <i class="fas fa-phone-volume d-none d-md-block" style="font-size: 2rem;"></i>
  <i class="fas fa-phone-volume fa-lg d-md-none"></i>
  <p class="m-0"> BRING YOUR NUMBER</p>
  <div class="position-absolute px-2 py-1" style="background-color: rgba(0,0,0,0.1); top:0;right:0"
    *ngIf="canAddCompanyNumbers"><i class="fal fa-plus"></i></div>
</button>

<!-- Number Portability Checker Modal -->
<div class="modal fade d-none" bsModal #numberPortabilityModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header d-flex justify-content-between align-items-center" style="background-color: #fe330a; color: #ffffff;">
                <h3 class=" m-0">
                    Do you want to keep your current phone number?
                </h3>
                <button type="button" class=" btn pull-right" (click)="numberPortabilityModal.hide()">
          <i class="fas fa-times-circle fa-lg text-primary"></i>
        </button>
                <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="numberPortabilityModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button> -->
            </div>
            <div class="modal-body">
                <h5 class="">
                    Let's check if your current phone number can be used for your new Voiply service (submit porting request after checkout).
                </h5>
                <div class="d-flex flex-wrap flex-md-nowrap">
                    <input class="form-control" #existingPhoneNumber #numberInput mask="(000) 000-0000" [(ngModel)]="_selectedNumber" placeholder="Please Enter a Phone Number" style="max-width: 250px;">
                    <button class="btn mt-3 mt-md-0 ml-md-3 bg-primary text-primary" (click)="checkNumberPortability()">
            <span *ngIf="!checkingPortability">Check Portability</span>
            <mat-spinner *ngIf="checkingPortability" class="white-spinner" diameter="20" value="accent">
            </mat-spinner>
          </button>
                    <button *ngIf="currentNumberIsPortable === true && currentNumberToPort" class="btn btn-primary mt-3 mt-md-0 ml-md-3" (click)="onClick(); numberPortabilityModal.hide()">
            Add Number
          </button>
                </div>
                <div *ngIf="currentNumberIsPortable === false && currentNumberToPort &&tollFreeNumberNotAllowed &&isTollFreeNumber" class="mt-3">
                    <h6 class="text-danger"><i class="fas fa-times-circle mr-2"></i> We only support (8XX) numbers on our business service, please visit us at <a href="https://build.voiply.com">https://build.voiply.com</a>

                    </h6>
                </div>
                <div *ngIf="currentNumberIsPortable === false && currentNumberToPort  &&!isTollFreeNumber" class="mt-3">
                    <h4 class="text-danger"><i class="fas fa-times-circle mr-2"></i> Sorry! We cannot transfer <span style="font-weight: bold">{{currentNumberToPort}}</span> to Voiply service.
                    </h4>
                </div>
                <div *ngIf="currentNumberIsPortable === true && currentNumberToPort" class="mt-3">
                    <h4 class="text-success"><i class="fas fa-check-circle mr-2"></i> Success! We can transfer <span style="font-weight: bold">{{currentNumberToPort}}</span> to your new Voiply service.
                    </h4>

                    <hr>

                    <div>
                        Please note this important information concerning number transfer.
                    </div>
                    <ul class="mb-4">
                        <li>Do not contact your phone company/carrier to disconnect your local telephone service.</li>
                        <li>Your telephone number must be active with your old phone company for us to transfer your number to Voiply.
                        </li>
                        <li>You will need to authorize the transfer of your number later in the order process.</li>
                        <li>Transferring a number may take up to 10 business days to complete.</li>
                        <li>During the transfer period, your current phone line will continue to operate normally. You can install and use your service as soon as you receive your adapter.</li>
                        <li>While you're waiting on the number transfer, you can still take advantage of all the great features Voiply has to offer.</li>
                    </ul>

                    <voiply-wistia-player [videoId]=videoId style="height:100%;left:0;top:0;width:100%;"></voiply-wistia-player>
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <input class="bg-white rounded-top form-control h-100 primary-number-input border-0" type="text" mask="(000) 000-0000" name="primaryNumber" #primaryNumber="ngModel" placeHolder="(XXX) XXX-XXXX" [(ngModel)]="_selectedNumber" (keyup)="checkNumber();" [ngClass]="{'is-invalid': !isValid && !isNew}">
    <div *ngIf="_selectedNumber == ''" class="text-muted font-weight-bolder text-center rounded-bottom text-uppercase" style="background-color: #D4D4D4; font-size: 12px;">Enter your residential phone number to continue</div>
    <div *ngIf="_selectedNumber != '' && (_selectedNumber || []).length < 10" class="text-muted font-weight-bolder text-center rounded-bottom text-uppercase" style="background-color: #D4D4D4; font-size: 12px;">The phone number should have 10 digits</div>
    <div *ngIf="currentNumberIsPortable === true&& this.currentNumberToPort && (_selectedNumber || []).length == 10" class="text-success font-weight-bold text-center rounded-bottom text-uppercase" style="background-color: #C0F3BC; font-size: 12px;">Success! We Can Transfer Your Number to Voiply.</div>
    <div *ngIf="currentNumberIsPortable === false && this.currentNumberToPort && (_selectedNumber || []).length == 10" class="text-danger font-weight-bolder text-center rounded-bottom text-uppercase" style="background-color: #FFBEC0; font-size: 12px;">Failed! Unfortunately we cannot transfer your number</div>
</div>