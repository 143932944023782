import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, TemplateRef } from '@angular/core';
import { PromoCode, CheckoutDetail } from '@voiply/shared/model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'checkout-promo-code-new',
  templateUrl: './promo-code-new.component.html',
  styleUrls: ['./promo-code-new.component.css']
})
export class PromoCodeNewComponent implements OnChanges {

  @Input()
  promoCodeApplied: PromoCode;
  @Input()
  checkoutDetail: CheckoutDetail;
  @Input()
  cartHasHardware: boolean;

  @Output()
  applyPromoCode = new EventEmitter<string>();
  @Output()
  removePromoCode = new EventEmitter();

  promoCode: string;
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.promoCodeApplied.status) {
      this.modalRef.hide();
    }
  }

  openModal(template: TemplateRef<any>) {
    this.promoCodeApplied.status = false;
    this.modalRef = this.modalService.show(template);
  }
}
