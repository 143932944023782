import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppStateSelectors, SetPrimaryPhoneFormStatusAction, SetPrimaryPhoneNumber, OnDestroyCleanup, sanitizePhoneNumber, AddCompanyNumberAction } from '@voiply/shared/common-ui';
import * as Model from '@voiply/shared/model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { NumberSearchService } from '@voiply/shared/number-search';
import { OnInit } from '@angular/core';
import { CartItemType } from '@voiply/shared/model';
import * as _ from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { take } from 'rxjs/operators';

@Component({
  selector: 'voiply-primary-number-home',
  templateUrl: './primary-number-home.component.html',
  styleUrls: ['./primary-number-home.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('out', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      transition('in => out', animate('400ms')),
      transition('out => in', animate('400ms'))
    ]),
    trigger('childAnimation1', [
      state('in', style({
        transform: 'rotate(0deg)'
      })),
      state('out', style({
        transform: 'rotate(180deg)'
      })),
      transition('in => out', animate('200ms', style({ transform: 'rotate(180deg)' }))),
      transition('out => in', animate('200ms', style({ transform: 'rotate(0deg)' })))
    ])
  ]
})
export class PrimaryNumberHomeComponent extends OnDestroyCleanup implements OnInit {
  primaryPhone: Model.PrimaryPhone;
  newNumber: string = '';
  accordianInit = false;
  existingNumber: string = '';
  isValid = false;
  submitted = false;
  canAddCompanyNumbers = false;
  tollFreeNumberNotAllowed = true;
  canshowCompanyNumber = false;

  accordianShowExistingNumber = "in";
  accordianShowNewNumber = "out";

  @Select(AppStateSelectors.primaryPhone) primaryPhone$: Observable<Model.PrimaryPhone>;
  @Select(AppStateSelectors.currentLocation) currentLocation$: Observable<any>;
  @Select(AppStateSelectors.doValidate) performValidation$: Observable<object>;
  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;

  @Dispatch() setPrimaryPhoneNumber = (phoneNumber, updateInServer = true) => new SetPrimaryPhoneNumber(phoneNumber, updateInServer);
  @Dispatch() primaryNumberFormStatusChanged = (isValid) => new SetPrimaryPhoneFormStatusAction(isValid);
  constructor(private numberSearchService: NumberSearchService) {
    super();
    this.subscriptions$.add(this.primaryPhone$.subscribe(phoneNumber => {
      this.primaryPhone = phoneNumber;
      this.isValid = phoneNumber.isValid;
      this.showInitialAccordian();
    }));
    this.performValidation$.subscribe(newvalue => {
      if (newvalue)
        this.submitted = true;
    })
    this.subscriptions$.add(this.cartItems$.subscribe(cartItems => {
      if (!(_.size(cartItems) > 0)) {
        this.canshowCompanyNumber = true;
        return;
      }
      _.each(cartItems, (cartItem) => {
        if (cartItem.type === CartItemType.Phone) {
          this.canshowCompanyNumber = true;
          //This return is to exit loop
          return false;
        } else {
          this.canshowCompanyNumber = false;
        }
      });
    }));
  }


  ngOnInit(): void {
    this.subscriptions$.add(this.currentLocation$.subscribe(currentLocation => {
      // Get temp number to be shown in number textbox
      if (location.origin.indexOf("localhost") > -1) {
        if (this.primaryPhone.number === '' || this.primaryPhone.number === null && currentLocation.stateCode != null) {
          this.newNumber = '1111111111';

        }
      }
      else {
        if (this.primaryPhone.number === '' || this.primaryPhone.number === null && currentLocation.stateCode != null) {
          this.numberSearchService.getNumbersByState(currentLocation.stateCode, '', currentLocation.stateCode === "NY" ? '929' : '')
            .subscribe(o => {
              if (o && o.numbers && o.numbers.length > 0)
                this.newNumber = o.numbers[0].toString()
            })
        }
      }
    }));
  }

  showInitialAccordian() {
    if(this.accordianInit) return;

    if(!this.primaryPhone.isNew) {
      this.accordianShowExistingNumber = "out";
      this.accordianShowNewNumber = "in";
    }
    else {
      this.accordianShowExistingNumber = "in";
      this.accordianShowNewNumber = "out";
    }

    this.accordianInit = true;
  }

  getNewNumber() {
    this.existingNumber = this.primaryPhone.number;
    if (location.origin.indexOf("localhost") > -1)
      this.setPrimaryPhoneNumber({ number: this.newNumber, isNew: false, systemSelected: false });
    else
      this.setPrimaryPhoneNumber({ number: this.newNumber, isNew: true, systemSelected: true });
  }
  getExistingNumber() {
    this.newNumber = this.primaryPhone.number;
    this.setPrimaryPhoneNumber({number: this.existingNumber , isNew: false, systemSelected: false});
  }

  onNumberSelected(phoneNumber: any) {
    const sanitizedPhoneNumber = sanitizePhoneNumber(phoneNumber.number.toString());
    this.setPrimaryPhoneNumber({ number: sanitizedPhoneNumber, isNew: phoneNumber.isNew, systemSelected: false });
  }
  onUpdate(existingNumber: any) {
    const sanitizedPhoneNumber = sanitizePhoneNumber(existingNumber.number.toString());
    this.setPrimaryPhoneNumber({ number: sanitizedPhoneNumber, isNew: existingNumber.isNew, systemSelected: existingNumber.systemSelected });
  }
  savePrimaryNumber() {
    if (this.primaryPhone.number !== null) {
      const sanitizedPhoneNumber = sanitizePhoneNumber(this.primaryPhone.number.toString());
      this.setPrimaryPhoneNumber({ number: sanitizedPhoneNumber, isNew: this.primaryPhone.isNew, systemSelected: this.primaryPhone.systemSelected });
    }
  }
  setIsNewStatus() {
    //need to change color of "Existing Number" and "New Number" buttons when user starts typing primary number
    //set isNew status on every key press, only in state and not in cosmos db
    if (this.primaryPhone.number !== null) {
      const sanitizedPhoneNumber = sanitizePhoneNumber(this.primaryPhone.number.toString());
      this.setPrimaryPhoneNumber({ number: sanitizedPhoneNumber, isNew: false, systemSelected: this.primaryPhone.systemSelected }, false);
    }
  }

  toggleAccordianShow(id) {
    this.accordianShowExistingNumber == 'in' ?  this.accordianShowExistingNumber = 'out' : this.accordianShowExistingNumber = 'in';
    this.accordianShowNewNumber == 'in' ?  this.accordianShowNewNumber = 'out' : this.accordianShowNewNumber = 'in';

    if(this.accordianShowNewNumber == 'out')
      this.getNewNumber();
    else
      this.getExistingNumber();
  }
}
