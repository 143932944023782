import { Component, Input, OnInit } from '@angular/core';
import { CompanyFeatures } from '../company-features.base';
import { CompanyFeaturesService } from '../../services/company-features.service';

@Component({
  selector: 'voiply-International-call-feature-home',
  templateUrl: './International-call-feature-home.component.html',
  styleUrls: ['../company-features.scss', './International-call-feature-home.component.scss']
})
export class InternationalCallFeatureHomeComponent extends CompanyFeatures {
  constructor(service: CompanyFeaturesService) {
    super(service);
  }

}
