<div class="w-100 d-flex justify-content-center">
    <button class="btn bg-orange text-primary m-3 py-2 px-3 rounded-lg" (click)="staticModal.show();">
    <p class="m-0 text-center"> PICK A NEW </p>
    <div class="position-absolute px-2 py-1" *ngIf="canAddCompanyNumbers"
      style="background-color: rgba(0,0,0,0.1); top:0;right:0"><i class="fal fa-plus"></i></div>
  </button>
</div>
<!-- Latest -->
<div class="modal fade" bsModal #staticModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog  modal-lg p-0" role="document">
    <div class="modal-content p-2" style="background-color: #EEEEEE;">
      <div class="modal-body p-0" >
        <div class="card">
          <div class="card-body" style="background-color: white;">
            <div>
              <i class="d-flex flex-row-reverse btn fa fa-times p-0" aria-hidden="true" (click)="staticModal.hide()" ></i>
              <h3 class="mb-0">Pick a New {{toggle ? "Local": "Toll Free"}}  Number</h3>
              <div class="flex-fill w-100">
                <!-- <div class="btn btn-group btn-sm d-flex flex-column flex-md-row m-0 p-1" role="group"
                    aria-label="Basic example">
                    <button id="1" type="button"
                        class="btn border d-flex flex-row justify-content-between align-items-center p-3"
                        [ngClass]="toggle? 'open_color' :'nopen_color'" (click)="onClick('1')">
                        
                        <div class="flex-grow-1">LOCAL</div>
                    </button>
                    <button id="2" type="button"
                        class="btn border d-flex flex-row justify-content-between align-items-center p-3"
                        [ngClass]="toggle? 'nopen_color' :'open_color'" (click)="onClick('2')">
                        <div class="flex-grow-1">TOLL-FREE</div>
                    </button>
                </div> -->
              </div>

              <div class="mt-4" *ngIf="toggle">
                <voiply-local-number-search-alt (numberSelected)="selectedNumber = $event" ></voiply-local-number-search-alt>
              </div>

              <div class="mt-4" *ngIf="!toggle">
                <voiply-tollfree-number-search-alt  (numberSelected)="selectedNumber = $event"  ></voiply-tollfree-number-search-alt>
              </div>

            </div>

            <div class="d-flex flex-row-reverse">
              <button type="button" class="btn bg-primary text-primary mt-3"
                  (click)="onNumberSelected();staticModal.hide()">
                  <h5 class="mb-0 p-1" style="font-weight:normal; letter-spacing: 1px;"> CONFIRM </h5>
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- 
<div class="modal fade" bsModal #staticModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title font-weight-bold m-0" id="exampleModalLongTitle">Select a New Number</h3>
            </div>
            <div class="modal-body">
                <div *ngIf="tollFreeNumberNotAllowed">
                    <voiply-local-number-search-alt (numberSelected)="selectedNumber = $event"></voiply-local-number-search-alt>
                </div>
                <tabset [justified]="true" *ngIf="!tollFreeNumberNotAllowed">
                    <tab heading="LOCAL NUMBERS" (selectTab)="internationalTabSelected=false">
                        <div class="mt-4">
                            <voiply-local-number-search-alt (numberSelected)="selectedNumber = $event"></voiply-local-number-search-alt>
                        </div>
                    </tab>
                    <tab heading="TOLL FREE NUMBERS" (selectTab)="internationalTabSelected=false">
                        <div class="mt-4">
                            <voiply-tollfree-number-search (numberSelected)="selectedNumber = $event"></voiply-tollfree-number-search>
                        </div>
                    </tab>
                    <tab heading="INTERNATIONAL NUMBERS" (selectTab)="internationalTabSelected=true" *ngIf="canAddCompanyNumbers">
                        <div class="mt-4">
                            <voiply-international-number-search (internationalNumberSelected)="setInternationalNumber($event)">
                            </voiply-international-number-search>
                        </div>
                    </tab>
                </tabset>

            </div>
            <div class="modal-footer d-flex  align-items-center flex-wrap justify-content-end" *ngIf="!internationalTabSelected">
                <div class="d-flex justify-content-end flex-grow-1">
                    <button type="button" class="btn btn-outline-dark px-4 py-2 mr-3" data-dismiss="modal" (click)="staticModal.hide()">Cancel</button>
                    <button type="button" class="btn bg-primary text-primary px-4 py-2" (click)="onNumberSelected();staticModal.hide()">
            Add Number
          </button>
                </div>
            </div>
            <div class="modal-footer d-flex  align-items-center flex-wrap justify-content-between" *ngIf="internationalTabSelected">
                <div class="mb-3 mb-lg-0 d-flex flex-row align-items-center">
                    <i class="fas fa-exclamation-square fa-lg mr-3"></i>
                    <div class="text-color text-wrap" style="font-size: 14px;max-width: 400px;">Activation Fee and Minimum Monthly Requirement will be assessed and billed after purchase</div>
                </div>
                <div class="d-flex justify-content-end flex-grow-1">
                    <button type="button" class="btn btn-outline-dark px-4 py-2 mr-3" data-dismiss="modal" (click)="staticModal.hide()">Cancel</button>
                    <button type="button" class="btn bg-primary text-primary px-4 py-2" (click)="onInternationalNumberSelected();staticModal.hide()">
            Add Number
          </button>
                </div>
            </div>
        </div>
    </div>
</div> -->