import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import { CartItemsComponent } from './cart-items/cart-items.component';
import { CartItemsNewComponent } from './cart-items-new/cart-items-new.component';
import { TaxAndFeesComponent } from './tax-and-fees-container/tax-and-fees/tax-and-fees.component';
import { TaxAndFeesNewComponent } from './tax-and-fees-container/tax-and-fees-new/tax-and-fees-new.component';
import { SubTotalComponent } from './sub-total-container/sub-total/sub-total.component';
import { SubTotalNewComponent } from './sub-total-container/sub-total-new/sub-total-new.component';
import { ShippingOptionsComponent } from './shipping-options-container/shipping-options/shipping-options.component';
import { ShippingOptionsNewComponent } from './shipping-options-container/shipping-options-new/shipping-options-new.component';
import { BillingPlanComponent } from './billing-plan/billing-plan.component';
import { BillingPlanNewComponent } from './billing-plan-new/billing-plan-new.component';
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { PromoCodeNewComponent } from './promo-code-new/promo-code-new.component';
import { CartTotalComponent } from './cart-total-container/cart-total/cart-total.component';
import { CartTotalNewComponent } from './cart-total-container/cart-total-new/cart-total-new.component';
import { AddressComponent } from './address-container/address/address.component';
import { AddressNewComponent } from "./address-container/address-new/address-new.component";
import { AddressHomeAfterCheckoutComponent } from './address-container/address-home-after-checkout/address-home-after-checkout.component';
import { ShippingAddressComponent } from './shipping-address-container/shipping-address/shipping-address.component';
import { ShippingAddressNewComponent } from './shipping-address-container/shipping-address-new/shipping-address-new.component';
import { ShippingAddressHomeAfterCheckoutComponent } from './shipping-address-container/shipping-address-home-after-checkout/shipping-address-home-after-checkout.component';
import { BillingBusinessComponent } from './billing/billing-business/billing-business.component';
import { BillingNewComponent } from './billing/billing-new/billing-new.component';
import { BillingHomeAfterCheckoutComponent } from './billing/billing-home-after-checkout/billing-home-after-checkout.component';
import { ShareOrderComponent } from './share-order/share-order.component';
import { StripeComponent } from './stripe/stripe.component';
import { StripeNewComponent } from './stripe-new/stripe-new.component';
import { TaxAndFeesAfterCheckoutComponent } from './tax-and-fees-container/tax-and-fees-after-checkout/tax-and-fees-after-checkout.component';
import { SubTotalAfterCheckoutComponent } from './sub-total-container/sub-total-after-checkout/sub-total-after-checkout.component';
import { ShippingOptionsAfterCheckoutComponent } from './shipping-options-container/shipping-options-after-checkout/shipping-options-after-checkout.component';
import { CartTotalAfterCheckoutComponent } from './cart-total-container/cart-total-after-checkout/cart-total-after-checkout.component';
import { ProtectionPlanComponent } from './protection-plan/protection-plan.component';
import { ProtectionPlanNewComponent } from './protection-plan-new/protection-plan-new.component';
import { ThankyouModalComponent } from './thank-you-modal-container/thankyou-modal/thankyou-modal.component';
import { TaxAndFeesAfterCheckoutAltComponent } from './tax-and-fees-container/tax-and-fees-after-checkout-alt/tax-and-fees-after-checkout-alt.component';
import { ShippingOptionsAfterCheckoutAltComponent } from './shipping-options-container/shipping-options-after-checkout-alt/shipping-options-after-checkout-alt.component';
import { CartTotalAfterCheckoutAltComponent } from './cart-total-container/cart-total-after-checkout-alt/cart-total-after-checkout-alt.component';
import { ThankyouModalAltComponent } from './thank-you-modal-container/thankyou-modal-alt/thankyou-modal-alt.component';
import { AddressExtralineComponent } from './address-container/address-extraline/address-extraline.component';
import { BillingExtralineComponent } from './billing/billing-extraline/billing-extraline.component';

export const COMPONENTS = [
    CartSummaryComponent,
    CartItemsComponent,
    CartItemsNewComponent,
    TaxAndFeesComponent,
    TaxAndFeesNewComponent,
    TaxAndFeesAfterCheckoutAltComponent,
    SubTotalComponent,
    SubTotalNewComponent,
    ShippingOptionsComponent,
    ShippingOptionsNewComponent,
    BillingPlanComponent,
    BillingPlanNewComponent,
    PromoCodeComponent,
    PromoCodeNewComponent,
    CartTotalComponent,
    CartTotalNewComponent,
    AddressComponent,
    AddressNewComponent,
    AddressHomeAfterCheckoutComponent,
    ShippingAddressComponent,
    ShippingAddressNewComponent,
    ShippingAddressHomeAfterCheckoutComponent,
    BillingBusinessComponent,
    BillingNewComponent,
    BillingHomeAfterCheckoutComponent,
    ShareOrderComponent,
    StripeComponent,
    StripeNewComponent,
    TaxAndFeesAfterCheckoutComponent,
    SubTotalAfterCheckoutComponent,
    ShippingOptionsAfterCheckoutComponent,
    ShippingOptionsAfterCheckoutAltComponent,
    CartTotalAfterCheckoutComponent,
    CartTotalAfterCheckoutAltComponent,
    ProtectionPlanComponent,
    ProtectionPlanNewComponent,
    ThankyouModalComponent,
    ThankyouModalAltComponent,
    AddressExtralineComponent,
    BillingExtralineComponent
];

export { CartSummaryComponent } from './cart-summary/cart-summary.component';
export { CartItemsComponent } from './cart-items/cart-items.component';
export { CartItemsNewComponent } from './cart-items-new/cart-items-new.component';
export { TaxAndFeesComponent } from './tax-and-fees-container/tax-and-fees/tax-and-fees.component';
export { TaxAndFeesNewComponent } from './tax-and-fees-container/tax-and-fees-new/tax-and-fees-new.component';
export { SubTotalComponent } from './sub-total-container/sub-total/sub-total.component';
export { SubTotalNewComponent } from './sub-total-container/sub-total-new/sub-total-new.component';
export { ShippingOptionsComponent } from './shipping-options-container/shipping-options/shipping-options.component';
export { ShippingOptionsNewComponent } from './shipping-options-container/shipping-options-new/shipping-options-new.component';
export { BillingPlanComponent } from './billing-plan/billing-plan.component';
export { BillingPlanNewComponent } from './billing-plan-new/billing-plan-new.component';
export { PromoCodeComponent } from './promo-code/promo-code.component';
export { PromoCodeNewComponent } from './promo-code-new/promo-code-new.component';
export { CartTotalComponent } from './cart-total-container/cart-total/cart-total.component';
export { CartTotalNewComponent } from './cart-total-container/cart-total-new/cart-total-new.component';
export { AddressComponent } from './address-container/address/address.component';
export { AddressNewComponent } from "./address-container/address-new/address-new.component";
export { AddressHomeAfterCheckoutComponent } from './address-container/address-home-after-checkout/address-home-after-checkout.component';
export { ShippingAddressComponent } from './shipping-address-container/shipping-address/shipping-address.component';
export { ShippingAddressNewComponent } from './shipping-address-container/shipping-address-new/shipping-address-new.component';
export { ShippingAddressHomeAfterCheckoutComponent } from './shipping-address-container/shipping-address-home-after-checkout/shipping-address-home-after-checkout.component';
export { BillingBusinessComponent } from './billing/billing-business/billing-business.component';
export { BillingNewComponent } from './billing/billing-new/billing-new.component';
export { BillingHomeAfterCheckoutComponent } from './billing/billing-home-after-checkout/billing-home-after-checkout.component';
export { ShareOrderComponent } from './share-order/share-order.component';
export { StripeComponent } from './stripe/stripe.component';
export { StripeNewComponent } from './stripe-new/stripe-new.component';
export { TaxAndFeesAfterCheckoutComponent } from './tax-and-fees-container/tax-and-fees-after-checkout/tax-and-fees-after-checkout.component';
export { SubTotalAfterCheckoutComponent } from './sub-total-container/sub-total-after-checkout/sub-total-after-checkout.component';
export { ShippingOptionsAfterCheckoutComponent } from './shipping-options-container/shipping-options-after-checkout/shipping-options-after-checkout.component';
export { CartTotalAfterCheckoutComponent } from './cart-total-container/cart-total-after-checkout/cart-total-after-checkout.component';
export { ProtectionPlanComponent } from './protection-plan/protection-plan.component';
export { ProtectionPlanNewComponent } from './protection-plan-new/protection-plan-new.component';
export { ThankyouModalComponent } from './thank-you-modal-container/thankyou-modal/thankyou-modal.component';
export { TaxAndFeesAfterCheckoutAltComponent } from './tax-and-fees-container/tax-and-fees-after-checkout-alt/tax-and-fees-after-checkout-alt.component';
export { ShippingOptionsAfterCheckoutAltComponent } from './shipping-options-container/shipping-options-after-checkout-alt/shipping-options-after-checkout-alt.component';
export { CartTotalAfterCheckoutAltComponent } from './cart-total-container/cart-total-after-checkout-alt/cart-total-after-checkout-alt.component';
export { ThankyouModalAltComponent } from './thank-you-modal-container/thankyou-modal-alt/thankyou-modal-alt.component';
export { AddressExtralineComponent } from './address-container/address-extraline/address-extraline.component';
export { BillingExtralineComponent } from './billing/billing-extraline/billing-extraline.component';
