import { Injectable, EventEmitter, Inject } from '@angular/core';
import { SpeedTest } from '../model/speed-test.model';
import { interval } from 'rxjs';
import { CrispService, ENVIRONMENT, IEnvironment } from '@voiply/shared/common-ui';

@Injectable({
  providedIn: 'root'
})
export class SpeedTestService {
  get nativeWindow(): any {
    return window;
  }

  valueChanged: EventEmitter<SpeedTest> = new EventEmitter<SpeedTest>();

  private request;
  constructor(private crispService: CrispService, @Inject(ENVIRONMENT) private environment: IEnvironment) { }

  download: number = 0;
  upload: number = 0;
  ping: number = 0;
  jitter: number = 0;
  ip: string = 'Not Available';
  public InitializeTest() {
    const that = this;
    this.nativeWindow.speedTestWorker.onmessage = function (event) { //when status is received, split the string and put the values in the appropriate fields
      var data = event.data.split(";"); //string format: status;download;upload;ping (speeds are in mbit/s) (status: 0=not started, 1=downloading, 2=uploading, 3=ping, 4=done, 5=aborted)
      if (+data[0] >= 4) {
        that.request.unsubscribe();
        that.valueChanged.emit({
          downloadSpeed: that.download, uploadSpeed: that.upload, ping: that.ping, jitter: that.jitter, ip: that.ip,
          testInProgress: false
        });
        that.crispService.setSessionEvent("speedtest",
          {
            downloadSpeed: that.download, uploadSpeed: that.upload, ping: that.ping, jitter: that.jitter, ip: that.ip,
            result: (that.download > 2 && that.upload > 1 && that.ping <= 50 && that.jitter <= 25) ? 'SUCCESS' : 'FAILURE'
          });
        return;
      }

      that.download = Math.round(+data[1] * 10) / 10;
      that.upload = Math.round(+data[2] * 10) / 10;
      that.ping = Math.round(+data[3]);
      that.ip = data[4];
      that.jitter = Math.round(+data[5]);

      if (that.jitter == NaN) that.jitter = 0;
      const value = {
        downloadSpeed: that.download, uploadSpeed: that.upload, ping: that.ping, jitter: that.jitter, ip: that.ip,
        testInProgress: true
      };

      that.valueChanged.emit(value);
    }
    this.nativeWindow.speedTestWorker.onerror = function (event) {
      console.error("Error in Speedtest", event);
    }
    this.startTest();
  }

  public startTest() {
    if (this.environment.production) {
      this.nativeWindow.speedTestWorker.postMessage(
        'start {"url_dl":"garbage.php","url_ul":"empty.dat","url_ping":"empty.dat","time_dl":"10","time_ul":"15","count_ping":"30"}'); //start the speedtest

      this.request = interval(100).subscribe(() => {
        this.nativeWindow.speedTestWorker.postMessage("status");
      });
    }
  }
}
