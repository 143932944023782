<!-- <div class="row align-items-center">
  <div class="col">
    <span *ngIf="taxDetail && taxDetail.estimatedTotalTax > 0" class="text-secondary font-weight-bold">
      <a (click)="taxBreakupModal.show()" style="cursor: pointer; text-decoration: underline; color: #fe330a">
        Taxes & Fees
      </a>
    </span>
    <span *ngIf="taxDetail && taxDetail.estimatedTotalTax == 0" class="text-secondary font-weight-bold"> Taxes &
      Fees</span>
  </div>
  <div class="col-auto" *ngIf="taxDetail">
    <ng-container *ngIf="calculatingTotal">
      <mat-spinner class="float-right" diameter="26" value="accent"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!calculatingTotal">
      <span class="text-secondary">
        <h4 *ngIf="!payAnnually">
          {{((voipAndServiceTaxTotal || 0)/2) + (hardwareTaxTotal || 0) | formatMoneyValue}}
          {{taxDetail.estimatedTotalTax | formatMoneyValue}}
        </h4>
        <h4 *ngIf="payAnnually">
          {{taxDetail.estimatedTotalTax | formatMoneyValue}}
        </h4>
      </span>
    </ng-container>
  </div>
</div> -->

<div class="d-flex align-items-center">
    <div class="flex-fill">
        <h5 *ngIf="taxDetail && taxDetail.estimatedTotalTax > 0" class="text-black font-weight-bolder mb-0">
      <a (click)="taxBreakupModal.show()" style="cursor: pointer; text-decoration: underline;">
        Taxes & Fees
      </a>
    </h5>
        <h5 *ngIf="taxDetail && taxDetail.estimatedTotalTax == 0" class="text-black font-weight-bolder mb-0">TAXES &
      FEES</h5>
    </div>
    <div *ngIf="taxDetail">
        <ng-container *ngIf="calculatingTotal">
            <mat-spinner class="float-right" diameter="26" value="accent"></mat-spinner>
        </ng-container>
        <ng-container *ngIf="!calculatingTotal">
            <span class="">
        <h5 class="m-0 " *ngIf="!payAnnually">
          (USD){{((voipAndServiceTaxTotal || 0)/2) + (hardwareTaxTotal || 0) | formatMoneyValue}}
          <!-- {{taxDetail.estimatedTotalTax | formatMoneyValue}} -->
        </h5>
        <h5 class="m-0 " *ngIf="payAnnually">
          (USD){{taxDetail.estimatedTotalTax | formatMoneyValue}}
        </h5>
      </span>
        </ng-container>
    </div>

</div>

<!-- Tax Break Modal -->
<div class="modal fade" bsModal #taxBreakupModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Taxes & Fees</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="taxBreakupModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">
                <h5 class="mt-3 d-flex flex-row mx-1" *ngIf="(hardwareTaxes || []).length > 0">
                    <span style="flex-grow: 1">Hardware taxes</span>
                    <span>{{ hardwareTaxTotal | formatMoneyValue}}</span>
                </h5>
                <hr class="m-0" *ngIf="(hardwareTaxes || []).length > 0" />
                <div *ngIf="(hardwareTaxes || []).length > 0">
                    <div class="d-flex flex-row mx-1" *ngFor="let taxItem of hardwareTaxes">
                        <div style="flex-grow: 1">{{taxItem.description}}</div>
                        <div>{{ taxItem.tax_amount | formatMoneyValue}}</div>
                    </div>
                </div>

                <h5 class="mt-4 d-flex flex-row mx-1">
                    <span style="flex-grow: 1">
            <span *ngIf="!payAnnually">Monthly taxes</span>
                    <span *ngIf="payAnnually">Annual taxes</span>
                    </span>
                    <span *ngIf="!payAnnually">{{ (voipAndServiceTaxTotal/2) | formatMoneyValue}}</span>
                    <span *ngIf="payAnnually">{{ voipAndServiceTaxTotal | formatMoneyValue}}</span>
                </h5>
                <hr class="m-0" />
                <div *ngIf="voipAndServiceTaxes">
                    <div class="d-flex flex-row mx-1" *ngFor="let taxItem of voipAndServiceTaxes">
                        <div style="flex-grow: 1">{{taxItem.description}}</div>
                        <div *ngIf="!payAnnually">{{ (taxItem.tax_amount/2) | formatMoneyValue}}</div>
                        <div *ngIf="payAnnually">{{ taxItem.tax_amount | formatMoneyValue}}</div>
                    </div>
                </div>

                <hr class="m-0 mt-4" style="border-top: 3px solid darkgray" />
                <h5 class="my-3 d-flex flex-row mx-1" *ngIf="taxDetail">
                    <span style="flex-grow: 1">Total taxes <span *ngIf="!payAnnually">
              (first and last month included)</span></span>
                    <span>{{ taxDetail.estimatedTotalTax | formatMoneyValue}}</span>
                </h5>
                <hr class="m-0 mb-4" style="border-top: 3px solid darkgray" />
            </div>
        </div>
    </div>
</div>
