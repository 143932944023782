import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CompanyFeaturesService } from '../../services/company-features.service';
import { FormBase } from '@voiply/shared/common-ui';

@Component({
  selector: 'voiply-international-callrates-new',
  templateUrl: './international-callrates-new.component.html',
  styleUrls: ['./international-callrates-new.component.scss']
})
export class InternationalCallratesNewComponent extends FormBase implements OnInit {

  @Input() currentRate: string;
  @Input() country: string;
  @Input() loading: boolean = false;
  @Output()
  getInternationalCallRates = new EventEmitter();

  constructor(private service: CompanyFeaturesService) {
    super();
    console.log(this.currentRate);
    this.form = new FormGroup({
      phone: new FormControl('')
    });
  }
  public formStatusChanged(isValid: boolean) {
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(value => {
      this.getInternationalCallRates.emit(value)
    });
  }
}
