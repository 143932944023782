import { Component, OnInit, Input } from '@angular/core';
import { Customer, User } from '@voiply/shared/model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { AddCodeForLoginAction, RemoveCodeForLoginAction, SaveCallFlowSelectionAction, UnLockOrderAction } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';
import { CrispService, SidenavService } from 'libs/shared/common-ui/src/lib/services';
import { AuthService } from '@voiply/auth0';
import uuid from 'uuid';
import _ from 'lodash';

@Component({
  selector: 'voiply-title-bar-after-checkout',
  templateUrl: './title-bar-after-checkout.component.html',
  styleUrls: ['./title-bar-after-checkout.component.css']
})
export class TitleBarAfterCheckoutComponent implements OnInit {

  @Input() isUserVoiplyTeamMember: boolean;
  @Input() title: string;
  @Input() buttonText: string;
  @Input() customer: Customer;
  @Input() orderId = '';
  @Input() codeForLogin = "";
  unlockingOrder = false;
  loginLink = "";
  copyLinkButton = false;

  @Dispatch() saveCallFlowSelection = (callFlowSelection: any) => new SaveCallFlowSelectionAction(callFlowSelection);
  @Dispatch() unLockOrderAction = () => new UnLockOrderAction();
  @Dispatch() addCodeForLogin = (codeForLogin) => new AddCodeForLoginAction(codeForLogin);
  @Dispatch() removeCodeForLogin = () => new RemoveCodeForLoginAction();

  constructor(private toastr: ToastrService, public sidenav: SidenavService, private authService: AuthService, private crispService: CrispService) { }

  ngOnInit() {
  }


  clearCallFlow() {
    const changes = { callFlow: null };
    this.saveCallFlowSelection(changes);
    this.toastr.success(`Order callflow reset to none.`);
    setTimeout(() => {
      location.reload();
    }, 2000);
  }

  async unlockOrder() {
    this.unlockingOrder = true;
    // This condition is for older customer whose order was already unlocked but codeForLogin was not generated for them. 
    // So we unlock the order again to generate a loginLink for that user
    if (!(this.customer || {}).auth0Id)
      this.processUnlock();
    else
      await this.authService.deleteUser(this.customer.auth0Id).then(() => {
        this.processUnlock();
      }).catch((err) => {
        this.toastr.error(err?.error?.message || "Something Went Wrong Please Try Again");
      })

  }
  processUnlock() {
    this.unLockOrderAction();
    this.codeForLogin = uuid();
    this.addCodeForLogin(this.codeForLogin);
    this.loginLink = `${location.origin}/${this.orderId}?code=${this.codeForLogin}`
    this.crispService.setUserEvent("order:unlock", [{ "email": this.customer.email, "data": { "unlocklink": this.loginLink } }]).subscribe();
    this.toastr.success(`Order Unlocked. Login link is sent to user lock it again.`);
    this.copyLinkButton = true;
    this.unlockingOrder = false;
  }

  copyLink() {
    const val = `${location.origin}/${this.orderId}?code=${this.codeForLogin}`
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(`Copied to Clipboard`);
  }

  onLogout() {
    this.authService.logout();
  }
}
