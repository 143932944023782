import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { App, CartItemType } from '@voiply/shared/model';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { APPSCONST, OnDestroyCleanup } from '@voiply/shared/common-ui';
import { ShowAppDetailsModalComponent } from '../show-app-details-modal/show-app-details-modal.component';
import { take } from 'rxjs/operators';

import * as _ from 'lodash';
import { __values } from 'tslib';
@Component({
  selector: 'voiply-app-new',
  templateUrl: './app-new.component.html',
  styleUrls: ['./app-new.component.scss']
})
export class AppNewComponent extends OnDestroyCleanup implements OnInit {
  @Input()
  apps$: Observable<any>;
  @Input()
  selectedCountry = 'US';
  @Input()
  cartItems;
  @Output()
  addToCart = new EventEmitter<App>();
  @Output()
  removeCartItem = new EventEmitter<string>();
  @Output()
  showWarning = new EventEmitter();

  modalRef: BsModalRef;
  showapps = 4;
  apps: App[]
  // @ViewChild('appModal', { static: true }) appModal: ModalDirective;
  selectedAppsInCart={ //Here key value is based on appKey
    1:{
      "heading": APPSCONST.ONLINEFAX,
      "isSelected": false,
      "off": "50% OFF"
    },
    3:{
      "heading": APPSCONST.SPAMCALLBOT,
      "isSelected": false,
      "off": "FREE"
    },
    5:{
      "heading": APPSCONST.EMERGENCY,
      "isSelected": false,
      "off": "50% OFF"
    },
    6:{
      "heading":APPSCONST.WEBROOT,
      "isSelected":false,
      "off": "50% OFF"
    },
    7:{
      "heading":APPSCONST.IDENTITYTHEFTPRORECTION,
      "isSelected":false,
      "off": "50% OFF"
    },
    8:{
      "heading":APPSCONST.BUREAUCREDITMONITORING,
      "isSelected":false,
      "off": "75% OFF"
    }
  }

  constructor(private modalService: BsModalService) {
    super()
  }

  ngOnInit() {
    this.subscriptions$.add(this.apps$.subscribe((apps) => {
        this.apps = _.filter(apps, (app) => app.isActive);
        this.arrangeAppOrder();
      })
    );
    this.findAppInCartItems();
  }

  arrangeAppOrder(){
    this.apps.sort( (d1,d2)=> d1.placingPriority-d2.placingPriority);
  }

  onAddToCart(app: App) {
    if(this.selectedCountry!=='US' && this.selectedCountry!=='CA'){
      this.showWarning.emit();
      return false;
    }
    this.addToCart.emit(app);
  }
  onOpenModalDialog(index: number) {
    //if(this.inCart(this.apps[index])) return;
    const initialState = {
      app: this.apps[index],
      isAppInCart: this.selectedAppsInCart[this.apps[index].appId].isSelected
    };
    this.modalRef = this.modalService.show(ShowAppDetailsModalComponent, { initialState, class: 'modal-xl' });
    this.modalRef.content.addToCart.pipe(take(1)).subscribe((app) => {
      //this.onAddToCart(app)
      this.clickedToggleAppBtn(app);
    });

  }
  showMoreApps() {
    this.showapps = this.showapps + 4;
  }
  appAvailableInCountry(app) {
    if (app.onlyAvailableInCountry.length) {
      if (_.includes(app.onlyAvailableInCountry, this.selectedCountry)) {
        return true
      } else return false
    } else return true
  }

  onRemoveCartItem(app) {
    _.each(this.cartItems, (value, keys) => {
      if(value.type === CartItemType.App) {
        if(value.heading == app.title) {
          this.removeCartItem.emit(keys);
        }
      }
    })
  }

  inCart(app) {
    let returnVar: boolean = false;
    _.each(this.cartItems, (value, keys) => {
      if(value.type === CartItemType.App) {
        if(value.heading == app.title) {
          returnVar = true;
        }
      }
    });
    return returnVar;
  }

  findHeight() {
    let el = document.getElementById(this.apps[0].videoId)
    if(el) {
      return el.clientHeight;
    }
  }

  clickedToggleAppBtn(app: App){
    if(!this.selectedAppsInCart[app.appId].isSelected){
      if(this.onAddToCart(app)==false)  return false;
      this.selectedAppsInCart[app.appId].isSelected=true;
    }
    else{
      this.onRemoveCartItem(app);
      this.selectedAppsInCart[app.appId].isSelected=false;
    }
  }

  findAppInCartItems(){
    for(let key of Object.keys(this.selectedAppsInCart))
      this.selectedAppsInCart[key].isSelected=false;
    
    for(let key of Object.keys(this.cartItems)){
      if(this.cartItems[key].type=="app"){
        this.selectedAppsInCart[this.cartItems[key].itemId].isSelected=true;
      }
    }
  }

}
