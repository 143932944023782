import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input } from '@angular/core';
import { CheckoutDetail } from '@voiply/shared/model';
import { CartTotalBase } from '../cart-total-base';

@Component({
  selector: 'checkout-cart-total-new',
  templateUrl: './cart-total-new.component.html',
  styleUrls: ['./cart-total-new.component.css'],
  animations: [
    trigger('slideInOut', [
      state('0', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      state('1', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      transition('1 => 0', animate('400ms')),
      transition('0 => 1', animate('400ms'))
    ]),
  ]
})
export class CartTotalNewComponent extends CartTotalBase {
  showSavings: string = '0';
  savingsHovered: HTMLElement;

  toggleAccordian() {
    this.showSavings === '0' ? this.showSavings = '1' : this.showSavings = '0';
  }

  getTotalSaving() {
    let totalSaving = 0;
    for(let savingItem of Object.keys(this.checkoutDetail.savings || {})) {
      totalSaving += this.checkoutDetail.savings[savingItem];
    }
    return totalSaving;
  }
}
