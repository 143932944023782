<div class="bsModal">
  <div class="modal-header">
    <div class="d-flex flex-row align-items-center">
      <img style="max-width: 45px; max-height: 45px;" [src]="app.image" class="app-image-color">
      <h4 class="flex-grow-1" class="modal-title text-primary pl-3">{{app.title}}</h4>
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="app.appId==6">
      <div class="float-video">
        <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/651601780?h=a4e12fb0d7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Emergency 999.mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Real-time protection for up to 3 PC, Mac®, Chromebook™ Android™ and IOS devices.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Cloud based Webroot Internet Security Plus with Antivirus proactively shields households from the latest malware, phishing scams and other cyberattacks all without slowing down your computer.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Webroot continuously analyzes and classifies billions of apps, files, websites and more so you bank, shop, and browse online worry-free. Our leading rollback technology automatically returns infected devices to their original state.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Spam Call Bot -->
    <div *ngIf="app.appId==3">
      <!-- <div class="float-video bg-light-grey">
        <span class="" style="margin: auto;">Video</span>
      </div> -->
      <div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Works with Nomorobo to block unsoliccited robocalls or telemarketers and automatically transfers them to our bot Harld. Talk with Harold by calling (646) 779-4777.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">When SMS screening is enabled, spam texts will be filtered out so you can stay focused.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Anonymous Call Rejection automatically rejects calls from numbers with Caller ID blocked.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Add numbers to block list to reduce unwanted calls further.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Enable Alow List Only to block all but authorized callers.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Online Fax -->
    <div *ngIf="app.appId==1">
      <div class="float-video">
        <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/571642032?h=03531cfbf1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Unlimited Faxing for $5-mo.mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Send faxes online without a fax machine. Open your inbound faxes right in your email inbox and read them on your desktop or mobile device—no more bulky fax machines.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">One of the biggest benefits of having a virtual fax service is, of course, the convenience. Send and receive faxes from anywhere you have email access.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Time and money aren’t the only things you’re saving with virtual faxes. You’re also saving the planet since you’re using less paper and less ink.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Emergency 999  -->
    <div *ngIf="app.appId==5">
      <div class="float-video">
        <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/571624301?h=56f006b944&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Emergency 999.mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Voiply Emergency 999 is an alert service that puts you in touch with a dispatcher who can summon emergency help and contact a friend or family member while offering immediate support.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Add your family members and emergency contacts into the system and they will receive calls and SMS messages with details of your emergency.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">During an emergency the dispatchers will send your data you to the proper local operators to get you the right help as fast as possible.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="app.appId==7">
      <!-- <div class="float-video bg-light-grey">
        <span class="" style="margin: auto;">Video</span>
      </div> -->
      <div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">If you become a victim of identity theft, one of our U.S. based agents will work to fix it. Plan includes Million Dollar Protection Package (AIG).</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Identity & Social Security Numbers Alerts (Bank/Debit/Credit Card Monitoring). If we detect a potential threat to your identity, we alert you by text, email, phone.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Help keep ID thieves from moving in on your biggest asset with Home Title Monitoring.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Drivers License/Medical/Data Breach/Dark Web Notifications - We search across multiple data breaches to see if your email address or phone number has been compromised.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">We will notify you by email/text message as soon as we detect that a registered sex offender moves near you.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="app.appId==8">
      <!-- <div class="float-video bg-light-grey">
        <span class="" style="margin: auto;">Video</span>
      </div> -->
      <div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Equifax, Experian and TransUnion are the three main consumer credit bureaus. They collect and store information about you that they use to generate your credit reports, which are used as the basis of your credit scores.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Credit reports may affect your mortgage rates, credit card approvals, apartment requests, or even your job application. Reviewing credit reports helps you catch signs of identity theft early.</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">If you become a victim of identity theft, one of our U.S. based agents will work to fix it. Plan includes Million Dollar Protection Package (AIG).</p>
            </li>
          </ul>
        </div>        
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">Get a free full copy of your credit report and score every month from Transunion. (Checking your credit will not hurt your score)</p>
            </li>
          </ul>
        </div>
        <div class="d-inline-flex divWidthClass p-2">
          <ul class="" style="list-style: square;color: red;">
            <li>
              <p class="h5 text-black" style="text-align: justify;">We monitor Experian, TransUnion, and Equifax for any changes to your reports and alert you via email and SMS.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div>{{app.description}}</div> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark px-4 py-2 borderRadiusClass" data-dismiss="modal"
      (click)="this.bsModalRef.hide()">No Thanks</button>
    <button *ngIf="!isAppInCart" type="button" class="btn bg-primary text-primary px-4 py-2 borderRadiusClass" (click)="this.addToCart.emit(app);
        this.bsModalRef.hide();">
        Activate
    </button>
    <button *ngIf="isAppInCart" type="button" class="btn bg-primary text-primary px-4 py-2 borderRadiusClass" (click)="this.addToCart.emit(app);
        this.bsModalRef.hide();">
        Deactivate
    </button>
  </div>
</div>
