<div class="">
    <div class="d-flex flex-column">
        <div>
            <h3>
                Do you want to a Business Number or a New Phone Number?
            </h3>
        </div>

        <div class="flex-fill w-100">
            <div class="btn btn-group btn-sm d-flex flex-column flex-md-row m-0 p-2" role="group"
                aria-label="Basic example">
                <button id="1" type="button"
                    class="btn border d-flex flex-row justify-content-between align-items-center p-3"
                    [ngClass]="toggle? 'open_color' :'nopen_color'" (click)="onClick('1')">
                    <div class="dot p-2 m-1 mr-3 border">
                        <div [ngClass]="toggle? 'dot-2-1' :'dot-2-2'"></div>
                    </div>
                    <div class="flex-grow-1">KEEP YOUR NUMBER</div>
                </button>
                <button id="2" type="button"
                    class="btn border d-flex flex-row justify-content-between align-items-center p-3"
                    [ngClass]="toggle? 'nopen_color' :'open_color'" (click)="onClick('2')">
                    <div class="dot p-2  m-1 mr-3 border">
                        <div [ngClass]="toggle? 'dot-2-2' :'dot-2-1'"></div>
                    </div>
                    <div class="flex-grow-1">NEW PHONE NUMBER</div>
                </button>
            </div>
        </div>
    </div>

    <div>
        <div *ngIf="toggle==true">
            <div class="m-2 rounded">
                <!-- <voiply-existing-number-business (update)="onUpdate($event)" (updateNumber)="this.primaryPhone.number = $event; this.setIsNewStatus(); this.savePrimaryNumber();" [selectedNumber]="primaryPhone.number" [tollFreeNumberNotAllowed]="tollFreeNumberNotAllowed"
                    [isNew]="primaryPhone.isNew" [isValid]="primaryPhone.isValid">
                </voiply-existing-number-business> -->
                <!-- <input type="text" [disabled]="(isValid)" [ngClass]="{ 'is-invalid': ((!isValid && submitted) || (primaryNumber.touched && !isValid)) , 'form-control h-100 primary-number-input':true}" name="primaryNumber" #primaryNumber="ngModel" mask="(000) 000-0000"
                placeHolder="Type Your Company Number" [(ngModel)]="primaryPhone.number" (keyup)="setIsNewStatus($event)"> -->

                <voiply-existing-number-business (update)="onUpdate($event)" 
                    [selectedNumber]="primaryPhone.number" 
                    (updateNumber)="this.primaryPhone.number = $event; this.setIsNewStatus($event); this.savePrimaryNumber();"
                    [canAddCompanyNumbers]="canAddCompanyNumbers" 
                    [isNew]="primaryPhone.isNew" 
                    [tollFreeNumberNotAllowed]="tollFreeNumberNotAllowed" 
                    [isValid]="primaryPhone.isValid">
                </voiply-existing-number-business>
               
            </div>
        </div>

        <div *ngIf="toggle==false">
            <div class="m-2 rounded">
                <voiply-new-number-business (numberSelected)="onNumberSelected($event)"
                    (internationalNumberSelected)="onInternationalNumberSelected($event)" [isNew]="primaryPhone.isNew"
                    [canAddCompanyNumbers]="canAddCompanyNumbers"
                    (changedPrimaryNumber)="onUpdatePrimaryNumberStatus($event,true)"
                    [primaryPhoneInfo]="primaryPhone"
                    >
                </voiply-new-number-business>
            </div>
        </div>
    </div>

</div>