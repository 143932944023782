import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonUiModule } from '@voiply/shared/common-ui';

import { CONTAINERS } from './containers';
import { COMPONENTS } from './components';


@NgModule({
  imports: [
    CommonModule,
    CommonUiModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...CONTAINERS
  ],
  exports: [
    ...COMPONENTS,
    ...CONTAINERS
  ]
})
export class CompanyFeaturesModule { }