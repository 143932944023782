import { Component, OnInit } from '@angular/core';
import { ProductConfigurationBase } from '../product-configuration.base';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CartItemType } from '@voiply/shared/model';
import * as _ from 'lodash';

@Component({
  selector: 'voiply-product-configuration-home-after-checkout',
  templateUrl: './product-configuration-home-after-checkout.component.html',
  styleUrls: ['./../product-configuration.base.scss']
})
export class ProductConfigurationHomeAfterCheckoutComponent extends ProductConfigurationBase {
  constructor(toastr: ToastrService, modalService: BsModalService) {
    super(toastr, modalService);
  }
  addAndUpdateProductConfiguration() {
    this.productConfigurations$.subscribe((data) => {
      this._productconfigutrations = _.pickBy(data, (value, key) => value.paid || value.type === CartItemType.App);
      this._productConfigurationLength = Object.keys(this._productconfigutrations).length;
      // loop through all cart items
      _.each(this._productconfigutrations, (value, key) => {
        if (value.type === CartItemType.Phone)
          this.phonesconfiguration[key] = value;
        else if (value.type === CartItemType.App)
          this.appsconfiguration[key] = value;
      });
      this._phonesconfigurationLength = Object.keys(this.phonesconfiguration).length;
      this._appsconfigurationLength = Object.keys(this.appsconfiguration).length;
    });
  }
}
