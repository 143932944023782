<div id="header" [style.marginTop.px]="headerHeight">
<div class=" my-3 card py-5 px-lg-4" style="border-radius: 0% !important;">
    <div class="card-header bg-white border-0">
        <!-- * title -->
        <div class="d-flex justify-content-between align-items-center">
            <div class="text-capitalize heading-font" id="survey-header">
                <span class="h1 font-weight-bolder d-none d-lg-block">Top Rated Residential Phone Service</span>
                <span class="h2 font-weight-bolder d-block d-lg-none">Why Voiply?</span>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <!-- * banner (when passing 'banner' query parameter) the appropriate image will show up -->
                <div class="pr-2 position-relative" *ngIf="selectedBanner.name">
                    <div>
                        <!-- class="position-absolute" style="top: -56px; right: 12px" -->
                        <img src="assets/images/banners/{{selectedBanner.name}}-desktop.png" alt="" class="d-none d-lg-block" style="height: 100px">
                        <img src="assets/images/banners/{{selectedBanner.name}}-mobile.png" alt="" class="d-block d-lg-none m-auto" style="height: 100px">
                    </div>
                </div>
                <!-- * rating stars -->
                <div class="d-flex flex-column align-items-end">
                    <div class="d-none d-sm-block" style="z-index:1;position:relative;left:+36px;">
                        <span *ngFor="let star of [1, 2, 3, 4]">
                    <i class="fas fa-star fa-2x" style="color: #F8C600"></i>
                  </span>
                    <span>
                        <i class="fas fa-star-half fa-2x" style="color: #F8C600;"></i>
                        <i class="fas fa-star-half fa-2x" style="color: #989898;-webkit-transform: scaleX(-1);transform: scaleX(-1);z-index:1;position:relative;left:-37px;"></i>
                    </span>
                    </div>
                    
                    <div class="d-block d-sm-none" style="z-index:1;position:relative;left:+18px;">
                        <span *ngFor="let star of [1, 2, 3, 4]">
                  <i class="fas fa-star" style="color: #F8C600"></i>
                  
                </span>
                        <span>
                  <i class="fas fa-star-half" style="color: #F8C600"></i>
                  <i class="fas fa-star-half " style="color: #989898;-webkit-transform: scaleX(-1);transform: scaleX(-1);z-index:1;position:relative;left:-18px;"></i>
                </span>
                    </div>
                    <div class="mt-1 text-right text-sm-center">
                        Based on <span class="font-weight-bolder"> 1,928 Reviews</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * body -->
    <div class="card-body">
        <!-- * description about voiply  -->
        <div class="d-flex flex-column-reverse flex-lg-row pb-3 align-items-center" style="border-bottom: 1px solid #ececec;">
            <div class="column-width">
                <div class="pr-lg-3 pt-3 pt-lg-none">
                    <div class="font-weight-bold h5" style="line-height: 1.75rem;">
                        Homeowners are unbundling their land lines and switching to VoIP as high speed internet becomes more affordable.
                    </div>
                    <div class="mt-3 h6" style="line-height: 1.5rem;">
                        You can keep your number and get all the great features you need, like caller-ID, call waiting, spam protection, 911, and more. The only difference is, your service is connected via high-speed internet and the Voiply Adapter.
                        <span class=" font-weight-bold"><i>Average household saves $380 per year.</i></span>
                    </div>
                    <!-- * voiply adapter package contents -->
                    <div class="mt-3 d-flex flex-column">
                        <span class="font-weight-lighter">
                            <i>Adapter Package Includes:</i>
                          </span>
                        <div class="d-flex justify-content-start flex-wrap flex-column flex-sm-row">
                            <div class="rounded-lg border mr-xl-4 mr-sm-2 my-1">
                                <div class="flex-fill text-right pr-1" style="font-size: xx-small;">1x</div>
                                <div class="d-flex px-2 pb-2">
                                    <img class="px-2" width="50px" height="50px" style="object-fit: contain;" src="assets/images/survey-header/product-1.png">
                                    <span class="package-item-text">Voiply <br> Adapter</span>
                                </div>
                            </div>
                            <div class="rounded-lg border mr-xl-4 mr-sm-2 my-1">
                                <div class="flex-fill text-right pr-1" style="font-size: xx-small;">1x</div>
                                <div class="d-flex px-2 pb-2">
                                    <img class="" width="50px" height="50px" style="object-fit: contain;" src="assets/images/survey-header/product-2.png">
                                    <span class="package-item-text">Ethernet <br>
                                        Cable</span>
                                </div>
                            </div>
                            <div class="rounded-lg border my-1 pr-lg-2 pr-xl-0">
                                <div class="flex-fill text-right pr-1" style="font-size: xx-small;">1x</div>
                                <div class="d-flex px-2 pb-2">
                                    <img class="" width="50px" height="50px" style="object-fit: contain;" src="assets/images/survey-header/product-3.png">
                                    <span class="package-item-text">Power <br>
                                      Supply</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- * survey header video -->
            <div class="column-width">
                <div *ngIf="systemFeatures">
                    <!-- <div class="rounded-lg" style="overflow: hidden;">
                        <voiply-wistia-player [videoId]=systemFeatures?.backgroundVideoId></voiply-wistia-player>
                    </div> -->
                    <div style="padding:56.25% 0 0 0;position:relative;">
                        <iframe src="https://player.vimeo.com/video/566129021?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;portrait=0&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Residential Online Checkout Intro.mp4"></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <!-- * steps to use voiply -->
            <div class="pt-5 mx-xl-3 mx-lg-4 mx-3">
                <img class="img-fluid" src="assets/images/survey-header/steps.png">
            </div>
            <div class="d-flex justify-content-between mt-3 text-center">
                <div class="d-flex flex-column align-items-center">
                    <div class="text-secondary font-weight-bolder text-uppercase">
                        STEP 1
                    </div>
                    <!-- ? I've used 2 size of fonts bigger one for big screen and smaller one for smaller screen -->
                    <div class="d-none d-lg-block">
                        Order Voiply Service<br> Online
                    </div>
                    <div class="d-block d-lg-none" style="font-size: smaller;">
                        Order Voiply Service<br> Online
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <i class="fas fa-caret-right fa-2x text-muted"></i>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <div class="text-secondary font-weight-bolder text-uppercase">
                        STEP 2
                    </div>
                    <div class="d-none d-lg-block">
                        Plug internet router<br> into Voiply Adapter
                    </div>
                    <div class="d-block d-lg-none" style="font-size: smaller;">
                        Plug internet router<br> into Voiply Adapter
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <i class="fas fa-caret-right fa-2x text-muted"></i>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <div class="text-secondary font-weight-bolder text-uppercase">
                        STEP 3
                    </div>
                    <div class="d-none d-lg-block">
                        Plug your home phone <br>into Voiply Adapter
                    </div>
                    <div class="d-block d-lg-none" style="font-size: smaller;">
                        Plug your home phone <br>into Voiply Adapter
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
