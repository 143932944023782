import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@voiply/shared/common-ui';

@Injectable({
  providedIn: 'root'
})
export class PrimaryNumberService {


  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) { }

  getNumberPortabilityStatus(number: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.environment["Ocp-Apim-Subscription-Key"]
      })
    };
    return this.http.get<any>(`${this.environment.voiplyPhonePortabilityCheckAPI}?number=${number}`, options)
      .pipe(catchError(this.handleError));
  }
  
  private handleError(error: Response | any) {
    console.error('ERROR HTTP Call', error);
    return throwError(error.status)
  }
}
