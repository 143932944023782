<div class="d-flex flex-column align-items-stretch">
    <div class="d-flex flex-column justify-content-between  bg-grey rounded ">
        <div class="d-flex flex-row justify-content-between align-items-center bg-grey rounded px-3 py-2">
            <button class="btn p-0 d-flex flex-grow-1 justify-content-between align-items-center" (click)="protectionPlanModal.show()">
              <div class="d-flex flex-column text-left">
                <div class="font-weight-bold text-secondary">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="24" viewBox="0 0 18 24">
                        <defs>
                          <linearGradient id="linear-gradient" x2="1" y2="0.929" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#fb522c"/>
                            <stop offset="1" stop-color="#d22700"/>
                          </linearGradient>
                        </defs>
                        <path id="iconmonstr-shield-20" d="M12,4.942a13.266,13.266,0,0,0,5,1.833v7.76c0,1.606-.415,1.935-5,4.76V4.942ZM21,3V14.535c0,4.6-3.2,5.8-9,9.465-5.8-3.661-9-4.862-9-9.465V3c3.516,0,5.629-.134,9-3C15.371,2.866,17.484,3,21,3ZM19,4.96a11.886,11.886,0,0,1-7-2.416A11.886,11.886,0,0,1,5,4.96v9.575c0,3.042,1.69,3.83,7,7.107,5.313-3.281,7-4.065,7-7.107Z" transform="translate(-3)" fill="url(#linear-gradient)"/>
                      </svg>
                      
                    Protection Plan ({{protectionPlanMonthlyCharge | formatMoneyValue}}/mo)
                    <hr class="p-0 ml-0 mr-0 mt-2 mb-0">
                </div>
              </div>
            </button>
            <div class="custom-checkbox d-flex">
                <label class="checkbox-container text-primary m-0 font-weight-bold" style="height: 20px;">
            <input type="checkbox" [checked]="hasProtectionPlan" #ProtectionPlanCheckBox
              (change)="this.protectionPlanChanged.emit(ProtectionPlanCheckBox.checked)">
            <span class="checkmark" style="border: 1px solid black;"></span>
            
          </label>
            </div>
            
        </div>
        <div class="d-flex align-items-center px-3 pt-1 pb-3">
            <span style="font-size:small;color:gray">
                Hassle free exchange program ensures that your device is covered in case of accidental damage or issue needing repair. 
            </span>
        </div>
        
    </div>
    
    <!-- <div class="text-muted font-weight-light px-3 text-center" style="font-size: small;">Our most comprehensive plan covers all equipment in your purchase</div> -->
</div>


<div class="modal fade" bsModal #protectionPlanModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header d-flex justify-content-between align-items-center" style="background-color: #fe330a; color: #ffffff;">
                <div class="d-flex flex-row align-items-center">
                    <img src="assets/images/shield.svg" style="width: 22px;" class="icon-white mr-3" alt="">
                    <h3 class=" m-0">
                        <span class="font-weight-bold">Voiply Premium </span>Protection Plan
                    </h3>
                </div>
                <button type="button" class=" btn pull-right" (click)="protectionPlanModal.hide()">
          <i class="fas fa-times-circle fa-lg text-primary"></i>
        </button>

            </div>
            <div class="modal-body p-4">

                <div style="background-color: #FAFAFA;" class="p-4">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <ul class="fa-ul">
                                <li><span class="fa-li d-flex justify-content-center" style="top:8px"><i
                      class="fas fa-square text-secondary" style="font-size: 8px;"></i></span>
                                    <span class="font-weight-bold"> No
                    deductibles or added costs.</span> Parts, labor and shipping included.</li>
                                <li class="mt-4"><span class="fa-li d-flex justify-content-center" style="top:8px"><i
                      class="fas fa-square text-secondary" style="font-size: 8px;"></i></span>
                                    <span class="font-weight-bold">
                    Includes 24/7 tech support setup, </span> connectivity issues, troubleshooting, and much more.</li>
                                <li class="mt-4"><span class="fa-li d-flex justify-content-center" style="top:8px"><i
                      class="fas fa-square text-secondary" style="font-size: 8px;"></i></span> Protection plan documents will be delivered via email within <span class="font-weight-bold"> 24
                    hours of purchase.</span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-sm-6">
                            <ul class="fa-ul">
                                <li class="mt-4 mt-sm-0"><span class="fa-li d-flex justify-content-center" style="top:8px"><i
                      class="fas fa-square text-secondary" style="font-size: 8px;"></i></span>
                                    <span> Power surge covered </span>from day one. Other breakdowns covered after the manufacturer's warranty expires.
                                </li>
                                <li class="mt-4"><span class="fa-li d-flex justify-content-center" style="top:8px"><i
                      class="fas fa-square text-secondary" style="font-size: 8px;"></i></span>
                                    <span> File a claim online or by phone
                    24/7. </span>If we can't repair it, we'll replace it or reimburse the purchase price with an Amazon e-gift card.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-flex  align-items-center flex-wrap justify-content-end">
                <div class="d-flex justify-content-end flex-grow-1">
                    <button type="button" class="btn btn-outline-dark px-4 py-2 mr-3" data-dismiss="modal" (click)="protectionPlanModal.hide()">No Thanks</button>
                    <button type="button" class="btn bg-primary-gradient text-primary px-4 py-2" (click)="onProtectionPlanAdded();protectionPlanModal.hide()">
            Add
          </button>
                </div>
            </div>
        </div>
    </div>
</div>