import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'voiply-footer-top-home-after-checkout',
  templateUrl: './footer-top-home-after-checkout.component.html',
  styleUrls: ['./footer-top-home-after-checkout.component.scss']
})
export class FooterTopHomeAfterCheckoutComponent implements OnInit {

  imglogo="/assets/images/voiplyBusinessFooterIcon.png";
  constructor() { }

  ngOnInit() {
  }

  navigatePage() {
    window.location.href = "https://build.voiply.com";
  }
}
