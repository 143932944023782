import { Component, Input, OnInit } from '@angular/core';
import { NgxSiemaOptions, NgxSiemaService } from 'ngx-siema';
import { Testimonial } from '../../model/testimonial.model';
@Component({
  selector: 'voiply-testimonials-new',
  templateUrl: './testimonials-new.component.html',
  styleUrls: ['./testimonials-new.component.scss']
})
export class TestimonialsNewComponent implements OnInit {
  @Input()
  testimonials: Testimonial[] = [];

  constructor(private ngxSiemaService: NgxSiemaService) {
  }

  options: NgxSiemaOptions = {
    selector: '.siema',
    duration: 200,
    easing: 'ease-out',
    perPage: {
      540: 1,
      768: 2, // 2 items for viewport wider than 800px
      1200: 3
    },
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: true
  };
  ngOnInit() {
    //this.autoScroll();
  }

  next() {
    this.ngxSiemaService.next(1, '.siema');
  }

  autoScroll() {
    setInterval(() => {
      this.ngxSiemaService.next(1);
    }, 7000);
  }

  prev() {
    // Use the prev function only for ngx-siema instance with selector '.siema'
    this.ngxSiemaService.prev(1, '.siema');
  }
}
