<div id="head-bar" class="w-100 fixed-header">
    <div class="w-100 shadow" style="background-color: #f5f5f5;">
        <div class="container-xl">
            <voiply-header-bar-checkout *ngIf="showCheckoutHeader" [phone]="phone" [chips]="chips" [price]="price"
                [country]="(billingAddress$|async).country" (payNow)="payNow()"></voiply-header-bar-checkout>
        </div>
    </div>
    <div class="bg-white w-100 shadow">
        <div class="container-xl">
            <voiply-navigation-bar-home helpNumber="8444864759" logo="logo.png" [systemFeatures]="systemFeatures">
            </voiply-navigation-bar-home>
        </div>
    </div>
    <div class="container-xl shadow">
        <!-- <voiply-header-title-bar-home [title]="title"></voiply-header-title-bar-home> -->
    </div>
</div>
