<div class="faqScroll d-flex mt-3 card py-5 px-lg-4" style="border-radius: 0% !important; border: 0px solid #ececec;" id="faq">
    <div style="position: relative;">
        <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block" style="position: absolute; left: -38px; top: 0px; font-size: 60px; color:#D3D4D5"></i>
    </div>
    <div class="card-header bg-white border-0">
        <div class="h2 font-weight-bolder heading-font">Frequently Asked Questions</div>
    </div>
    <div class="card-body">
        <div class="row">
            <div *ngFor="let faq of faqs" class="col-lg-6 p-3">
                <voiply-faq-new-item [faq]="faq"></voiply-faq-new-item>
            </div>
        </div>
    </div>
</div>
