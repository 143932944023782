// ANGULAR and Other Node Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonUiModule } from '@voiply/shared/common-ui';

// Current Module elements
import { COMPONENTS } from './components';
import { CONTAINERS } from './container';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommonUiModule
  ],
  declarations: [
    ...CONTAINERS, 
    ...COMPONENTS
  ],
  exports: [
    ...CONTAINERS
  ]
})
export class HeaderSurveyModule { }
