import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'voiply-footer-top-new',
  templateUrl: './footer-top-new.component.html',
  styleUrls: ['./footer-top-new.component.scss']
})
export class FooterTopNewComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;
  @Input() features: string[];
  @Input() imglogo: string;
  @Input() navigateButtonText: string;
  @Output() navigatePage = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
