<div style="position: fixed;top: 0; left:0;right:0;bottom:0;width: 100%;height: 100%;z-index: 1030;">
    <!-- VOIPLY SPINNER -->
    <div class="center-ring">
        <div class="loadingio-spinner-spinner-g3slsh2f5fj d-flex justify-content-center" style="margin:auto">
            <div class="ldio-y54fvyll1pl d-flex justify-content-center align-items-center">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <h3 class="text-secondary" style="position: absolute; bottom:-35px;
        width: 248px;
        left: calc(50% - 124px);
    ">{{loadingText}}</h3>
    </div>
    <!-- VOIPLY SPINNER END -->
</div>
<div
    style="position: fixed;top: 0; left:0;right:0;bottom:0;width: 100%;height: 100%;z-index: 1020;background-color: #e1e1e1;opacity: 0.9;">
</div>