import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TaxDetail } from '@voiply/shared/model';
import * as _ from 'lodash';
import { TaxAndFeesBase } from '../tax-and-fees-base';

@Component({
  selector: 'checkout-tax-and-fees-new',
  templateUrl: './tax-and-fees-new.component.html',
  styleUrls: ['./tax-and-fees-new.component.scss']
})
export class TaxAndFeesNewComponent extends TaxAndFeesBase {

}
