<div class="bg-light-grey rounded-lg p-4">
    <div class="d-flex flex-row align-items-center justify-content-between py-2 cursor-pointer" (click)="toggleFaqShow()">
        <div>
            <div class="d-flex flex-row align-items-center">
                <div class="m-0 pr-2 h5 font-weight-bolder" [ngClass]="{'text-black':faqShow==='out','text-dark':faqShow==='in'}">{{ faq.title }}</div>
            </div>
        </div>
        <div>
            <svg [@childAnimation1]="faqShow" xmlns="http://www.w3.org/2000/svg" width="23.333" height="23.333" viewBox="0 0 23.333 23.333">
            <path id="Select_Dropdown" data-name="Select Dropdown" d="M11.257.345c.8-.8,2.342-.129,2.583,1.113L16.47,15A1.206,1.206,0,0,1,15,16.47L1.458,13.84C.216,13.6-.45,12.053.345,11.257Z" transform="translate(11.667) rotate(45)" fill="#f44b25"/>
          </svg>
            <!-- <i class="fas fa-caret-up text-secondary fa-2x" [@childAnimation1]="faqShow"> </i> -->
        </div>
    </div>
    <div [@slideInOut]="faqShow">
        <p class="mb-0 text-muted h6">{{ faq.content }}</p>
    </div>
</div>
