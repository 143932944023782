import { Component, OnInit, Input } from '@angular/core';
import { Providers } from '../../model/speed-test.model';
import { SelectInternetProviderAction } from '@voiply/shared/common-ui';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Component({
  selector: 'voiply-internet-providers',
  templateUrl: './internet-providers.component.html',
  styleUrls: ['./internet-providers.component.css']
})
export class InternetProvidersComponent implements OnInit {

  @Input()
  providers: Providers[];

  @Input() selectedInternetProvider : {};

  @Dispatch() selectInternetProviderAction = (selectInternetProvider: string) => new SelectInternetProviderAction(selectInternetProvider);
   
  constructor() { }

  ngOnInit() {
  }
  selectInternetProvider(providerName) {
    this.selectInternetProviderAction(providerName);
  }

}
