<div class="card border-0" style="border-radius: 0% !important;" *ngIf="canshowCompanyNumber">
    <div style="position: relative;">
        <!-- <i class="fal fa-long-arrow-right d-none d-lg-block section-arrow" style="position: absolute;
              left: -60px; top:20px; font-size: 60px; color:#D3D4D5"></i> -->
        <div class="d-flex justify-content-center flex-wrap flex-lg-nowrap p-3" id="companyNumbers">
            <div class="flex-grow-1 pb-2 pr-0" [ngClass]="{'pb-lg-0 pr-lg-2':userIsVoiplyMember$ | async,'pb-md-0 pr-md-2':!(userIsVoiplyMember$ | async)}">
                <div class="input-group h-100">
                    <input type="text" class="form-control h-100 primary-number-input border-grey rounded-lg" mask="(000) 000-0000" *ngIf="userIsVoiplyMember$ | async" [(ngModel)]="number" (focusout)="updatePrimaryNumber()">
                    <input type="text" class="form-control h-100 primary-number-input border-grey rounded-lg" mask="(000) 000-0000" *ngIf="!(userIsVoiplyMember$ | async)" [(ngModel)]="number" disabled>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-center flex-nowrap">
                <div class="" *ngIf="showE911">
                    <voiply-e911-new [emergencyAddress]="currentEmergencyAddress" (updateEmergencyAddress)="onE911AddressChanged($event)"></voiply-e911-new>
                </div>
                <!-- <div class="px-1 px-sm-2 ">
          <button class="btn bg-primary text-primary w-100 h-100 py-3" (click)="addMorePhonesComponent()">
            <i class="fas fa-user-plus d-none d-md-block"></i>
            <h5 class="m-0 button-heading">ORDER MORE</h5>
          </button>
        </div> -->
                <div class="pl-1 pl-sm-2" *ngIf="userIsVoiplyMember$ | async">
                    <voiply-more-numbers-new (updateNumbers)="onUpdateAdditionalNumbers($event)" [showAddtoCompanyNumber]="false" [additionalNumbers]="additionalNumbers" [updatingOrder]="updatingOrder" [coolDownPeriodOn]="coolDownPeriodOn"></voiply-more-numbers-new>
                </div>
                <div class="pl-1 pl-sm-2">
                    <voiply-apply-changes-new *ngIf="!(isOrderDisabled$ | async)" [updatingOrder]="updatingOrder" [coolDownPeriodOn]="coolDownPeriodOn" (updateOrder)="updateOrderDetails()">
                    </voiply-apply-changes-new>
                </div>
            </div>
        </div>
    </div>

</div>
