import { Component, Inject, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ProductBase } from '../product.base';
import { ENVIRONMENT, IEnvironment, CrispService, AudioService } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';
import { CartItem, CartItemType, Phone } from '@voiply/shared/model';
import * as _ from 'lodash'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddToCartConfirmModalComponent } from '../../components/add-to-cart-confirm-modal/add-to-cart-confirm-modal.component';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'voiply-phone-home',
  templateUrl: './phone-home.component.html',
  styleUrls: ['./phone-home.component.scss']
})
export class PhoneHomeComponent extends ProductBase {
  isCollapsed = true;
  modalRef: BsModalRef;
  @ViewChild('template') templateRef: TemplateRef<any>;
  constructor(@Inject(ENVIRONMENT) environment: IEnvironment, toastr: ToastrService, crispService: CrispService, audioService: AudioService, private modalService: BsModalService) {
    super(environment, toastr, crispService, audioService);
  }

  onAddPhonetoCartHome(phonedetail) {
    const phone = phonedetail.phone;
    if (_.filter(this.cartItems, (cartItem) => cartItem.type == CartItemType.Phone).length === 0) {
      this.onAddPhoneToCart(phonedetail);
      return;
    }

    _.each(this.cartItems, (value, key) => {
      if (value.type === CartItemType.Phone) {
        this.onSwitchPhone(key, phone.featureId);
        return false; //exit loop once condition met
      }
    })

    // switch (phone.heading) {
    //   case 'Voiply Adapter':
    //     if (quantity > 1) {
    //       const initialState = {
    //         title: "A majority of home customers only need one adapter. Do you want to add Multiple Adapters?",
    //         rightButton: "Add to Cart",
    //         leftButton: "Cancel"
    //       };
    //       this.modalRef = this.modalService.show(AddToCartConfirmModalComponent, { initialState, class: 'modal-lg ' });
    //       this.modalRef.content.rightButtonClicked.pipe(take(1)).subscribe(() => {
    //         this.onAddPhoneToCart(phonedetail);
    //       });
    //     } else if (quantity === 1 && this.hasVoiplyAdapter) {
    //       const initialState = {
    //         title: "An adapter is already in your cart, a majority of home customers only need one adapter. Do you want to add another one?",
    //         rightButton: "Add to Cart",
    //         leftButton: "Cancel"
    //       };
    //       this.modalRef = this.modalService.show(AddToCartConfirmModalComponent, { initialState, class: 'modal-lg ' });
    //       this.modalRef.content.rightButtonClicked.pipe(take(1)).subscribe(() => {
    //         this.onAddPhoneToCart(phonedetail);
    //       });
    //     } else {
    //       this.onAddPhoneToCart(phonedetail);
    //     }
    //     break;
    //   default:
    //     if (quantity === 1 && this.hasVoiplyAdapter) {
    //       const initialState = {
    //         title: "There already is a Voiply Adapter in you cart, would you like to switch it for " + phone.heading + ' ?',
    //         rightButton: "Yes",
    //         leftButton: "No, Add " + phone.heading
    //       };
    //       this.modalRef = this.modalService.show(AddToCartConfirmModalComponent, { initialState, class: 'modal-lg ' });
    //       this.modalRef.content.rightButtonClicked.pipe(take(1)).subscribe(() => {
    //         this.calculatingTotal(true);
    //         this.onSwitchPhone(key, phone.featureId);
    //       });
    //       this.modalRef.content.leftButtonClicked.pipe(take(1)).subscribe(() => {
    //         this.onAddPhoneToCart(phonedetail);
    //       });
    //     } else {
    //       this.onAddPhoneToCart(phonedetail);
    //     }
    // }
  }
}


