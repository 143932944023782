import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { App, CartItemType } from '@voiply/shared/model';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { OnDestroyCleanup } from '@voiply/shared/common-ui';
import { ShowAppDetailsModalComponent } from '../show-app-details-modal/show-app-details-modal.component';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
import { __values } from 'tslib';
@Component({
  selector: 'voiply-app-new-after-checkout',
  templateUrl: './app-new-after-checkout.component.html',
  styleUrls: ['./app-new-after-checkout.component.scss']
})
export class AppNewAfterCheckoutComponent extends OnDestroyCleanup implements OnInit {
  @Input()
  apps$: Observable<any>;
  @Input()
  selectedCountry = 'US';
  @Input()
  cartItems;
  @Output()
  addToCart = new EventEmitter<App>();
  @Output()
  removeCartItem = new EventEmitter<string>();
  modalRef: BsModalRef;
  showapps = 4;
  apps: App[]
  // @ViewChild('appModal', { static: true }) appModal: ModalDirective;

  constructor(private modalService: BsModalService) {
    super()
  }

  ngOnInit() {
    this.subscriptions$.add(this.apps$.subscribe((apps) => {
        this.apps = _.filter(apps, (app) => app.isActive);
        this.arrangeAppOrder();
      })
    );
  }

  onAddToCart(app: App) {
    this.addToCart.emit(app);
  }
  onOpenModalDialog(index: number) {
    if(this.inCart(this.apps[index])) return;
    const initialState = {
      app: this.apps[index],
    };
    this.modalRef = this.modalService.show(ShowAppDetailsModalComponent, { initialState, class: 'modal-lg' });
    this.modalRef.content.addToCart.pipe(take(1)).subscribe((app) => {
      this.onAddToCart(app)
    });

  }
  showMoreApps() {
    this.showapps = this.showapps + 4;
  }
  appAvailableInCountry(app) {
    if (app.onlyAvailableInCountry.length) {
      if (_.includes(app.onlyAvailableInCountry, this.selectedCountry)) {
        return true
      } else return false
    } else return true
  }

  onRemoveCartItem(app) {
    console.log(this.cartItems);
    _.each(this.cartItems, (value, keys) => {
      if(value.type === CartItemType.App) {
        if(value.heading == app.title) {
          this.removeCartItem.emit(keys);
        }
      }
    })
  }

  inCart(app) {
    let returnVar: boolean = false;
    _.each(this.cartItems, (value, keys) => {
      if(value.type === CartItemType.App) {
        if(value.heading == app.title) {
          returnVar = true;
        }
      }
    });
    return returnVar;
  }

  findHeight() {
    let el = document.getElementById(this.apps[0].videoId)
    if(el) {
      return el.clientHeight;
    }
  }

  arrangeAppOrder(){
    this.apps.sort( (d1,d2)=> d1.placingPriority-d2.placingPriority);
  }

}
