import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ProductConfigurationModule } from '@voiply/product-configuration'
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxPaginationModule } from 'ngx-pagination';



import { CommonUiModule } from '@voiply/shared/common-ui';

import { COMPONENTS } from './components';
import { CONTAINERS } from './container';
import { AddToCartConfirmModalComponent } from './components/add-to-cart-confirm-modal/add-to-cart-confirm-modal.component';
import { ShowAppDetailsModalComponent } from './components/show-app-details-modal/show-app-details-modal.component';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    CollapseModule,
    ModalModule.forRoot(),
    CommonUiModule,
    ProductConfigurationModule,
    BsDropdownModule.forRoot(),
    NgxPaginationModule
  ],
  exports: [
    ...COMPONENTS,
    ...CONTAINERS
  ],
  declarations: [
    ...COMPONENTS,
    ...CONTAINERS,
  ],
  entryComponents: [AddToCartConfirmModalComponent, ShowAppDetailsModalComponent]
})
export class ProductModule { }
