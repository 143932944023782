import { Component } from '@angular/core';
import { ShippingAddressBase } from '../shipping-address.base';

@Component({
  selector: 'checkout-shipping-address-new',
  templateUrl: './shipping-address-new.component.html',
  styleUrls: ['./shipping-address-new.component.css']
})
export class ShippingAddressNewComponent extends ShippingAddressBase {
  constructor() {
    super();
  }
}
