import { Component, Input } from '@angular/core';
import { CheckoutDetail } from '@voiply/shared/model';
import { SubTotalBase } from '../sub-total-base';

@Component({
  selector: 'checkout-sub-total-new',
  templateUrl: './sub-total-new.component.html',
  styleUrls: ['./sub-total-new.component.scss']
})
export class SubTotalNewComponent extends SubTotalBase {


}
