import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'SearchPipe'
})

export class SearchPipe implements PipeTransform {
    transform(value: any, input: string, searchableList: any) {
        if (input) {
            input = input.toLowerCase();
            return value.filter(function (el: any) {
                let element = flat(el);
                let isTrue = false;
                for (const k in searchableList) {
                    if (element[searchableList[k]]) {
                        console.log(element);
                        console.log(searchableList[k]);
                        // const obj=element[searchableList[k]];
                        // for (const property in obj) {
                        //     if (obj.hasOwnProperty(property)) {
                        //       // Do things here
                        //       console.log(obj[k]);
                        //     }
                        //   }
                        if (element[searchableList[k]] instanceof Array) {
                            element[searchableList[k]].forEach(em => {
                                if (em.number.toString().toLowerCase().indexOf(input) > -1) {
                                    isTrue = true;
                                }
                            });
                        }

                        if (element[searchableList[k]].toString().toLowerCase().indexOf(input) > -1) {
                            isTrue = true;
                        }
                        if (isTrue) {
                            return el
                        }
                    }
                }
            })
        }
        return value;
    }
}

function flat(data) {
    const result = {};
    function recurse (cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
             for(var i=0, l=cur.length; i<l; i++)
                 recurse(cur[i], prop + "[" + i + "]");
            if (l === 0)
                result[prop] = [];
        } else {
            let isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop+"."+p : p);
            }
            if (isEmpty && prop)
                result[prop] = {};
        }
    }
    recurse(data, "");
    return result;
}
