import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'checkout-billing-plan-new',
  templateUrl: './billing-plan-new.component.html',
  styleUrls: ['./billing-plan-new.component.scss']
})
export class BillingPlanNewComponent {

  @Input()
  payAnnually: boolean;

  @Output()
  changePlan = new EventEmitter<boolean>();
}
