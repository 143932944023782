import { Select } from "@ngxs/store";
import { AppStateSelectors, SkipSurveyAction, OnDestroyCleanup, HeadingService } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { OnInit, Directive } from '@angular/core';
import { Address, ApplicationFeature, CartItem, CartItemType, CheckoutDetail, Customer, Phone } from '@voiply/shared/model';
import * as _ from 'lodash';

/// Common class for both business and home Header.
@Directive()
export abstract class HeaderBase extends OnDestroyCleanup implements OnInit {

  @Select(AppStateSelectors.isUserVoiplyMember) isUserVoiplyTeamMember$: Observable<boolean>;
  @Select(AppStateSelectors.customer) customer$: Observable<Customer>
  @Select(AppStateSelectors.companyFeatures) systemFeatures$: Observable<ApplicationFeature[]>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.metadata) metadata$: Observable<any>;
  @Select(AppStateSelectors.billingAddress) billingAddress$: Observable<Address>;

  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;
  @Select(AppStateSelectors.phones) phones$: Observable<Phone[]>;
  @Select(AppStateSelectors.checkoutDetails) checkOutDetails$: Observable<CheckoutDetail>;

  @Dispatch() skipSurveyDispatcher = () => new SkipSurveyAction();


  isUserVoiplyTeamMember: boolean;
  systemFeatures: ApplicationFeature[];
  customer: Customer;
  orderId = '';
  codeForLogin = "";
  phone;
  price;
  showCheckoutHeader = true;
  temp: Address;

  constructor() {
    super();
    this.subscriptions$.add(this.isUserVoiplyTeamMember$.subscribe(data => { this.isUserVoiplyTeamMember = data; }));
    this.subscriptions$.add(this.systemFeatures$.subscribe(data => { this.systemFeatures = data; }));
    this.subscriptions$.add(this.customer$.subscribe(customer => { this.customer = customer; }));
    this.subscriptions$.add(this.orderId$.subscribe(orderId => { this.orderId = orderId; }));
    this.subscriptions$.add(this.metadata$.subscribe(metadata => { this.codeForLogin = metadata.codeForLogin; }));

    this.subscriptions$.add(
      this.checkOutDetails$.subscribe(details => {

        let totalSavings = 0;
        Object.keys(details.savings).forEach((k) => {
          totalSavings += details.savings[k];
        });
        this.price = {
          discountedPrice: details.orderTotal,
          actualPrice: details.orderTotal + totalSavings,
        }
      })
    );
    this.subscriptions$.add(
      this.cartItems$.subscribe(cartItems => {
        this.subscriptions$.add(
          this.phones$.subscribe(phones => {
            this.phone = [...phones]
              .reverse()
              .find(
                phone =>
                  phone.heading ==
                  _.find(
                    cartItems,
                    (cartItem: CartItem) =>
                      cartItem.type === CartItemType.Phone
                  )?.heading || ''
              );
          })
        );
      })
    );
    const debounce = (func, wait) => {
      let timeout;

      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    };

    window.onscroll = debounce(() => {
      let el = document.getElementById("checkout");
      if(el!==undefined && el!==null){
        this.showCheckoutHeader = !this.elementInViewport(el);
      }   
    }, 100);
  }

  ngOnInit() {

  }
  skipSurvey() {
    this.skipSurveyDispatcher();
  }

  payNow() {
    this.clickTo(this.phone.heading);
    this.scrollTo('checkout');
    // this.showCheckoutHeader = false;
  }

  scrollTo(controlId) {
    const el = document.getElementById(controlId);
    window.scrollTo({
      top: el.offsetTop - document.getElementById("head-bar").offsetHeight - 15,
      left: window.pageXOffset,
      behavior: 'smooth'
    });
  }


  clickTo(id) {
    const el = document.getElementById(id);
    if (el) el.click();
  }

  elementInViewport(el): boolean {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top < (window.pageYOffset + window.innerHeight) &&
      left < (window.pageXOffset + window.innerWidth) &&
      (top + height) > window.pageYOffset &&
      (left + width) > window.pageXOffset
    );
  }

}