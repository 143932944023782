import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { OnDestroyCleanup } from '@voiply/shared/common-ui';
import { FaqService } from '../../services/faq.service';

@Component({
  selector: 'voiply-all-faqs-new',
  templateUrl: './all-faqs-new.component.html',
  styleUrls: ['./all-faqs-new.component.scss']
})
export class AllFaqsNewComponent extends OnDestroyCleanup implements OnInit {
  isCollapsed = false;
  faqs: { title: string, content: string }[] = [];

  constructor(private service: FaqService) {
    super();
  }

  ngOnInit() {
    this.subscriptions$.add(this.service.getFaqs().subscribe(data => this.faqs = data.AllFaq));
  }

}
