<div class="d-flex flex-column align-items-stretch">
  <form [formGroup]="form" class="ignore-ng-invalid">
    <intl-tel-input-new [cssClass]="'custom ignore-ng-invalid form-control'" [filterOutCountries]="['us','ca']"
      [preferredCountries]="['gb']" [enableAutoCountrySelect]="false" [enablePlaceholder]="true" name="phone"
      formControlName="phone">
    </intl-tel-input-new>
  </form>
  <span *ngIf="country" class="py-2 bg-secondary text-primary text-uppercase text-center rounded-bottom">
    <mat-spinner class="ml-3" diameter="25" value="accent" *ngIf="loading" style="display: inline">
    </mat-spinner><span *ngIf="!loading" class="font-weight-bolder">{{currentRate}}</span>
  </span>
</div>