import { Component, HostListener, NgZone, } from '@angular/core';
import { CrispService } from '@voiply/shared/common-ui';
import { SurveyBase } from '../../components/survey/survey.base';
import { SystemType } from '@voiply/shared/model';
import { HeaderSurveyBase } from '../header-survey.base';

@Component({
  selector: 'voiply-header-survey-home',
  templateUrl: './header-survey-home.component.html',
  styleUrls: ['./../header-survey.base.scss']
})
export class HeaderSurveyHomeComponent extends HeaderSurveyBase {
  headerHeight = 0;
  observer;
  constructor(crispService: CrispService, private zone: NgZone) {
    super(crispService, SystemType.Home);
  }

  ngOnInit() {
    this.observer = new (window as any).ResizeObserver(entries => {
      this.zone.run(() => {
        this.headerHeight = entries[0].contentRect.height;
      });
    });
    this.observer.observe(document.getElementById('head-bar'));
  }

  ngOnDestroy() {
    this.observer.unobserve(document.getElementById('head-bar'));
  }
}

