<div class="container-xl bg-white shadow">
    <voiply-header-home></voiply-header-home>
    <div class="container bg-light-grey pt-3">
        <!-- <voiply-header-survey-home></voiply-header-survey-home> -->
        <voiply-intro-home></voiply-intro-home>
        <voiply-phone-home></voiply-phone-home>
        <voiply-primary-number-home></voiply-primary-number-home>
        <voiply-company-features-home></voiply-company-features-home>
        <voiply-apps-home></voiply-apps-home>
        <!-- <voiply-product-home></voiply-product-home> -->
        <voiply-checkout-home></voiply-checkout-home>
        <!-- <voiply-speed-test></voiply-speed-test> -->
        <voiply-testimonial-new></voiply-testimonial-new>
        <voiply-all-faqs-new></voiply-all-faqs-new>
        <voiply-International-call-feature-home></voiply-International-call-feature-home>
    </div>
    <voiply-footer-home></voiply-footer-home>
    <voiply-cookie-concent></voiply-cookie-concent>
</div>