import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntroBusinessComponent } from './container/intro-business/intro-business.component';
import { IntroHomeComponent } from './container/intro-home/intro-home.component';
import { FormsModule } from '@angular/forms';
import { CommonUiModule } from '@voiply/shared/common-ui';
import { CONTAINERS } from './container';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommonUiModule
  ],
  declarations: [
    ...CONTAINERS
  ],
  exports: [
    ...CONTAINERS
  ]
})
export class HeaderIntroDisplayModule {}
