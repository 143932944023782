import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input } from '@angular/core';
import { NavigationBarBase } from '../navigation-bar.base';

@Component({
  selector: 'voiply-navigation-bar-home',
  templateUrl: './navigation-bar-home.component.html',
  styleUrls: ['./../navigation-bar.base.css'],
  animations: [
    trigger('slideInOut', [
      state('0', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('1', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      transition('0 => 1', animate('400ms')),
      transition('1 => 0', animate('400ms'))
    ]),
  ]
})
export class NavigationBarHomeComponent extends NavigationBarBase {

  @Input() helpNumber: string;
  @Input() logo: string;

  constructor() {
    super();
  }

  toggleAccordian = '0';
  toggleAccordianShow() {
    this.toggleAccordian === '0' ? this.toggleAccordian = '1' : this.toggleAccordian = '0';
  }

  goToBusiness() {
    window.location.href = "https://build.voiply.com";
  }

  scrollTo(controlId) {
    const el = document.getElementById(controlId);
    // el.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      top: el.offsetTop - document.getElementById("head-bar").offsetHeight,
      left: window.pageXOffset,
      behavior: 'smooth'
    });
  }
}
