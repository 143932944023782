import { Component, OnInit, Input, Directive } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { PrimaryNumberService } from '../../services';

@Directive()
export class NewNumberBase implements OnInit {

  @Output() numberSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() internationalNumberSelected: EventEmitter<any> = new EventEmitter<any>();

  @Input() isNew: boolean;
  @Input() canAddCompanyNumbers = false;
  @Input() tollFreeNumberNotAllowed = false;
  local: boolean = true;
  selectedNumber = '';
  internationalTabSelected = false;
  internationalNumber: any = {};
  constructor() { }

  ngOnInit() {
  }

  onNumberSelected() {
    this.numberSelected.emit({ number: this.selectedNumber, isNew: true, systemSelected: false });
  }
  setInternationalNumber(internationalNumber) {
    this.internationalNumber.number = internationalNumber.number;
    this.internationalNumber.countryCode = internationalNumber.countryCode;
    this.internationalNumber.areaCode = internationalNumber.areaCode;
    this.internationalNumber.dialCode = internationalNumber.dialCode;
    this.internationalNumber.package = internationalNumber.package;
    this.internationalNumber.country = internationalNumber.country;
    this.internationalNumber.countryShortCode = internationalNumber.countryShortCode
  }

  onInternationalNumberSelected() {
    this.internationalNumberSelected.emit({ number: this.internationalNumber.number, isNew: true, systemSelected: false, isInternational: true, countryCode: this.internationalNumber.countryCode, areaCode: this.internationalNumber.areaCode, dialCode: this.internationalNumber.dialCode, package: this.internationalNumber.package, country: this.internationalNumber.country, countryShortCode: this.internationalNumber.countryShortCode });
  }

}
