import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { IEnvironment, ENVIRONMENT } from '@voiply/shared/common-ui';

@Injectable({
  providedIn: 'root'
})
export class TestimonialService {

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) { }
  private handleError(error: Response | any) {
    console.error('ERROR HTTP Call', error);
    return throwError(error.status)
  }

  getTestimonials(): Observable<any> {
    return this.http.get(`${this.environment.rootApi}/testimonials`).pipe(catchError(this.handleError));
  }
}
