<div class="bg-primary-gradient header-fixed" id="top-header">
  <div class="container">
    <div class="text-primary d-flex align-items-center justify-content-between py-3">

      <div class="d-flex flex-row align-items-center">
        <div class="pr-3">
          <img src="/assets/images/trophy.png" width="19" height="23.5" alt="">
        </div>
        <!-- <h5 class="my-3 py-1 align-self-center d-inline-flex" [innerHTML]="'title.home' | translate"></h5> -->
        <div>
          <h5 class="m-0"> #1 Phone System for {{title}}</h5>
        </div>
      </div>

      <div class="d-flex flex-row ml-3 align-items-center">
        <div class="d-lg-flex flex-row d-none d-lg-block">
          <button class="btn btn-outline-light mx-1 px-4 d-flex align-items-center" [disabled]="unlockingOrder"
            type="button" (click)="unlockOrder()" *ngIf="isUserVoiplyTeamMember">
            Unlock Order <mat-spinner *ngIf="unlockingOrder" class="d-inline-block ml-3 white-spinner" diameter="20"
              value="accent">
            </mat-spinner>
          </button>
          <button class="btn btn-outline-light mx-1 px-4 d-flex align-items-center" type="button"
            (click)="copyLink()" *ngIf="isUserVoiplyTeamMember && copyLinkButton">
            <i class="fal fa-clipboard mr-2 d-flex"></i>
            Copy Login Link
          </button>
          <button class="btn btn-outline-light mx-1 px-4 " type="button" (click)="clearCallFlow()"
            *ngIf="isUserVoiplyTeamMember">
            Clear Callflow
          </button>
        </div>
        <button class="btn btn-outline-light mx-1 px-4 d-none d-sm-flex align-items-center" type="button"
          (click)="onLogout()">
          <i class="fal fa-sign-out mr-2 d-flex"></i>
          <span> Logout</span>
        </button>
        <button class="btn p-0 d-sm-none" (click)="onLogout()" style="font-size: 20px;"><i
            class="fal fa-sign-out text-white"></i></button>
        <i class="fas fa-align-justify ml-3 cursor-pointer fa-lg text-primary hide-navigation"
          (click)="this.sidenav.toggle()"></i>
      </div>
    </div>

  </div>
</div>