import { Component, EventEmitter, Output, ViewChild, AfterViewInit, OnInit, Directive } from '@angular/core';
import { Select } from '@ngxs/store';
import { CompanyFeaturesService } from '../services/company-features.service';
import { AppStateSelectors, FeaturesModelComponent, OnDestroyCleanup } from '@voiply/shared/common-ui';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { Observable } from 'rxjs';
import { ApplicationFeature } from '@voiply/shared/model';

@Directive()
export class CompanyFeatures extends OnDestroyCleanup {
    features: any;
    currentRate: string;
    loading: boolean;
    country: string;
    videoId = "l52ww7qlun";
    selectedCountry = 'US';
    @Output() showAllFeatures = new EventEmitter();
    @Select(AppStateSelectors.companyFeatures) features$: Observable<ApplicationFeature[]>;
    @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<any>;

    @ViewChild(FeaturesModelComponent) featuremodel;

    constructor(private service: CompanyFeaturesService) {
        super();

        i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        this.subscriptions$.add(this.features$.subscribe(items => this.features = items));
        this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => this.selectedCountry = checkoutDetail.shippingAddress.country));

    }

    onShowAllFeatures() {
        this.featuremodel.openFeatures();
    }

    getInternationalCallRates(value) {
        if (value.phone && value.phone.countryCode && (value.phone.internationalNumber || value.phone.number)) {
            this.loading = true;
            this.country = i18nIsoCountries.getName(value.phone.countryCode.toLowerCase(), 'en');
            let phoneNumber = value.phone.internationalNumber == '' ?
                value.phone.number.replace('+', '').replace(' ', '') : value.phone.internationalNumber.replace('+', '').replace(' ', '');
            this.service.getNumberRatesAsync(phoneNumber).then(results => {
                this.loading = false;
                if (results.rate <= 0.03) {
                    this.currentRate = 'FREE';
                } else if (results.rate > 0.25) {
                    this.currentRate = 'Not Supported';
                } else {
                    this.currentRate = `${results.rate * 100}c per minute`;
                }
            }, e => {
                console.error('Error in international rates', e);
                this.loading = false;
            });
        }
    }
}
