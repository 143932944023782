import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const faqUrl = `${location.origin}/assets/faq.json`;

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(private http: HttpClient) { }
  private handleError(error: Response | any) {
    console.error('ERROR HTTP Call', error);
    return throwError(error.status)
  }

  getFaqs(): Observable<any> {
    return this.http.get(faqUrl).pipe(catchError(this.handleError));
  }
}