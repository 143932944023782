<div class="card border-0" style="border-radius: 0;" *ngIf="userIsVoiplyMember$ | async" id="checkout">
    <div class="card-header bg-white border-0 pb-0">
        <div class="d-lg-flex justify-content-between">
            <div class="h2 font-weight-bolder text-capitalize">
                Express Checkout
            </div>
            <div class="bg-black rounded d-flex align-items-center">
                <img class="mx-1 img-fluid" style="height:34px!important" src="/assets/images/secure-payment.png">
            </div>
        </div>
    </div>
    <div class="row px-3 pb-3 pt-0">
        <!-- Billing Address, stripe and payment section -->
        <div class="col-md-12 col-lg-6 col-sm-12 pt-3">
            <div *ngIf="checkoutDetail">
                <div class="billing info">
                    <checkout-billing-new [billingAddress]="billingAddress" (billingAddressChange)="billingAddressChange($event)">
                    </checkout-billing-new>

                    <div class="bg-white mt-2 custom-checkbox">
                        <!-- Billing same as shipping -->
                        <div class="border-bottom mx-4 pb-1"></div>
                        <div class="mt-2 pt-1">
                            <div class="bg-grey d-flex justify-content-between align-items-center mx-4 mb-2 px-3 py-2 rounded" style="line-height: 2.4rem;">
                                <label class="m-0 font-weight-bold checkbox-label w-100"> Same Address Shipping
                                  <input class="checkbox-input" type="checkbox" #shippingSameAsBillingCheckbox
                                      name="shippingSameAsBilling"
                                      [checked]="checkoutDetail.isShippingSameAsBillingAddress"
                                      (change)="onShippingSameAsBillingChanged(shippingSameAsBillingCheckbox.checked)">
                                  <span class="checkbox-mark mt-1"></span>
                              </label>
                            </div>
                        </div>
                        <checkout-shipping-address-new [shippingAddress]="shippingAddress" (shippingAddressChange)="shippingAddressChange($event)" *ngIf="!checkoutDetail.isShippingSameAsBillingAddress"></checkout-shipping-address-new>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
