<!--containing in - Lib: product-configuration -->
<!--used by - Container: product-configuration-home-after-ckeckout -->
<!--Added for - Home, Business -->
<div class="bg-dark text-primary rounded d-flex justify-content-between align-items-center px-2" style="font-size: 12px;" *ngIf="phoneStatus.status !== 'Registered'">
    <span class="pr-2">OFFLINE</span>
    <span class="cursor-pointer" [popover]="'IP: ' + (phoneStatus.ip || 'N/A')" placement="auto" [outsideClick]="true">
      <i class="fas fa-question-circle"></i>
    </span>
</div>
<div class="bg-sucess text-primary rounded d-flex justify-content-between align-items-center px-2" style="font-size: 12px;" *ngIf="phoneStatus.status == 'Registered'">
    <span class="pr-2">ONLINE</span>
    <span class="cursor-pointer" [popover]="'IP: ' + (phoneStatus.ip || 'N/A')" placement="auto" [outsideClick]="true">
    <i class="fas fa-question-circle"></i>
  </span>
</div>
