import { Phone, App, CartItem, CartItemType, Gender, VoiceMail, PhoneConfiguration } from '@voiply/shared/model';
import { AppStateSelectors, IEnvironment, ENVIRONMENT, OnDestroyCleanup, SaveCartItemAction, CrispService, AudioService, RemoveCartItemAction, UpdateCartItemConfigurationAction, CalculatingTotalAction, AddPhonesToCartAction, AddAppToCartAction, UpdateCartItemAction, COUNTRIES } from '@voiply/shared/common-ui';
import { Directive, Inject, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import * as LogRocket from 'logrocket';

/// Common class for both business and home Header.
@Directive()
export class ProductBase extends OnDestroyCleanup implements OnInit {
  cartItems: any;
  selectedCountry = 'US'
  phones: Phone[] = [];
  allPhones;
  countryDist={};
  
  @Select(AppStateSelectors.phones) phones$: Observable<Phone[]>;
  @Select(AppStateSelectors.apps) apps$: Observable<App[]>;
  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;
  @Select(AppStateSelectors.userIsVoiplyMember) userIsVoiplyMember$: Observable<boolean>;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<any>;
  @Dispatch() saveCartItem = (cartItem: CartItem) => new SaveCartItemAction(cartItem);
  @Dispatch() addAppToCart = (app: App) => new AddAppToCartAction(app);
  @Dispatch() addPhonesToCart = (phoneFeatureId: number, quantity: number) => new AddPhonesToCartAction(phoneFeatureId, quantity);
  @Dispatch() removeCartItemAction = (CartItemKey: string) => new RemoveCartItemAction(CartItemKey);
  @Dispatch() updateCartItemConfigurationAction = (key, cartItemConfiguration) => new UpdateCartItemConfigurationAction(key, cartItemConfiguration);
  @Dispatch() calculatingTotal = (isCalculating: boolean) => new CalculatingTotalAction(isCalculating);
  @Dispatch() updateCartItemAction = (key: string, updateFeatureId: number) => new UpdateCartItemAction(key, updateFeatureId)

  constructor(@Inject(ENVIRONMENT) private environment: IEnvironment, private toastr: ToastrService, private crispService: CrispService, private audioService: AudioService) {
    super();
    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      this.selectedCountry = checkoutDetail.shippingAddress.country;
      this.filterPhoneBasedOnCountry();
    }));
    this.subscriptions$.add(this.cartItems$.subscribe((items) => this.cartItems = {...items}));
    for(let i of COUNTRIES){
      this.countryDist[i.countryShortCode] = i.countryName;
    }
  }

  ngOnInit(): void {
    this.subscriptions$.add(this.phones$.subscribe((items) => {this.phones = items.filter((itm) => (itm.isActive == true && itm.outOfStock==false))
      this.allPhones=this.phones;
    }));
  }
  bringYourOwnDevice() {
    this.addPhonesToCart(11, 1);
    this.toastr.success(`Product added to your cart.`);
  }

  onAddPhoneToCart(phonedetail) {
    if(this.selectedCountry!=='US' && this.selectedCountry!=='CA'){
      if(phonedetail.phone.featureId!==4){
        this.showWarning();
        return false;
      }
    }
    const phone = phonedetail.phone;
    const quantity = phonedetail.quantity;

    // save cart item to state
    this.addPhonesToCart(phone.featureId, quantity);


    if (phone.heading === "Deluxe Phone") {
      this.crispService.setSessionEvent("sales:phone:deluxe", { "added-new-deluxe-phone": true });
      LogRocket.track("sales:phone:deluxe");
    }

    this.toastr.success(` ${quantity} ${phone.heading} added to your cart.`);
  }

  onAddAppToCart(app: App) {
    if(this.showWarning()==true) return false;
    if (!app.multipleAllowed) {
      if (!_.find(this.cartItems, (cartItem: CartItem) => cartItem.type === CartItemType.Phone)) {
        this.toastr.warning(`Add a Phone to add '${app.title}' in your cart`);
        return;
      }
      if (_.some(this.cartItems, (cartItem: CartItem) => cartItem.type === CartItemType.App && cartItem.itemId === app.appId)) {
        this.toastr.warning(`App '${app.title}' is already in your cart. Cannot add it again.`);
        return;
      }
    }

    this.addAppToCart(app);
    this.toastr.success(`App '${app.title}' added to your cart.`);
  }

  onSwitchPhone(key, updateFeatureId) {
    this.calculatingTotal(true);
    this.updateCartItemAction(key, updateFeatureId);
    this.toastr.success(`Switched Phones`);
  }
  onRemoveCartItem(cartItemKey: string) {
    this.removeCartItemAction(cartItemKey);
  }

  onUpdateCartItemConfiguration(cartItem: any) {
    this.updateCartItemConfigurationAction(cartItem.cartItemKey, cartItem.configuration);
  }

  filterPhoneBasedOnCountry(){
    if(this.phones && this.allPhones){
      if(this.selectedCountry=='CA'){
        this.phones=this.allPhones.filter( (itm) => (itm.featureId==4 || itm.featureId==11 || itm.featureId==5  )  );
      }
      else if(this.selectedCountry=='US'){
        this.phones=this.allPhones;
      }
      else{
        this.phones=this.allPhones.filter( (itm) => (itm.featureId==4 || itm.featureId==11 )  ); //3 id cordless phone 6 wifi desk phone 
      }
    }
  }
  showWarning(){
    console.log(this.countryDist)
    if(this.selectedCountry!=='US' && this.selectedCountry!=='CA'){
      this.toastr.warning("At this time only the Voiply Online App is available in "+this.countryDist[this.selectedCountry]);
      return true;
    }
  }
}
