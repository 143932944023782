<div class="new-cont h-100" [style.opacity]="updatingOrder || coolDownPeriodOn ? 0.4 : 1">
    <button class="btn bg-dark text-primary rounded-lg h-100 w-100 h-100" (click)="updateOrderDetails();">
    <span *ngIf="!updatingOrder">
      <svg style="height: 30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.114 32.847">
        <g id="Group_416" data-name="Group 416" transform="translate(-1698.397 -727.895)">
          <g id="Group_209" data-name="Group 209" transform="translate(1698.397 734.301)">
            <g id="confirm" transform="translate(0)">
              <g id="Group_205" data-name="Group 205">
                <g id="Group_204" data-name="Group 204">
                  <path id="Path_1372" data-name="Path 1372" d="M13.221,0A13.221,13.221,0,1,0,26.441,13.221,13.2,13.2,0,0,0,13.221,0Zm0,24.673A11.453,11.453,0,1,1,24.673,13.22,11.428,11.428,0,0,1,13.221,24.673Z" fill="#fff"/>
                </g>
              </g>
              <ellipse id="Ellipse_116" data-name="Ellipse 116" cx="11.852" cy="11.852" rx="11.852" ry="11.852" transform="translate(0.928 1.721)" fill="#fff"/>
            </g>
          </g>
          <ellipse id="Ellipse_117" data-name="Ellipse 117" cx="8.466" cy="8.466" rx="8.466" ry="8.466" transform="translate(1715.58 727.894)" fill="#414141"/>
          <path id="settings_2_" data-name="settings (2)" d="M10.437,4.127l-.822-.179a4.471,4.471,0,0,0-.264-.638L9.806,2.6a.319.319,0,0,0-.043-.4L8.484.925a.319.319,0,0,0-.4-.043l-.707.455a4.471,4.471,0,0,0-.638-.264L6.561.251A.319.319,0,0,0,6.249,0H4.439a.319.319,0,0,0-.312.251l-.179.822a4.471,4.471,0,0,0-.638.264L2.6.882a.319.319,0,0,0-.4.043L.925,2.205a.319.319,0,0,0-.043.4l.455.707a4.471,4.471,0,0,0-.264.638l-.822.179A.319.319,0,0,0,0,4.439v1.81a.319.319,0,0,0,.251.312l.822.179a4.471,4.471,0,0,0,.264.638l-.455.707a.319.319,0,0,0,.043.4l1.28,1.28a.319.319,0,0,0,.4.043l.707-.455a4.471,4.471,0,0,0,.638.264l.179.822a.319.319,0,0,0,.312.251h1.81a.319.319,0,0,0,.312-.251l.179-.822a4.471,4.471,0,0,0,.638-.264l.707.455a.319.319,0,0,0,.4-.043l1.28-1.28a.319.319,0,0,0,.043-.4l-.455-.707a4.471,4.471,0,0,0,.264-.638l.822-.179a.319.319,0,0,0,.251-.312V4.439A.319.319,0,0,0,10.437,4.127ZM7.261,5.344A1.916,1.916,0,1,1,5.344,3.428,1.919,1.919,0,0,1,7.261,5.344Zm0,0" transform="translate(1718.959 730.872)" fill="#fff"/>
          <g id="Group_208" data-name="Group 208" transform="translate(1705.796 741.682)">
            <g id="Group_206" data-name="Group 206">
              <path id="Path_1373" data-name="Path 1373" d="M140.579,176.126a.829.829,0,0,0-1.192,0l-6.535,6.535-3.31-3.31a.843.843,0,0,0-1.192,1.192l3.906,3.906a.768.768,0,0,0,.6.253.778.778,0,0,0,.6-.253l7.131-7.131A.829.829,0,0,0,140.579,176.126Z" transform="translate(-128.098 -175.874)" fill="#414141"/>
            </g>
          </g>
        </g>
      </svg>
    </span>
    <div class="d-flex justify-content-center" *ngIf="updatingOrder">
      <mat-spinner class="white-spinner" diameter="20" value="accent"></mat-spinner>
    </div>
    <h5 class="m-0 font-weight-bold pt-2">Apply Changes</h5>
  </button>
</div>
