import { Component, OnInit, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { OrderService, UserService, RouterService, CrispService } from '@voiply/shared/common-ui';
import { EventType, SystemType, CartItemType } from '@voiply/shared/model';
import { ToastrService } from 'ngx-toastr';
import * as LogRocket from 'logrocket';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Component({
  selector: 'voiply-call-back',
  templateUrl: './call-back.component.html',
  styleUrls: ['./call-back.component.css']
})
export class CallBackComponent implements OnInit, AfterContentInit {

  @ViewChild('callbackLoader') callbackLoader: ElementRef;

  public orderId: string;
  progress: NodeJS.Timer;




  constructor(private auth: AuthService, private orderService: OrderService, private userService: UserService, private crispService: CrispService,
    private router: RouterService, private toastr: ToastrService, private route: ActivatedRoute) {

  }
  ngAfterContentInit(): void {
    if (this.callbackLoader) {
      this.progress = setInterval(() => this.progressMove(), 700);
    }
  }


  async ngOnInit() {
    let authResult: any;
    this.orderId = localStorage.getItem("OrderId");
    LogRocket.identify(this.orderId, { subscription: 'PAID' });

    this.route.fragment
      .pipe(
        map(fragment => new URLSearchParams(fragment)),
        map(params => ({
          accessToken: params.get('access_token'),
          idToken: params.get('id_token'),
          expiresIn: params.get('expires_in'),
          state: params.get('state')
        }))
      )
      .subscribe(res => {
        authResult = res;
      });
    if (authResult.accessToken) {
      this.auth.setSession(authResult)
      console.log("AuthResult : ", authResult);
      const userData = await this.auth.getProfile(authResult);
      // console.log("UserData : ", userData);
      localStorage.setItem('authProfile', userData);
      const tokenPayload = userData || {};
      const userAuth0Id = (tokenPayload || {}).sub;
      const name = (tokenPayload || {}).name;
      const email = (tokenPayload || {}).email;
      if (this.callbackLoader) {
        this.progressIncrement(20, 50);
      }
      try {
        const isItVoiplyTeamMember = await this.userService.isVoiplyTeamMember(userAuth0Id);
        if (isItVoiplyTeamMember.voiplyTeamMember) {
          localStorage.setItem('adminAuth0Id', userAuth0Id);
          this.progressIncrement(100, 1);
          this.router.navigate(`/${this.orderId}/aftercheckout`)
          return
        }
        const order = await this.orderService.getOrderAsync(this.orderId);

        let customer = order.customer;
        if (_.isEmpty(customer)) {
          customer = { auth0Id: null }
        }
        console.log("[CALLBACK] Saving user id", userAuth0Id);
        // new customers are created with Login password flow so we assign a method "password" to customer Object
        if (customer.auth0Id == null) {
          console.log('[CALLBACK] Assigning user to order');
          const changes: any = {
            customer: { name: name, email: email, auth0Id: userAuth0Id, method: 'password' }
          };
          await this.userService.customerUpdate(this.orderId, changes);
          //The user should enter this flow only when user had previously logged in with passwordless method.
        } else if (_.includes(userAuth0Id, 'auth0') && !_.includes(customer.auth0Id, 'auth0') && !customer.updatedSignUp) {
          const changes: any = {
            customer: { updatedSignUp: { auth0Id: userAuth0Id, method: 'password', name: name, email: email } }
          };
          await this.userService.customerUpdate(this.orderId, changes).then(response => {
            this.toastr.success('Sign Up Method Change Successful');
            this.router.navigate(`/${this.orderId}/aftercheckout`)
          });
        }

      }
      catch (err) {
        console.log(err);
      }
    }
    else {
      console.log("AccessToken not found.");
      this.toastr.error(`Please disable 'Block third-party cookies' in your browser. Call our sales team for assistance if you're unable to Login.`);
    }


    this.progressIncrement(100, 1);
    this.router.navigate("/" + this.orderId);
  }



  //This function is used to increment the loader to 99% irrespective of internet speed.
  progressMove() {
    const content = this.callbackLoader.nativeElement.textContent;
    let progressDigit = parseInt(content, 10);
    if (progressDigit > 98) {
      clearInterval(this.progress);
    } else {
      progressDigit++;
      this.callbackLoader.nativeElement.innerHTML = progressDigit.toString();
    }

  }
  //This function was created to increment the loader progress to certain amount based on the process completed.
  progressMoveFast(incrementUpto) {
    const content = this.callbackLoader.nativeElement.textContent;
    let progressDigit = parseInt(content, 10);
    if (progressDigit > 100) {
      clearInterval(this.progress);
    } else if (progressDigit < incrementUpto) {
      progressDigit++
      this.callbackLoader.nativeElement.innerHTML = progressDigit.toString();
    } else {
      clearInterval(this.progress);
      this.progress = setInterval(() => this.progressMove(), 700);
    }
  }
  //Wrapper function for clearing interval before calling progressmovefast
  progressIncrement(incrementUpto, speed) {
    clearInterval(this.progress);
    this.progress = setInterval(() => this.progressMoveFast(incrementUpto), speed);
  }

}
