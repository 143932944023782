import { E911ExtralineSettingsComponent } from './e911-extraline-settings/e911-extraline-settings.component';
import { PrimaryNumberBusinessAfterCheckoutComponent } from './primary-number-business-after-checkout/primary-number-business-after-checkout.component';
import { PrimaryNumberHomeAfterCheckoutComponent } from './primary-number-home-after-checkout/primary-number-home-after-checkout.component'

export const CONTAINERS = [
    PrimaryNumberBusinessAfterCheckoutComponent,
    PrimaryNumberHomeAfterCheckoutComponent,
    E911ExtralineSettingsComponent
];

export { PrimaryNumberBusinessAfterCheckoutComponent } from './primary-number-business-after-checkout/primary-number-business-after-checkout.component';
export { PrimaryNumberHomeAfterCheckoutComponent } from './primary-number-home-after-checkout/primary-number-home-after-checkout.component'
export { E911ExtralineSettingsComponent } from './e911-extraline-settings/e911-extraline-settings.component';
