<div *ngIf="phone">
    <div class="container-fluid">
        <div class="d-flex flex-sm-row flex-column">
            <div class="d-flex flex-row justify-content-between pt-2 flex-fill">
                <!-- left-->
                <div class="d-flex flex-column pb-2 flex-fill justify-content-center">
                    <div>
                        <div class="d-flex flex-row">
                            <div *ngIf="phone.featureText"
                                class="text-uppercase font-weight-bolder text-secondary rounded p-2 mr-2 my-auto d-none d-lg-block"
                                style="background-color: #F9DED7;">{{phone.featureText}}</div>
                            <div class="text-capitalize font-weight-bolder h3 my-auto">{{phone.heading}}</div>
                        </div>
                        <div class="d-none d-sm-block">
                            <div *ngIf="phone.heading" class="d-flex flex-row flex-wrap">
                                <div *ngFor="let feature of phone.features; let i = index"
                                    [ngClass]="{'border-right': i != phone.features.length - 1}" class="px-1">
                                    {{feature.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none d-sm-block d-lg-none flex-wrap">
                        <div class="h5">
                            <span class="text-secondary">${{phone.monthlyCharge}}/mo</span> for <span
                                class="text-secondary">12</span> months with <span
                                class="text-capitalize text-secondary">Voiply Annual Plan</span>
                        </div>
                    </div>

                </div>
                <!-- right -->
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column-reverse flex-lg-row justify-content-end align-items-center ml-auto">
                        <div class="btn btn-primary text-uppercase font-weight-bolder h5 rounded px-4 py-2 py-lg-3 mt-2 mt-lg-3 d-none d-sm-block d-lg-none mx-2"
                            (click)="payNow.emit()">
                            VIEW CART
                        </div>
                        <div class="d-none d-lg-block">
                            <div class="d-flex justify-content-end">
                                <div *ngIf="chips.freeShipping"
                                    class="text-uppercase font-weight-bolder bg-grey rounded py-2 px-3 mr-2 my-auto"
                                    style="font-size: smaller;">
                                    <i class="fas fa-check mr-1"></i>
                                    <span class="text-uppercase">Free shipping</span>
                                </div>
                                <div *ngIf="chips.promoCode"
                                    class="text-uppercase font-weight-bolder bg-grey rounded py-2 px-3 mr-2 my-auto"
                                    style="font-size: smaller;">
                                    <i class="fas fa-check mr-1"></i>
                                    <span class="text-uppercase">Free month</span>
                                </div>
                                <div *ngIf="chips.payAnnually"
                                    class="text-uppercase font-weight-bolder bg-grey rounded py-2 px-3 mr-2 my-auto d-none d-lg-block"
                                    style="font-size: smaller;">
                                    <i class="fas fa-check mr-1"></i>
                                    <span class="text-uppercase">20% discount</span>
                                </div>
                            </div>
                        </div>
                        <div class="h4 my-0 my-sm-2 text-right mr-2">
                            <span class="text-secondary font-weight-bolder mr-2">{{price.discountedPrice |
                                currency}}</span>
                            <span class="text-muted"> <del>{{price.actualPrice | currency}}</del> </span>
                        </div>
                    </div>
                    <div class="d-none d-lg-block">
                        <div class="h5">
                            <span *ngIf="chips.payAnnually"><span class="text-secondary">${{phone.monthlyCharge *
                                    0.8}}/mo</span> for <span class="text-secondary">12</span> months with <span
                                    class="text-capitalize text-secondary">Voiply Annual Plan</span></span>
                            <span *ngIf="!chips.payAnnually"><span
                                    class="text-secondary">${{phone.monthlyCharge}}/mo</span> with <span
                                    class="text-capitalize text-secondary">Voiply Monthly Plan</span></span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="d-block d-sm-none text-right">
                <span *ngIf="chips.payAnnually"><span class="text-secondary">${{phone.monthlyCharge *
                        0.8}}/mo</span> for <span class="text-secondary">12</span> months with <span
                        class="text-capitalize text-secondary">Voiply Annual Plan</span></span>
                <span *ngIf="!chips.payAnnually"><span class="text-secondary">${{phone.monthlyCharge}}/mo</span> with
                    <span class="text-capitalize text-secondary">Voiply Monthly Plan</span></span>
            </div>
            <div class="btn btn-primary text-uppercase font-weight-bolder h5 rounded px-4 py-2 py-sm-3 my-2 my-sm-auto d-block d-sm-none d-lg-block mx-2"
                (click)="payNow.emit()">
                VIEW CART
            </div>
        </div>
    </div>
</div>