import { Component, OnInit, Inject } from '@angular/core';
import { ProductBase } from '../product.base';
import { ENVIRONMENT, IEnvironment, CrispService, AudioService } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'voiply-product-home-after-checkout',
  templateUrl: './product-home-after-checkout.component.html',
  styleUrls: ['./product-home-after-checkout.component.scss']
})
export class ProductHomeAfterCheckoutComponent extends ProductBase {

  constructor(@Inject(ENVIRONMENT) environment: IEnvironment, toastr: ToastrService, crispService: CrispService, audioService: AudioService) {
    super(environment, toastr, crispService, audioService);
  }

}
