import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { AppStateSelectors, BillingAddressChangeAction } from '@voiply/shared/common-ui';
import { Address } from '@voiply/shared/model';
import { Observable } from 'rxjs';


@Component({
  selector: 'voiply-root-page',
  templateUrl: './root-page.component.html',
  styles: []
})
export class RootPageComponent implements OnInit {

  queryParams;
  currentBillingAddress:Address;

  @Select(AppStateSelectors.billingAddress) billingAddress$: Observable<Address>;
  @Dispatch() onBillingAddressChange = (billingAddress) => new BillingAddressChangeAction(billingAddress);


  constructor(private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
    });

    this.billingAddress$.subscribe( currentBillingAddress=> this.currentBillingAddress=currentBillingAddress);
    
    if(this.queryParams.z){
      this.currentBillingAddress.zip=this.queryParams.z;
      this.onBillingAddressChange(this.currentBillingAddress);
    }
    this.doAutoScroll();
  }

  ngOnInit() {
  }

  doAutoScroll(){
    if(this.queryParams.checkout || this.queryParams.faq || this.queryParams.international || this.queryParams.addOn || this.queryParams.numbers){
      let className;
      if(this.queryParams.checkout){
        className="checkoutScroll";
      }
      else if(this.queryParams.faq){
        className="faqScroll";
      }
      else if(this.queryParams.international){
        className="internationalScroll"
      }
      else if(this.queryParams.addOn){
        className="addOnScroll"
      }
      else if(this.queryParams.numbers){
        className="keepNumberScroll"
      }
      else if(this.queryParams.addOn){
        className="addOnScroll";
      }
      else{
        return;
      }
      setTimeout(() => {
        const section = document.getElementsByClassName(className);
        // console.log("section =", section[0].parentElement.scrollIntoView());
        if (section !== undefined && section.length > 0)
          window.scrollTo({
            top: section[0].getBoundingClientRect().top + window.scrollY,
            left: window.pageXOffset,
            behavior: 'smooth'
          });
      }, 1000);
    }
  }

}
