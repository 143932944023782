import { Component, ElementRef, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UserService } from '@voiply/shared/common-ui';
import { Address, Stripe } from '@voiply/shared/model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'checkout-stripe-new',
  templateUrl: './stripe-new.component.html',
  styleUrls: ['./stripe-new.component.css']
})
export class StripeNewComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @ViewChild('checkout') checkoutForm;

  @Input()
  totalAmount: number;
  @Input()
  shippingAmount: number;
  @Input()
  shippingLabel: string;
  @Input()
  shippingDetail: string;
  @Input()
  billingDetails: Address;
  @Input()
  orderId = '';
  @Output()
  stripeDataChanged = new EventEmitter<Stripe>();
  @Output()
  paymentSuccess = new EventEmitter<any>();
  @Output()
  paymentFailed = new EventEmitter<any>();
  showPaymentButton = false;
  payViaBrowser = true;
  paymentRequest: any;
  card: any;
  cardConfigured = false;
  cardHandler = this.onChange.bind(this);
  error: string;
  paymentTokenSubmitted = false;

  constructor(
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private userService: UserService) {
  }

  ngAfterViewInit() {
    if (this.card)
      return;

    this.card = elements.create('card', {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: '#d3d4d5',
          color: '#212529',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          ':-webkit-autofill': {
            color: '#212529',
          },
          '::placeholder': {
            color: '#2125298a',
          },
        },
        invalid: {
          iconColor: 'red',
          color: 'red',
        },
      },
      hidePostalCode: true
    });

    this.configurePaymentButton(this.totalAmount);

    if (this.showPaymentButton && !this.cardConfigured) {
      this.cardConfigured = true;
      console.log('Mounting Card element ngAfterViewInit');
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // const empty: any = {};
    // if ((((changes.value || empty).currentValue || empty).subscription || { paymentSuccessful: false }).paymentSuccessful) {
    //   this.paymentTokenSubmitted = false;
    //   return;
    // }

    if (this.showPaymentButton)
      this.configurePaymentButton(this.totalAmount);
  }

  ngOnDestroy() {
    console.log('Destroying Stripe Element');
    if (this.card) {
      // this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  configurePaymentButton(totalOrderAmount: number) {
    if (totalOrderAmount <= 0)
      totalOrderAmount = this.totalAmount;
    // 1. instantiate a paymentRequest object
    this.paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        amount: Number((this.totalAmount * 100).toFixed(2)),
        label: "Pay Now",
      },
      requestShipping: false,
      shippingOptions: [
        {
          id: "shipping",
          label: this.shippingLabel,
          detail: this.shippingDetail,
          amount: +this.shippingAmount.toFixed(0) * 100
        }
      ]
    });

    // // 3. register listener


    this.paymentRequest.on('paymentmethod', async (result) => {
      this.makePayment(result.paymentMethod)
      result.complete('success');
    })
    //Register cancel payment listner
    this.paymentRequest.on('cancel', async (event) => {
      this.paymentFailed.emit()
    })

    // 4. mount the button asynchronously
    this.mountButton();
  }

  async mountButton() {
    const result = await this.paymentRequest.canMakePayment();
    console.log('Can make browser payment', result);
    if (result) {
      //this.prButton.mount(this.payElement.nativeElement);
      // this.showPaymentButtonChanged.emit(true);
      this.showPaymentButton = true;
    } else {
      if (!this.cardConfigured) {
        this.cardConfigured = true;
        console.log('Mounting Card element in mountButton');
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
      }
    }
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  changePayViaBrowser(value) {
    this.payViaBrowser = value;
    console.log('Change Pay Via Browser', value);
    if (value) {
      // Pay via browser, then hide card component.
      if (this.card) {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
        this.card = null;
        this.cardConfigured = false;
      }
    } else {
      if (!this.card) {
        // this.configurePaymentButton(this.totalAmount);
        this.ngAfterViewInit();
      }
      this.cardConfigured = true;
      console.log('Mounting Card element ngAfterViewInit');
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
    }
  }

  public async payNow() {
    if (this.showPaymentButton && this.payViaBrowser)
      this.paymentRequest.show();
    else {
      /* Create your 'card' payment method */
      stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        /* Reference: https://stripe.com/docs/api/payment_methods/create#create_payment_method-billing_details */
        billing_details: {
          name: this.billingDetails.name,
          email: this.billingDetails.email,
          address: {
            city: this.billingDetails.city,
            country: this.billingDetails.country,
            line1: this.billingDetails.address,
            line2: this.billingDetails.address2,
            postal_code: this.billingDetails.zip,
            state: this.billingDetails.state
          },
          phone: this.billingDetails.phone
        },

      }).then((result) => {
        if (result.error) {
          this.toastr.error('Error creating Stripe Payment Method: ', result.error.message);
          this.paymentFailed.emit()
          return result.error.message;
        } else {
          console.log(result.paymentMethod);
          this.makePayment(result.paymentMethod);
        }
      });


    }
  }

  makePayment(paymentMethod) {
    this.stripeDataChanged.emit({
      paymentMethodId: paymentMethod.id,
      card: {
        exp_month: paymentMethod.card.exp_month, exp_year: paymentMethod.card.exp_year,
        id: paymentMethod.card.id, last4: paymentMethod.card.last4, brand: paymentMethod.card.brand
      }
      /* Proceed to next step of creating customer and subscription */
    });
    this.userService.makePaymentNew(this.orderId, paymentMethod.id).then(response => {
      if (response)
        this.manageSubscriptionStatus(response.paymentIntent, response.subscriptionInfo, paymentMethod.id)
    }).catch(err => {
      this.toastr.error('Error Payment Failed', err?.error);
      this.paymentFailed.emit()
    })
  }
  manageSubscriptionStatus(payment_intent, subscriptionInfo, paymentMethodId) {
    if (payment_intent) {
      /* Do NOT share or embed your client_secret anywhere */
      const { client_secret, status } = payment_intent;
      if (status === "requires_action" || status === "requires_payment_method" || status === "requires_confirmation") {
        stripe.confirmCardPayment(client_secret, { setup_future_usage: "off_session" })
          .then((result) => {
            //Todo mark the invoice as void
            if (result.error) {
              this.toastr.error('payment Failed', result.error.message);
              this.paymentFailed.emit();
            } else {
              this.paymentSuccess.emit({ subscriptionInfo, paymentMethodId });
            }
          }).catch((err) => {
            this.toastr.error('Error confirming card payment:', err.message);
            this.paymentFailed.emit();
          });
      } else {
        console.log('success'); // Show success state
        this.paymentSuccess.emit({ subscriptionInfo, paymentMethodId });

      }
    } else {
      /* If no payment intent exists, show the success state
       * Usually in this case if you set up a trial with the subscription
       */
      console.log('success');
      this.paymentSuccess.emit({ subscriptionInfo, paymentMethodId });
    }
  }
}
