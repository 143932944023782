import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { PrimaryNumberService } from '../../../services';
import { NewNumberBase } from '../new-number.base';

@Component({
  selector: 'voiply-new-number-home',
  templateUrl: './new-number-home.component.html',
  styleUrls: ['./new-number-home.component.scss']
})
export class NewNumberHomeComponent extends NewNumberBase implements OnInit {

  @Output() numberSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() internationalNumberSelected: EventEmitter<any> = new EventEmitter<any>();

  @Input() isNew: boolean;
  @Input() canAddCompanyNumbers = false;
  @Input() tollFreeNumberNotAllowed = false;
  local: boolean = true;
  selectedNumber = '';
  internationalTabSelected = false;
  internationalNumber: any = {};

  toggle:boolean = true;
  flag:boolean=false;
  
  constructor() {
    super()
  }

  ngOnInit() {
  }

  onClick(btnId){
    if(btnId=='1' && this.toggle==false){
      this.toggle=!this.toggle;
      return;
    }
    if(btnId=='2' && this.toggle==true){
      this.toggle=!this.toggle;
      return;
    }
  }
}
