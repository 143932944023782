import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT, IEnvironment } from '@voiply/shared/common-ui';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyFeaturesService {

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) { }
  private handleError(error: Response | any) {
    console.error('ERROR HTTP Call', error);
    return throwError(error.status)
  }

  getNumberRatesAsync(num): Promise<any> {
    return this.http.get(`${this.environment.internationalCallRateApi}?number=${num}`).pipe(catchError(this.handleError)).toPromise();
  }
}
